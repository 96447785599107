/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.footnote",
  title: "A Footnote",
  description: "Adds a numeric or symbolic footnote for refrence to be rendered on the page.",
  "inner": {
      type: "inner",
      description: "The inner content of the footnote.",
      required: true
  },
  supportServer: false,
  passthrough: true,
  dependents: ["ua.footnotes", "ua.a.incenter"]
});

var footnotes = require("./ua.footnotes.js");

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number.
 * @param {*} ele The element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = function (options) {

  var footnoteId = footnotes.FindFootnoteId(options.inner);

    //check if the footnote is already in the list
    if (footnoteId === -1) {
      footnoteId = footnotes.add(options.inner);
    }

  var footnote = {
    n: "div",
    id: `footnote-${footnoteId}`,
    c: "footnote",
    i: {
      n: "a",
      c: "ua-footnote",
      href: `#footnote-${footnoteId}`,
      i: `[${footnoteId}]`
    }
  };

  return footnote;

};