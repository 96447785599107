/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "lottie.dot",
  title: "Lottie.dot Animated Vector Player",
  description: "Adds a lottie.dot vector animation (usually created through After Effects) to your page.",
  fields: {
    src: {
      title: "Source",
      type: "uri", 
      filetype: ["json", "lottie", "dotlottie"],
      description: "The source of the lottie to play (json)."
    },
    autoplay: {
      title: "Autoplay",
      type: "boolean",
      description: "Should the animation play automatically?",
      default: true
    }, controls: {
      title: "Controls",
      type: "boolean",
      description: "Should the animation show controls?"
    }, loop: {
      title: "Loop",
      type: "boolean",
      description: "Should the animation loop?",
      default: true
    }, speed: {
      title: "Speed",
      type: "number",
      description: "The speed of the animation.",
      default: 1
    }, renderer: {
      title: "Renderer",
      type: "string",
      description: "The renderer to use. Defaults to svg.",
      default: "svg"
    }
  }
  //,
  // Included by default during page load
  // external: {
  //   name: "dotlottie-player",
  //   src: "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs",
  //   type: "module"
  // },
});
// var namespace = module.exports.namespace;




//Impot lottie player
// var lottie = false;

try {
  // lottie = require("@dotlottie/player-component");
} catch (error) {
  // console.error("Error loading dotlottie player. It's not compatable with servier side rendering.", error);
}

var jsonRender = require("../interface/jsonRender.js");

// var namespace = "lottie.dot";
// exports.namespace = namespace;

// var required = ["src"];

// exports.define = {
//   namespace: namespace,
//   title: "Lottie Animation Player for dotlottie files.",
//   description: "Connects to the dotlottie player to play animations.",
  
//   required: required
// } 
{/* <script src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs" type="module"></script> */}

// console.log(`Module ${namespace} loaded with an external script.`, exports.define);

exports.render = async function (options) {
   //var date = new Date($(this).attr("fromserver"));

  //  if ("UATisServer" in window && window.UATisServer) {

  //   //I'm not comapatible with server side rendering (yet)

  //   var onlyClientSideRender = {
  //     "n": "json",
  //     "type": "ua/interface",
  //     // "render": "no-server",
  //     "i": JSON.stringify(options, null, 0) + ""
  //   };

  //   // console.info("Only client side render", onlyClientSideRender);
  //   return await jsonRender.render(onlyClientSideRender);

  // };
  
   var lottie = {
      n: "dotlottie-player"
   }

   if (!("loop" in options)) {
      options.loop = "";
   }

    if (!("autoplay" in options)) {
      options.autoplay = "";
    }

    // console.lo

    // if (!("controls" in options)) {
    //   options.controls = "";
    // }


  return await jsonRender.render(lottie, {
    PassthroughOptions: options
  });

};
