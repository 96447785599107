/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.element.placeholder",
  title: "Universe App Element Placeholder",
  description: "Specialized component used for marking a component for the interface, handling controls, and developer transparency.",
  editable: false,
  fields: {
    "define": {
      title: "Definition",
      type: "object", 
      description: "The definition of the element.",
      required: true
    },
    "inner": {
      title: "Inner",
      type: "*",
      description: "The inner content which should be fully rendered.",
      required: true
    }, "options": {
      title: "Options",
      type: "object",
      description: "The options for the element.",
      required: true
    }
  }
});
// var namespace = module.exports.namespace;

var f = require("../scripts/f.js");
var jsonRender = require("../interface/jsonRender.js");
var prependToId = "_uae__";

// var define = {
//     namespace: "ua.element.placeholder",
//     title: "Universe App Element Placeholder",
//     description: "Specialized component used for marking a component for the interface, handling controls, and developer transparency.",
//     editable: false,
//     fields: {
//       "define": {
//         type: "object", 
//         description: "The definition of the element.",
//         required: true
//       },
//       "inner": {
//         type: "*",
//         description: "The inner content which should be fully rendered.",
//         required: true
//       }, "options": {
//         type: "object",
//         description: "The options for the element.",
//         required: true
//       }
//     }, output: {
//       namespace: "uae",
//       attributes: {
//         name: {
//           type: "name",
//           description: "The name of the data to be saved. This will only be outputed for controls. If it's not a control it will not be outputed."
//         },
//         id: {
//           type: "id",
//           description: `The ID of the element. If an ID is detected in the parent of the inner content (first holding element), that id will be appended to '${prependToId}'. This helps you work with the element specifically. If it's a control, try the name field but note that for uae elements name fields are not guarenteed unique like those used in a data repeater.`
//         }
//       }
//     }
//   }; module.exports.define = define;


  /**
   * Must be called directly, render may fail if used as part of a template.
   * @param {*} options The jsonObject to render.
   * @returns The rendered DOM object.
   */
  exports.render = async function (options) {

    var ele = document.createElement("uae");
    var inner = null;

    // check is options.inner null, undefined, or empty
    if (options.inner === null || options.inner === undefined || options.inner === "") {
      console.error("The Universe App Tool Element either did not receive any inner content or the inner content was empty.", define, options);

      var errorMessage = "The Universe App Tool Element either did not receive any inner content or the inner content was empty."
      // do I have a define and a define.namespace?
      if (define) {
        if ("namespace" in define) {
          errorMessage += `Error at ${define.namespace}.`;
        }
      }

      throw errorMessage;
    }

    //process inner
    if (f.isDomElement(options.inner)) {
        inner = options.inner;
    } else {
        // console.log("I'm not a dom element", {
        //     options: options
        // });
        inner = await jsonRender.render(options.inner);
        // ele.appendChild(jsonRender(options.inner));
    }

    //if options.inner (a dom element) first child matches <uae namespace="ua.alert"> ensure that it has the namespace = "ua.alert"
    var isError = false;
    // if (inner.firstChild) {
        if (inner.nodeName === "UAE") {
            if (inner.getAttribute("namespace") === "ua.element.error") {
                isError = true;
            }
        }
    // }

    if (isError) {
    } else if ("control" in options.define) {
        if (options.define.control) {

            //get the name attribute
            var name = options.name;

            //if not name or it's undefined inner.getAttribute("name");
            if (name === undefined) {
                name = inner.getAttribute("name");
            }

            // console.log("Control", options);

            if (name === null) {
                throw `${options.namespace} says it's a control, but it's missing a data name. Controls can not be saved without a name.`;
            }

            ele.setAttribute("name", name);

            //remove name from inner
            inner.removeAttribute("name");

            // ele.setAttribute("control", "true");

        }
    }

    ele.setAttribute("namespace", options.define.namespace);

    var myid = "";

    //get id if it exsits

    // try {
      //if inner is an object and not an array
      if (typeof inner === "object" && !Array.isArray(inner)) {
        if (f.isDomElement(inner)) {
          if (!(inner.nodeName === "#document-fragment")) {

          // try {
            myid = inner.getAttribute("id"); 
          // } catch (error) {
            //I'm probably a document fragment
          // }
          }
        } else {
          if ("id" in inner) {
            myid = inner.id;
          }
        }
      }
    // } catch (error) {
    //   var err = new Error(`The ID could not be scraped from the inner content. ${error.toString()}`);
    //   err.dom = inner;
    //   err.isDom = f.isDomElement(inner);

    //   if (err.isDom) {
    //     err.domType = inner.nodeType;
    //     err.domName = inner.nodeName;
    //     err.outerHTML = inner.outerHTML;
    //   }

    //   err.objectType = typeof inner;
    //   err.error = error;
    //   throw  err;
    // }


    //check through  the definition each field and find a field that has addtouae set to true
    for (var key in options.define.fields) {
      if (options.define.fields[key].addtouae) {

        var val = options.options[key];
        // console.log("Adding to uae", {
        //   key, val, options
        // });

        //if the value is undefined, don't add it
        if (val === undefined) {
          // console.log(`Skipping ${key} because it's undefined.`);
          continue;
        }

        // console.log("Adding to uae", {
        //   key, val, options
        // });

        //if it's an array or an object - make it a json string
        if (Array.isArray(val)) {
          val = JSON.stringify(val);
        } else if (typeof val === "object") {
          val = JSON.stringify(val);
        }

        ele.setAttribute(key, val);
      }
    }



    // myid = inner.getAttribute("id");
    
    //if my id is not blank, and not null or undefined
    if (!(myid === "" || myid === null || myid === undefined)) {
      ele.setAttribute("id", `${prependToId}${myid}`);
    }

    ele.appendChild(inner);
    return ele;

  }