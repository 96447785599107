/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.fs.center",
  title: "A Centered Container in the View", 
  description: "Creates a verticle and horizontal centered container in the view. This is useful for creating a centered container in the view.",
  fields: {
    "containerClass": {
      title: "Container Class",
      type: "class", 
      description: "The class to be added to the container."
    }, 
    "inner": {
      title: "Inner",
      type: "inner",
      description: "The inner content of the container.",
      required: true
    }
  },
  pssthrough: true
});
// var namespace = module.exports.namespace;

var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");

// var define = {
//   namespace: "ua.fs.center",
//   title: "A Centered Container in the View", 
//   description: "Creates a verticle and horizontal centered container in the view. This is useful for creating a centered container in the view.",
//   fields: {
//     "containerClass": {
//       type: "class", 
//       description: "The class to be added to the container."
//     }, 
//     "inner": {
//       type: "inner",
//       description: "The inner content of the container.",
//       required: true
//     }
//   }
// }; module.exports.define = define;

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  var element =  {
    "n": "div",
    "class": "vh-100 vw-100 d-flex align-items-center justify-content-center text-center",
    "i": {
      "n": "div",
      "c": "",
      "i": options.inner
    }
  };

  if ("containerClass" in options) {
    element.i.c = options.containerClass;
  }

 
  return element;

}