/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */

var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.marquee",
  title: "Image Marquee",
  description: "A marquee of images.",
  fields: {
    "inner": {
      title: "Items",
      type: "array", 
      description: "A list of img or svg elements to display in the marquee.",
      required: true
    },
    "reverse": {
      title: "Reverse",
      type: "boolean", 
      description: "Should the Marquee flow in reverse?",
      default: false
    },
    "verticle": {
      title: "Verticle",
      type: "boolean", 
      description: "Should the Marquee show veritcally as opposed to horizontally?",
      default: false
    },
    "duration": {
      title: "Duration",
      type: "number",
      description: "How long should the marquee take to complete a cycle?",
      default: 15
    }
  },
});

// var namespace = "ua.marquee";
// exports.namespace = namespace;

// var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");

// var required = ["inner"];
// exports.required = required;

// exports.define = {
//   namespace: namespace,
//   title: "Image Marquee",
//   fields: {
//     "inner": {
//       type: "array", 
//       description: "A list of img or svg elements to display in the marquee."
//     },
//     "reverse": {
//       type: "boolean", 
//       description: "Should the Marquee flow in reverse?",
//       default: false
//     },
//     "verticle": {
//       type: "boolean", 
//       description: "Should the Marquee show veritcally as opposed to horizontally?",
//       default: false
//     },
//     "duration": {
//       type: "number",
//       description: "How long should the marquee take to complete a cycle?",
//       default: 15
//     }
//   },
//   required: required
// } 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // var options = uae.GetOptions(json, required);
  
  var marquee = {
    n: "div",
    c: "marquee",
    s: "--marquee--duration: 15s",
    i: [{
      n: "div",
      c: "marquee__group",
      i: options.inner
    }, {
      n: "div",
      c: "marquee__group",
      "aria-hidden": "true",
      i:  options.inner
    }]
  };

  if ("revierse" in options && options.reverse) {
    marquee.c += " marquee--reverse";
  }

  if ("verticle" in options && options.verticle) {
    marquee.c += " marquee--verticle";
  }

  if ("duration" in options) {
    marquee.s = `--marquee--duration: ${options.duration}s`;
  }

  return marquee;
}

/** 
<div class="marquee" style="--marquee--duration:15s">
  <!-- 
Marquee Reverse marquee--reverse -->
  <div class="marquee__group">
    <img src="../imgs/pt/afr.svg">
    <img src="../imgs/pt/ali.svg">
    <img src="../imgs/pt/amx.svg">
    <img src="../imgs/pt/apy.svg">
    <img src="../imgs/pt/bnk.svg">
    <img src="../imgs/pt/cch.svg">
    <img src="../imgs/pt/dsv.svg">
    <img src="../imgs/pt/gpy.svg">
    <img src="../imgs/pt/klr.svg">
    <img src="../imgs/pt/mas.svg">
    <img src="../imgs/pt/sep.svg">
    <img src="../imgs/pt/sof.svg">
    <img src="../imgs/pt/visa.svg">
    <img src="../imgs/pt/we.svg">
  </div>

  <div aria-hidden="true" class="marquee__group">
    <img src="../imgs/pt/afr.svg">
    <img src="../imgs/pt/ali.svg">
    <img src="../imgs/pt/amx.svg">
    <img src="../imgs/pt/apy.svg">
    <img src="../imgs/pt/bnk.svg">
    <img src="../imgs/pt/cch.svg">
    <img src="../imgs/pt/dsv.svg">
    <img src="../imgs/pt/gpy.svg">
    <img src="../imgs/pt/klr.svg">
    <img src="../imgs/pt/mas.svg">
    <img src="../imgs/pt/sep.svg">
    <img src="../imgs/pt/sof.svg">
    <img src="../imgs/pt/visa.svg">
    <img src="../imgs/pt/we.svg">
</div>
</div> */
