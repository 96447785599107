/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for checking if a property is in an object and it is a specfied type.
 * 
 */

var namespace = "validate";

var supportedTypes = ["string", "number", "boolean", "object", "array", "list", "function", "promise", "throwable"];

/**
 * Validates a data object.
 * 
 * For strings (and only strings) it will make sure the string is not empty.
 * Supported checks:
 * - string, number, boolean, object, array, function, promise, throwable
 * 
 * @param {*} search The object to search in.
 * @param {*} key The key to search for.
 * @param {*} type The type to search for.
 * @returns {boolean} true if the key is in the object and is the correct type.
 */
function validate(search, key, type = "string") {
    return validate(search[key], type);
}

function validate(value, type) {
    //is the value null or undefined
    if (value === null || value === undefined) {
        return false;
    }

    //switch on the type
    switch (type) {
        case "string":
            //is the value a string
            return typeof value === "string" && value.length > 0;

        case "number":
            //is the value a number
            return typeof value === "number";

        case "boolean":
            //is the value a boolean
            return typeof value === "boolean";

        case "object":
            //is the value an object
            return typeof value === "object" && value !== null;

        case "array":
        case "list":
            //is the value an array
            return Array.isArray(value);

        case "function":
            //is the value a function
            return typeof value === "function";

        case "promise":
            //is the value a promise
            return value instanceof Promise;

        // case "throwable":
        //     //is the value an error
        //     return value instanceof Error;

        default:
            return false;
    }

    return false;

}

module.exports = {
    supportedTypes: supportedTypes,
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: validate,
    tests: [
        //make individual tests
        {
            namespace: `${namespace}.string`,
            must: true,
            run: async () => {
                var search = {
                    "string": "string"
                };
                var key = "string";
                var type = "string";
                $rtn = validate(search, key, type);
                return $rtn;
            }
        
    },{
        namespace: `${namespace}.stringNull`,
        must: false,
        run: async () => {
            var search = {
                "string": null
            };
            var key = "string";
            var type = "string";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    
},{
    namespace: `${namespace}.stringNullString`,
    must: false,
    run: async () => {
        var search = {
            "string": "null"
        };
        var key = "string";
        var type = "string";
        $rtn = validate(search, key, type);
        return $rtn;
    }

}, {
        namespace: `${namespace}.number`,
        must: true,
        run: async () => {
            var search = {
                "number": 1
            };
            var key = "number";
            var type = "number";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.boolean`,
        must: true,
        run: async () => {
            var search = {
                "boolean": true
            };
            var key = "boolean";
            var type = "boolean";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.object`,
        must: true,
        run: async () => {
            var search = {
                "object": {}
            };
            var key = "object";
            var type = "object";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.array`,
        must: true,
        run: async () => {
            var search = {
                "array": []
            };
            var key = "array";
            var type = "array";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.function`,
        must: true,
        run: async () => {
            var search = {
                "function": function () {}
            };
            var key = "function";
            var type = "function";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.promise`,
        must: true,
        run: async () => {
            var search = {
                "promise": new Promise((resolve, reject) => {})
            };
            var key = "promise";
            var type = "promise";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }]
};