/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.header",
  title: "Universe App Header",
  description: "A consistent header for apps built with Universe App Tools.",
  fields: {
    "icon": {
      title: "Icon",
      type: "icon", 
      description: "The icon used on the left side of the header.",
      default: false
    }, "title": {
      title: "Title",
      type: "string",
      description: "The title of the header.",
      required: true
    }, "description": {
      title: "Description",
      type: "string",
      description: "The description of the header."
    },
    "tools": {
      title: "Tools",
      type: "inner",
      description: "The items or buttons to display next to the header icon."
    },
    "inner": {
      title: "Inner",
      type: "inner",
      description: "The content to display at the bottom of the header as a start of a section."
    }, "image": {
      title: "Image",
      type: "image",
      description: "The image to be displayed in the header.",
      default: false
    }, "imageClass": {
      title: "Image Class",
      type: "string",
      description: "The class of the image."
    },
    "alt": {
      title: "Alt",
      type: "string",
      description: "The alt text for the image."
    }
  }
});
// var namespace = module.exports.namespace;
// var namespace = "ua.header";
// exports.namespace = namespace;

// var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");

// exports.define = {
//   namespace: "ua.header",
//   title: "Universe App Header",
//   description: "A consistent header for apps built with Universe App Tools.",
//   fields: {
//     "icon": {
//       type: "icon", 
//       description: "The icon used on the left side of the header.",
//       default: false
//     }, "title": {
//       type: "string",
//       description: "The title of the header.",
//       required: true
//     }, "description": {
//       type: "string",
//       description: "The description of the header."
//     },
//     "tools": {
//       type: "inner",
//       description: "The items or buttons to display next to the header icon."
//     },
//     "inner": {
//       type: "inner",
//       description: "The content to display at the bottom of the header as a start of a section."
//     }, "image": {
//       type: "image",
//       description: "The image to be displayed in the header.",
//       default: false
//     }, "imageClass": {
//       type: "string",
//       description: "The class of the image."
//     },
//     "alt": {
//       type: "string",
//       description: "The alt text for the image."
//     }
//   } 
// }

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  var header = {
    "n": "div",
    "c": "container-fluid p-0 m-0 ua-header",
    "i": []
  };

  //is their a icon property in options
  if (options.icon) {
    header.i.push({
      "n": "div",
      "c": "text-center p-3 ua-header-icon",
      "i": [{
        "n": "i",
        "c": `bi bi-${options.icon}`,
        "s": "font-size:4rem;"
      }]
    });
  }

  if (options.image) {

    var img = {
      n: "div",
      c: "me-2",
      s: "width: 4rem;",
      i: {
        "n": "img",
        "c": "img-fluid",
        "src": options.image
      }
    };

    if ("alt" in options) {
      img.i.alt = options.alt;
      img.i.title = options.alt; 
    }

    header.i.push(img);
  }

  if ("imageClass" in options) {
    img.i.c = options.imageClass;
  }

  var title = {
    "n": "div",
    "c": "ua-header-title-holster",
    "i": [{
      "n": "h2",
      "c": "fs-1 d-inline ua-header-title",
      "i": options.title
    }]
  };

  if (options.description) {
    title.i.push({
      "n": "p",
      "c": "text-muted m-0 p-0 d-inline",
      "i": options.description
    });
  }

  header.i.push(title);

  var element = false;

  if (options.tools) {
      
      var toolkit = {
        "n": "div",
        "c": "p-3",
        "i": options.tools
      };
  
      header.i.push(toolkit);
  }

  if (options.inner) {

    element = {
      "n": "section",
      "i": [
        header,
        {
          "n": "div",
          "c": "row ua-header-section",
          "i": options.inner
        }]
    }

  }

  if (!element) {
    element = header;
  }

  return element;

}

/** 
 * 
 * 
 <div class="container-fluid p-0 m-0 ua-header">
    <div class="text-center p-3 ua-header-icon">
        <i class="bi bi-${icon}" style="font-size:4rem;"></i>
    </div>
    <div class="p-0 m-0 ua-header-title-holster">
        <h2 class="fs-1 m-0 p-0 d-inline ua-header-title">${title}</h2>
        <p class="text-muted m-0 p-0 d-inline">${description}</p>
    </div>
    <div class="p-3">
        <button class="btn btn-underline-primary"><i class="bi bi-gear-wide-connected"></i></button>
    </div>  
</div> 
*/
