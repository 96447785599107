/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.platform.video",
  title: "Video Element",
  description: "The video element allows for the embedding of videos from various platforms.",
  fields: {
    "platform": {
      title: "Platform",
      required: true,
      type: "list",
      items: ["vimeo", "youtube"],
      description: "The platform provider of the video."
    }, 
    "id": {
      title: "Video ID",
      required: true,
      type: "string",
      description: "The id of the video on the platform."
    }
  }, errors: {
    "platform": {
      title: "Platform Not Supported",
      description: "The video platform is not supported."
    }
  }
});

// var jsonRender = require("../interface/jsonRender.js");
var element = require("../interface/element.js");

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  var embed = {};

  if (options.platform == "youtube") {
    embed = {
      n: "iframe",
        src: "https://www.youtube.com/embed/" + options.id,
        width: "100%",
        height: "640px",
        frameborder: "0",
        allowfullscreen: "allowfullscreen"
    };
  } else if (options.platform == "vimeo") {
    embed = {
      n: "iframe",
        src: "https://player.vimeo.com/video/" + options.id,
        width: "100%",
        height: "640px",
        frameborder: "0",
        allowfullscreen: "allowfullscreen"
    };
  } else {
    _.errors.platform.throw({
      platform: options.platform
    });
  }

  return embed;

}