/*!
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Universe App Tools.
 * 
 * Justin K Kazmierczak
 * 
 * Saves and retrieves files that are stored in the manager.
 * 
 * The manager module could be replaced to work with cloud files like native files.
 * 
 */

// var jckConsole = require("@jumpcutking/console");
var GenerateSafeError = require("./functions/generateSafeError.js").function;
var namespace = "uam.filemanager";
var resolve = require("./functions/resolve.js").function;
// var resolvable = require("./functions/resolvable.js").function;
var padZeros = require("./functions/padZeros.js").function;
var fs = require("fs");
var fsPromises = require("fs").promises;
var path = require("path");
// var lockfile = require('proper-lockfile');
var sleep = require("./functions/sleep.js").function;

/**
 * Deletes a file or a folder.
 * @param {*} uri The path to the file.
 * @param {*} recursive recursive into the folder and delete all the contents.
 * @throws Error if the file doesn't exist.
 */
function remove(uri, recursive = false) {
    uri = resolve(uri);

    if (fs.existsSync(uri)) {
       //is it a directory?
         if (fs.statSync(uri).isDirectory()) {
              if (recursive) {
                fs.rmSync(uri, { recursive: true });
              } else {
                fs.rmdirSync(uri);
              }
         } else {
              fs.unlinkSync(uri);
         }
    }
} module.exports.remove = remove;



/**
 * Copies the contents of a folder to another folder.
 * Creates the destination folder if it doesn't exist.
 * Creates the subfolders if they don't exist.
 * @param {*} src The source folder.
 * @param {*} dest The destination folder.
 */
function copyFolder(src, dest) {

    src = resolve(src);
    dest = resolve(dest);

    console.log(`Copying folder ${src} to ${dest}`);

    //does the source folder exist?
    if (!fs.existsSync(src)) {
        throw new Error(`Source folder does not exist: ${src}`);
    }

    //does the destination folder exist?
    if (!fs.existsSync(dest)) {
        fs.mkdirSync(dest, { recursive: true });
    }

    //start copying the folder and it's contents
    copyFolderRecursive(src, dest);

    console.log(`Copied folder ${src} to ${dest}`);

} module.exports.copyFolder = copyFolder;

function copyFolderRecursive(src, dest) {
    
        //get the files in the folder
        var files = fs.readdirSync(src);
    
        //for each file detect if it is a directory
        for (var i = 0; i < files.length; i++) {
    
            var filePath = path.join(src, files[i]);
            var stats = fs.statSync(filePath);
    
            // is it a directory
            if (stats.isDirectory()) {
                //recurse
                copyFolderRecursive(filePath, path.join(dest, files[i]));
            } else {
                //copy the file
                fs.copyFileSync(filePath, path.join(dest, files[i]));
            }
    
        }
    
    }

/**
 * Saves a JSON object to the file manager.
 * @param {*} uri File path
 * @param {*} json Object to store
 */
function saveJSON(uri, json, replacer = null, spacing = 2) {

    uri = resolve(uri);

    //if the folder directory doesn't exsits create it recursively
    var dir = uri.split("/");
    dir.pop();
    dir = dir.join("/");
    if (!fs.existsSync(dir)) {
        fs.mkdirSync(dir, { recursive: true });
    }

    fs.writeFileSync(uri, JSON.stringify(json, replacer, spacing)) // spacing level = 2)

} module.exports.saveJSON = saveJSON;

/**
 * Copies a file from the source to the destination.
 * @param {String} _uri The path to the file. Will resolve.
 * @param {String} _destUri The path to the destination. Will resolve. 
 * @param {Boolean} overide Overide the file if it already exists. Default is false.
 * @returns {String} The fully qualified path to the new file.
 */
function copy(_uri, _destUri, overide = false) {
    
    var uri = resolve(_uri);
    var destUri = resolve(_destUri);


    //does the soure _uri exist?
    if (!fs.existsSync(uri)) {
        throw new Error(`Source file does not exist: ${uri}`);
    }

    if (fs.existsSync(destUri)) {
        if (overide == false) {
            throw new Error(`Destination file already exists: ${destUri}`);
        }
    }

    //get the full directory path
    var dir = destUri.split("/");
    dir.pop();
    dir = dir.join("/");
    

    //copy the file
    fs.copyFileSync(uri, destUri);

    return destUri;

} module.exports.copy = copy;

/**
 * Joins paths together - without resolving.
 * If you want to resolve and join multiple paths, use resolve. (First path only).
 * @param  {...any} args 
 * @returns Uses path.join
 */
function join(...args) {
    return path.join(...args);
} module.exports.join = join;

/**
 * Resloves a path based on the APP as the home directory. Uses path.join on additional arguments.
 * @! => process.cwd()
 * 
 * @!/functions will equal /myapp path.join with "./functions"
 * /myapp/functions
 * @param {*} path The path to resolve. Path.Join arguments
 * @returns an adjusted path
 */
module.exports.resolve = resolve;

/**
 * Using the @! directory path, resolves a path from a fully qualified uri to a path relative to that directory.
 * @param {*} uri The fully qualified path to the file.
 * @param  {...any} _path The path to resolve.
 * @returns The resolved path.
 */
function ResolveFrom(uri, ..._path) {

    //if the tilda is in the uri
    if (uri.indexOf("~") != -1) {
        throw new Error("The uri contains a ~. URI ");
    }

    //for each path remove @!/
    for (var i = 0; i < _path.length; i++) {
        _path[i] = _path[i].replace("@!", "");
        // _path[i] = _path[i].replace("~", "");
    }

    return path.join(uri, ..._path);
} module.exports.ResolveFrom = ResolveFrom;

/**
 * Turns a fully qualified URI to a resolvable based on the APP as the home directory.
 * @param {*} uri The fully qualified path to the file to make resolvable.
 * @returns The resolvable path.
 */
function MakeResolvable(uri) {
    return uri.replace(resolve("@!"), "@!");
} module.exports.MakeResolvable = MakeResolvable;

// function relative

/**
 * Does the file exist?
 * @param {*} uri The path to the file.
 * @returns Does the file exist? Boolean. returns fs.existsSync(uri);
 */
function exists(uri) {
    uri = resolve(uri);
    return fs.existsSync(uri);
} module.exports.exists = exists;

/**
 * Creates a directory.
 * @param {*} uri The path to the directory.
 * @returns {Boolean} True if the directory was created. Returns fs.mkdirSync(uri, { recursive: true });
 */
function createDirectory(uri) {
    uri = resolve(uri);
    return fs.mkdirSync(uri, { recursive: true });
} module.exports.createDirectory = createDirectory;

/**
 * Checks to see if a directory exists, if not, creates it.
 * @param {*} uri The path to the directory.
 * @returns {Boolean} True if the directory was created. Returns createDirectory(uri);
 */
function createDirectoryIfNotExists(uri) {
    uri = resolve(uri);
    if (!exists(uri)) {
        return createDirectory(uri);
    }
} module.exports.createDirectoryIfNotExists = createDirectoryIfNotExists;

/**
 * Clears the contents of a directory.
 * @param {*} uri The path to the directory.
 */
function emptyDirectory(uri) {
    
        uri = resolve(uri);

        //ensure it ends with a "/"
        if (uri[uri.length - 1] != "/") {
            uri = uri + "/";
        }

        fs.rmSync(uri, { recursive: true });
        // fs.rmSync(uri, { recursive: true });

        //recreate the directory
        createDirectory(uri);
    
} module.exports.emptyDirectory = emptyDirectory;

// /**
//  * Copy and overwrite a file.
//  * @param {*} src the URI to the file
//  * @param {*} dest the URI to the destination
//  * @param {*} overwrite overwrite the file if it already exists
//  */
// function copy(src, dest, overwrite = false) {
//     src = resolve(src);
//     dest = resolve(dest);

//     // if (!fs.existsSync(src)) {
//     //     throw new Error(`Source file does not exist: ${src}`);
//     // }

//     if (fs.existsSync(dest)) {
//         if (overwrite == false) {
//             throw new Error(`Destination file already exists: ${dest}`);
//         }
//     }

//     fs.copyFileSync(src, dest);

// } module.exports.copy = copy;

/**
 * Changes the file extension of a path.
 * @param {*} uri The uri to change the extension of.
 * @param {*} ext The new extension.
 * @returns The uri with the new extension.
 */
function ChangeFileExt(uri, ext) {

    if (ext[0] == ".") {
        ext = ext.substr(1);
    }

    return RemoveFileExt(resolve(uri)) + `.${ext}`;
} module.exports.ChangeFileExt = ChangeFileExt;

/**
 * Removes the file extension from a path.
 * @param {*} uri The uri to remove the extension from.
 * @returns The uri without the extension.
 */
function RemoveFileExt(uri) {
    var pos = uri.lastIndexOf(".");
    return uri.substr(0, pos < 0 ? uri.length : pos);
} module.exports.RemoveFileExt = RemoveFileExt;

/**
 * Joins a URL together.
 * Maintians the :// and replaces \ with /
 */
function joinURL(...args) {

    var uri = path.join(...args);

    //replace \ with /
    uri = uri.replace(/\\/g, "/");
    uri = uri.replace(/:\//g, "://");

    return uri;

} module.exports.joinURL = joinURL;

/**
 * Loads and parses a JSON file.
 * @param {*} uri file path
 */
function loadJSON(uri) {

    uri = resolve(uri);
    //load the config
    return JSON.parse(fs.readFileSync(uri, 'utf8').toString());

} module.exports.loadJSON = loadJSON;

/**
 * Loads any file with a UTF8 encoding/
 * @param {*} uri The path to the file.
 * @param {*} encoding The encoding to use. Default is UTF8.
 * @returns The file contents.
 * @throws Error if the file doesn't exist.
 */
function load(uri, encoding = "utf8") {
    uri = resolve(uri);
    return fs.readFileSync(uri, encoding).toString();
} module.exports.load = load;

/**
 * Does the log folder exist? If not, create it.
 * @returns {String} The path to the log folder.
 */
function GetLogFolder(folder = "", logFilePath = false) {


    //does the log folder exist?
    createDirectoryIfNotExists(resolve("@!/.log"));

    //correct the folder with a closing "/"
    folder = correctFolder(folder);

    //create the log folder if it doesn't exist
    if (logFilePath == false) {
        logFilePath = path.join(__dirname,`../.log/${folder}`);
    }

    createFolderRecursively(logFilePath, path.join(__dirname, "../.log"));

    // console.info ("Log folder: ", logFilePath);
    return logFilePath;
} module.exports.GetLogFolder = GetLogFolder;

/**
 * Saves a JSON object to the file manager.
 * @param {*} uri File path
 * @param {*} data text or binary data to store
 */
function save(uri, data) {

    uri = resolve(uri);

    //if the folder directory doesn't exsits create it recursively
    var dir = uri.split("/");
    dir.pop();
    dir = dir.join("/");
    if (!fs.existsSync(dir)) {
        fs.mkdirSync(dir, { recursive: true });
    }

    fs.writeFileSync(uri, data) // spacing level = 2)

} module.exports.save = save;


/**
 * Resolves and compares a from and a to, returning path.relative
 * @param {*} from The from URI
 * @param {*} to The to URI
 * @returns The relative path.
 */
function relative(from, to) {
    return path.relative(resolve(from), resolve(to));
} module.exports.relative = relative;

// /**
//  * Creates a path that can be resolved from the app root using the current app root.
//  * @! => process.cwd()
//  * @!/example.html will equal /my/home/my/app/path/example.html
//  * @param {*} path The path to resolve.
//  * @returns an adjusted path. Example: @!/example.html
//  * @deprecated Use resolve instead.
//  */
// function resolveable(...args) {
//     return resolvable(...args);
// } module.exports.resolveable = resolveable;

/**
 * Creates a folder recursively, but limits it to the starting point
 * @param {*} folder the name of the folder
 * @param {*} limitStartingPoint the starting point - do not end in a "/"
 */
function createFolderRecursively(folder, limitStartingPoint) {

    // console.log("Creating folder recursively...", {
    //     foldr: folder,
    //     limit: limitStartingPoint
    // });

    if (folder === limitStartingPoint) {
    
    //   console.info("Reached starting point.", {
    //     test: (folder === limitStartingPoint)
    //   });
      // Reached the starting point, start creating folders from here
      createFolder(folder);
    } else {
      // Split the folder path into parts
      var parts = folder.split('/');

      var ReachedStartingPoint = false;
      
      // Recursively create parent folders
      for (let i = 1; i < parts.length; i++) {

        var parentFolder = parts.slice(0, i).join('/');
        var curFolder = parts.slice(0, i + 1).join('/');

        // console.log("Parent folder: ", {
        //     parnt: parentFolder,
        //     currt: curFolder,
        //     limit: limitStartingPoint,
        //     ReachedStartingPoint: ReachedStartingPoint
        // });
        
        if (parentFolder === limitStartingPoint) {
          // Reached the starting point, start creating folders from here
          ReachedStartingPoint = true
        }

        if (ReachedStartingPoint) {
            createFolder(curFolder);
        }

      }
    }
 }
  
/**
 * Creates a folder if it doesn't exist.
 * @param {*} folder The folder to create.
 */
function createFolder(folder) {
    if (!fs.existsSync(folder)) {
        // The folder does not exist, create it
        fs.mkdirSync(folder);
        // console.log(`Created folder: ${folder}`);
    }
}

/**
 * Get's the backup date in the format of YYYYMMDD-HHMMSS
 * @returns {String} The backup date string.
 */
function getBackupDate() {
    var date = new Date();
    // console.log("Date test.", {
    //     year: date.getFullYear(),
    //     month: date.getMonth(),
    //     date: date.getDate(),
    //     hours: date.getHours(),
    //     minutes: date.getMinutes(),
    //     seconds: date.getSeconds(),
    //     withPadded: date.getFullYear() + padZeros(date.getMonth(), 2) + padZeros(date.getDate(), 2) + "-" + padZeros(date.getHours(), 2) + padZeros(date.getMinutes(), 2) + padZeros(date.getSeconds(), 2)
    // });
    return date.getFullYear() + padZeros(date.getMonth(), 2) + padZeros(date.getDate(), 2) + "-" + padZeros(date.getHours(), 2) + padZeros(date.getMinutes(), 2) + padZeros(date.getSeconds(), 2);
    // return dateStr;
} module.exports.getBackupDate = getBackupDate;

/**
 * Saves a backup of the file.
 * @param {*} uri The fully qualified path to the file.
 * @param {*} folder The folder to save the backup to or save in root of the .log file
 */
function SaveBackupOf(uri, folder = "", logFilePath = false) {
    
    var logPath = GetLogFolder(folder, logFilePath);
    // folder = correctFolder(folder);
    // if (folder != "") {
    //     folder = folder + "/";
    // }

    var fileNameAndExt = path.basename(uri);
    var fileContents = fs.readFileSync(uri);
    fs.writeFileSync(path.join(logPath, `/${getBackupDate()} ${fileNameAndExt}`), fileContents);

} module.exports.SaveBackupOf = SaveBackupOf; module.exports.Backup = SaveBackupOf;

/**
 * Saves a log file to ./log folder of the app
 * @param {*} filename The name of the file (without the extension or path). Will add .json to the end.
 * @param {*} json The object to save.
 * @param {*} folder The folder to save the backup to or save in root of the .log file
 * @param {*} logFilePath The path to the log folder. If false, it will use the default path.
 * @returns {String} The fully qualified path to the file.
 */
function SaveLogJSON(filename, json, folder = "", logFilePath = false) {  

    // folder = correctFolder(folder);
    var logPath = GetLogFolder(folder, logFilePath);

    //if .json is not at the end of the filename, add it
    // check the last 5 characters
    if (filename.substr(filename.length - 5) != ".json") {
        filename = `${filename}.json`;
    }

    // var npath = `/${filename}`;

    // if (folder != "") {
    //     npath = `/${folder}/${npath}`;
    // }

    var uri = path.join(logPath, `/${filename}`);
    saveJSON(uri, json);

    return uri;

} module.exports.SaveLogJSON = SaveLogJSON;

/**
 * Saves a log file to ./log folder of the app
 * @param {*} filename The name of the file (MUST include the file extension).
 * @param {*} text The text to save.
 * @param {*} folder The folder to save the backup to or save in root of the .log file
 * @param {*} logFilePath The path to the log folder. If false, it will use the default path.
 * @returns {String} The fully qualified path to the file.
 */
function SaveLog(filename, text, folder = "", logFilePath = false) {
    // folder = correctFolder(folder);
    var logPath = GetLogFolder(folder, logFilePath);

    // //if .json is not at the end of the filename, add it
    // // check the last 5 characters
    // if (filename.substr(filename.length - 4) != ".log") {
    //     filename = `${filename}.log`;
    // }

    var npath = `/${filename}`;

    if (folder != "") {
        npath = `/${folder}/${npath}`;
    }

    var uri = path.join(logPath, `/${npath}`);
    save(uri, text);

    return uri;

} module.exports.SaveLog = SaveLog;

// function SaveLogJSONbyNamespaceItem(filename, item, folder = "", logFilePath = false) {

// // /**
// //  * Loads the previously saved config.define.json file and adds the definitions to the config only if there is a change.
// //  */
// // async function saveDefine(defin) {

// //     var curDefines = {}
// //     //get json 
// //     try {
// //         curDefines = fm.LoadLogJSON(configDefineFilename);
// //     } catch (error) {
        
// //     }
// //     // var curDefines = fm.loadJSON(configDefinePath);
// //     var oriDefines = {...curDefines};

// //     //is the key in cur define?

// //     var curDefine = curDefines[defin.namespace];

// //     if (curDefine == undefined) {
// //         //add it
// //         curDefines[defin.namespace] = defin;
// //     } else {

// //         //compare the two
// //         var hasChanges = false;
// //         var i = 0;
// //         while (Object.keys(defin).length > i) {
// //             var key = Object.keys(defin)[i];
// //             if (curDefine[key] != defin[key]) {
// //                 hasChanges = true;
// //             }
// //             i = i + 1;
// //         }

// //         if (hasChanges) {
// //             //add it
// //             curDefines[defin.namespace] = defin;
// //         }

// //     }

// //     // curDefines = CompareDefinitions(curDefines);
    
// //     //save json
// //     // fm.BackupJSON(configDefineFilename, oriDefines, "config"); // to log folder by filename and defin and a folder name
// //     // fm.saveJSON(configDefinePath, curDefines); // save the definition file

// //     try {
// //         fm.SaveLogJSON(configDefineFilename, curDefines);
// //         fm.SaveLogJSON(define.namespace, defin, "configurations");
// //     } catch (error) {
        
// //     }

// // }
 

//     //load current file if it exists
//     var curJson = {};
//     try {
//         curJson = LoadLogJSON(filename, folder, logFilePath);
//     } catch (error) {
        
//     }

//     //add the item to the json
//     curJson[item.namespace] = {...item};

//     //save the json
//     return SaveLogJSON(filename, curJson, folder, logFilePath);



// } module.exports.SaveLogJSONbyNamespaceItem = SaveLogJSONbyNamespaceItem;


// const fs = require('fs');
// const path = require('path');

// const fs = require('fs');
// const lockfile = require('proper-lockfile');
// const path = require('path');

function LockFile(filePath) {
    const lockFilePath = resolve(`${filePath}.lock`);
    fs.writeFileSync(lockFilePath, '', { flag: 'wx' });
}

function UnlockFile(filePath) {
    const lockFilePath = resolve(`${filePath}.lock`);
    if (fs.existsSync(lockFilePath)) {
        fs.unlinkSync(lockFilePath);
    }
}

async function waitForUnlock(filePath) {
    const lockFilePath = resolve(`${filePath}.lock`);

    var count = 0;
    while (fs.existsSync(lockFilePath)) {
        count++;
        await new Promise(resolve => setTimeout(resolve, 100)); // Check every 100ms

        if (count == 100) {
            console.warn("Still waiting for file to unlock: ", filePath);
        }
    }
}


/**
 * Safely saves JSON to a file with a namespace update.
 * @param {*} typeOrFolder The type of file or the folder to save the file in.
 * @param {*} item The item to save.
 * @param {*} folder The folder to save the backup to or save in root of the .log file
 * @param {*} logFilePath The path to the log folder. If false, it will use the default path.
 */
async function SaveLogJSONbyNamespaceItem(typeOrFolder, item, folder = "", logFilePath = false) {

    //error if I have no namespace
    if (!("namespace" in item)) {
        throw new Error("The item does not have a namespace.");
    }

    // var logPath = GetLogFolder(typeOrFolder, logFilePath);

    // var filePath = path.join(logPath, `${item.namespace}.json`);
    // var lockFilePath = `${filePath}.lock`;

    var nfolder = typeOrFolder;

    if (folder != "") {
        nfolder = `${folder}/${nfolder}`;
    }

    SaveLogJSON(`${item.namespace}.json`, item, nfolder, logFilePath);
}
module.exports.SaveLogJSONbyNamespaceItem = SaveLogJSONbyNamespaceItem;


/**
 * Loads a log file from the log folder of the app/
 * @param {*} filename The name of the file (without the extension or path).
 * @param {*} folder The folder to save the backup to or save in root of the .log file
 * @param {*} logFilePath The path to the log folder. If false, it will use the default path.
 * @returns {object} The JSON object.
 */
function LoadLogJSON(filename, folder = "", logFilePath = false) {
    
        // folder = correctFolder(folder);
        var logPath = GetLogFolder(folder, logFilePath);
    
        var uri = path.join(logPath, `/${filename}.json`);
        return loadJSON(uri);
    
} module.exports.LoadLogJSON = LoadLogJSON;

/**
 * Saves a log file to log folder of the app
 * @param {*} filename The name of the file (without the extension or path).
 * @param {*} json The object to save.
 * @param {*} folder The folder to save the backup to or save in root of the .log file
 * @param {*} logFilePath The path to the log folder. If false, it will use the default path.
 * @returns {String} The fully qualified path to the file.
 */
function BackupJSON(filename, json, folder = "", logFilePath = false) {

    //add date and time to filename
    filename = `${filename} ${getBackupDate()}`;
    return SaveLogJSON(filename, json, folder, logFilePath);

} module.exports.BackupJSON = BackupJSON;

/**
 * Removes log files (recursively) that are older than the specified number of days.
 * @param {*} days The number of days to keep the log files.
 * @param {*} keepfiles An array of files to keep.
 * @returns {Array} An array of files that were deleted.
 */
function PruneLog(days, keepfiles = []) {



    var logDir = GetLogFolder();
    var now = new Date();
    var cutoff = now.setDate(now.getDate() - days);

    console.log(`Prunning ${logDir} for files older than ${days} days.`);

    var info = PruneAllFilesInDir(logDir, cutoff, keepfiles);

    //calculate the total number of bytes deleted
    var totalBytes = 0;
    for (var i = 0; i < info.deleted.length; i++) {
        totalBytes += info.deleted[i].size;
    }

    console.log(`Pruned ${info.deleted.length} log files, with ${info.errors.length} errors and saved ${totalBytes}b.`);
    SaveLogJSON("prune.log.json", info);

    return info;

} module.exports.PruneLog = PruneLog;


/**
 * Prunes all files in a directory recursively.
 * @param {*} dir The directory to prune.
 * @param {*} cutoff The cutoff Date.
 * @param {*} keepfiles An array of files to keep.
 * @returns {Object} An object with the following properties:
 */
function PruneAllFilesInDir(dir, cutoff, keepfiles =[]) {//, ignoreFilesFolders = []) {

    dir = resolve(dir);

    var files = fs.readdirSync(dir);

    console.log(`Checking ${files.length} files in ${dir}`);

    var info = {
        errors: [],
        deleted: []
    }

    //for each file detect if it is a directory
    for (var i = 0; i < files.length; i++) {

        //the keepfile array will be based on the directory

        var filePath = path.join(dir, files[i]);

        //should I keep the file
        if (keepfiles.includes(files[i])) {
            continue;
        }


        var stats = fs.statSync(filePath);
        var fileAge = new Date(stats.mtime).getTime(); // Use last modified time
        var fileSize = stats.size;

        // is it a directory
        if (stats.isDirectory()) {
            //recurse
            try {

                // //if the file is in the ignore list, skip it
                // if (ignoreFilesFolders.includes(files[i])) {
                //     continue;
                // }

                if (PruneEmptyFolder(filePath)) {
                    info.deleted.push({
                        path: filePath,
                        size: 0
                    });
                    continue;
                }

                var rtn = PruneAllFilesInDir(filePath, cutoff);

                if (rtn.errors.length > 0) {
                    if (info.errors > 0) {
                        info.errors = info.errors.concat(rtn.errors, info.errors);
                    } else {
                        info.errors = rtn.errors;
                    }
                }

                if (rtn.deleted.length > 0) {
                    if (info.deleted > 0) {
                        info.deleted = info.deleted.concat(rtn.deleted, info.deleted);
                    } else {
                        info.errors = rtn.deleted;
                    }
                }

                if (PruneEmptyFolder(filePath)) {
                    info.deleted.push({
                        path: filePath,
                        size: 0
                    });
                    continue;
                }

            } catch (error) {
                error = GenerateSafeError(error);
                console.warn(`Error deleting file ${filePath}`, error);
                info.errors.push({
                    path: filePath,
                    age: fileAge,
                    size: fileSize,
                    error: error
                });
            }

        } else {
            // If last modified time is older than the cutoff date, delete the file
            if (fileAge < cutoff) {

                try {

                    //if the file is in the ignore list, skip it
                    // if (ignoreFilesFolders.includes(files[i])) {
                    //     continue;
                    // }
                    
                    fs.unlinkSync(filePath);

                    info.deleted.push({
                        path: filePath,
                        size: fileSize
                    });

                } catch (error) {
                    error = GenerateSafeError(error);
                    console.warn(`Error deleting file ${filePath}`, error);
                    info.errors.push({
                        path: filePath,
                        age: fileAge,
                        size: fileSize,
                        error: error
                    });

                }
            } //the file
        }

    }

    return info;

} module.exports.PruneAllFilesInDir = PruneAllFilesInDir;


function PruneEmptyFolder(dir) {
    var dirFiles = fs.readdirSync(dir);
    if (dirFiles.length == 0) {
        //delete the directory
        fs.rmdirSync(dir);
        // info.deleted.push({
        //     path: dir,
        //     size: 0
        // });
        return true;
    }

    return false;

} module.exports.PruneEmptyFolder = PruneEmptyFolder;

/**
 * Get's the latest JSON file in a specified folder
 * @param {*} folder The fully qualified path to the folder.
 * @returns {*} The fully qualified path to the file or false if no files were found.
 */
function getLatestJsonFile(folder) {
    var files = fs.readdirSync(folder)
      .filter(file => path.extname(file) === '.json')
      .sort((a, b) => b.localeCompare(a)); // Sort in descending order
  
    if (files.length > 0) {
      return path.join(folder, files[0]);
    }
  
    return false; // No .json files found in the directory
} module.exports.getLatestJsonFile = getLatestJsonFile;

/**
 * Adds a "/" if the folder name doesn't include one at the end
 * @param {*} folder The folder name
 * @returns the corrected folder name
 */
function correctFolder(folder) {
    //trim the folder

    if (folder == undefined) {
        folder = "";
    }

    if (folder != "") {
        folder = folder.trim();
        if (folder[folder.length - 1] != "/") {
            folder = folder + "/";
        }
    }
    return folder;
}


/**
 * Moves the contents of a folder to another folder, safely without touching unassocaited files.
 * @param {*} srcDir The source directory.
 * @param {*} destDir The destination directory.
 */
async function move(srcDir, destDir) {
    try {
        const entries = fs.readdirSync(srcDir, { withFileTypes: true });

        for (const entry of entries) {
            const srcPath = path.join(srcDir, entry.name);
            const destPath = path.join(destDir, entry.name);

            if (entry.isDirectory()) {
                // Ensure the destination directory exists
                if (!fs.existsSync(destPath)) {
                    fs.mkdirSync(destPath, { recursive: true });
                }
                // Recurse into the directory
                await fm.moveFiles(srcPath, destPath);
            } else if (entry.isFile()) {
                // Check if the file already exists in the destination
                if (!fs.existsSync(destPath)) {
                    // If the file does not exist, move it
                    fs.copyFileSync(srcPath, destPath);
                    console.log(`File copied: ${destPath}`);
                } else {
                    console.log(`File already exists: ${destPath}`);
                }
            }
        }
    } catch (error) {
        console.error(`Error moving files: ${error.message}`);
    }
} module.exports.move = move;

function files(_path, {recursive = false, ext = null}) {

    //does file exist?
    if (!(exists(_path))) {
        throw new Error(`File does not exist: ${_path}`);
    }

    //get all the files and return their info, if recursive, go into the folders, if ext is a string or an arry of strings only return those files with that extension
    var files = [];
    var stats = fs.statSync(_path);

    if (stats.isDirectory()) {
        //get the files
        var entries = fs.readdirSync(_path, { withFileTypes: true });
        for (var i = 0; i < entries.length; i++) {
            var entry = entries[i];
            var entryPath = _path + "/" + entry.name;
            var entryStats = fs.statSync(entryPath);
            var ext = path.extname(entry.name);

            var entry = {
                ...entryStats,
                name: entry.name,
                uri: entryPath,
                ext: ext,
            }

            // console.log("Entry would be", entry)

            if (entryStats.isDirectory()) {
                if (recursive) {
                    files = files.concat(files(entryPath, {recursive: true, ext: ext}));
                }
            } else {
                if (ext == null) {
                    files.push({entry});
                } else {
                    if (Array.isArray(ext)) {
                        if (ext.indexOf(path.extname(entry.name)) != -1) {
                            files.push(entry);
                        }
                    } else {
                        if (path.extname(entry.name) == ext) {
                            files.push(entry);
                        }
                    }
                }
            }
        }
    }

    return files;

} module.exports.files = files;

function createSymbolicLink(src, dest) {
    src = resolve(src);
    dest = resolve(dest);

    //does the source file exist?
    if (!fs.existsSync(src)) {
        throw new Error(`Source file does not exist: ${src}`);
    }

    //does the destination file exist?
    if (fs.existsSync(dest)) {
        throw new Error(`Destination file already exists: ${dest}`);
    }

    fs.symlinkSync(src, dest);

} module.exports.createSymbolicLink = createSymbolicLink;