/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.select.dropdown",
  title: "Searchable List Box",
  description: "A searchable list box.",
  control: true,
  prevalidate: true,
  supportServer: false,
  fields: {
    "name": {
      title: "Name",
      type: "string",
      description: "The name of the control.",
      required: true
    },
    "title": {
      title: "Title",
      type: "string",
      description: "The title of the control.",
      default: ""
    },
    "icon": {
      title: "Icon",
        type: "string",
        description: "The icon of the control.",
        required: false
      },
    "data": {
      title: "Data",
      type: "array",
      description: "The data items to be listed in the search box.",
      required: true
    },
    "mustmatch": {
      title: "Must Match",
      type: "boolean",
      description: "Does the value have to match the data exactly?",
      required: false
    }, 
    "listClass": {
      title: "List Class",
      type: "class",
      description: "The class of the list group shown when the input is focused (list of items)."
    }, 
    "itemClass": {
      title: "Item Class",
      type: "class",
      description: "The class of each item in the list group."
    },
    "showList": {
      title: "Show List",
      type: "boolean",
      description: "Whether or not to always show the list.",
      default: false,
      addtouae: true
    }
  },
  passthrough: {
    except: ["inner", "type", "id", "inputclass", "inputstyle", "data", "includeOther"]
  }
});
// module.exports.define = define;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");

// var define = {
//   namespace: "ua.select.dropdown",
//   title: "Searchable List Box",
//   description: "A searchable list box.",
//   control: true,
//   prevalidate: true,
//   supportServer: false,
//   fields: {
//     "name": {
//       type: "string",
//       description: "The name of the control.",
//       required: true
//     },
//     "title": {
//       type: "string",
//       description: "The title of the control.",
//       default: ""
//     },
//     "icon": {
//         type: "string",
//         description: "The icon of the control.",
//         required: false
//       },
//     "data": {
//       type: "array",
//       description: "The data items to be listed in the search box.",
//       required: true
//     },
//     "mustmatch": {
//       type: "boolean",
//       description: "Does the value have to match the data exactly?",
//       required: false
//     }, 
//     "listClass": {
//       type: "class",
//       description: "The class of the list group shown when the input is focused (list of items)."
//     }, 
//     "itemClass": {
//       type: "class",
//       description: "The class of each item in the list group."
//     },
//     "showList": {
//       type: "boolean",
//       description: "Whether or not to always show the list.",
//       default: false,
//       addtouae: true
//     }
//   },
//   passthrough: {
//     except: ["inner", "type", "id", "inputclass", "inputstyle", "data", "includeOther"]
//   }
// }; module.exports.define = define;

async function render(options) {

  console.log("Show list?", options.showList);

  var randomName = uai.generateRandomID(12);

  var inputElement = {
      "n": "input",
      "id": randomName,
      "class": "form-control dropdown-toggle",
      "title": options.title,
      "aria-describedby": `${randomName}-list`,
      "mustmatch": options.mustmatch,
      "showList": options.showList
  };

  inputElement = await jsonRender.pass({...options}, {...inputElement}, ["name", "class", "icon", "style", "data", 
    "mustmatch", "listClass", "itemClass", "showList"  
  ]);

  var listItems = options.data.map(item => ({
      "n": "li",
      "c": "list-group-item list-group-item-action",
      "i": item,
      "data-value": item
  }));

  //add class if specified
  if ("itemClass" in options) {
      listItems.forEach(item => {
          item.c += ` ${options.itemClass}`;
      });
  }

  if (options.includeOther) {
      listItems.unshift({ "n": "li", "c": "list-group-item", "i": "Other", "data-value": "Other" });
  }

  var inputGroupElement = {
      "n": "div",
      "c": "input-group",
      "i": [inputElement]
  };

  if (options.icon) {
      inputGroupElement.i.unshift({
          "n": "div",
          "c": "input-group-text text-color",
          "i": [{ "n": "i", "class": options.icon }]
      });
  }

  var listGroupElement = {
      "n": "ul",
      "id": `${randomName}-list`,
      "c": "list-group",
      "i": listItems
  };

  if (options.listClass) {
      listGroupElement.c += ` ${options.listClass}`;
  }

  var element = {
      "n": "div",
      "name": options.name,
      "c": "form-group pb-3",
      "i": [
          {
              "n": "label",
              "for": randomName,
              "c": "pb-2 fs-4",
              "i": options.title
          },
          inputGroupElement,
          listGroupElement
      ]
  };

  var domElement = await jsonRender.render(element);

  var input = domElement.querySelector("input");
  var list = domElement.querySelector(`#${randomName}-list`);

  input.addEventListener("input", function() {
      var searchValue = this.value.toLowerCase();
      Array.from(list.children).forEach(function(item) {
          var text = item.textContent.toLowerCase();
          item.style.display = text.includes(searchValue) ? "" : "none";
      });
  });

  // input.addEventListener("change", focus);
  // input.addEventListener("input", function() {
  //   console.log("Input", { value: this.value });
  // });
  input.addEventListener("focus", focus);
  input.addEventListener("focusout", focus);
  // list.addEventListener("mousedown", focus);
  // input.addEventListener("blur", focus);
 

  document.addEventListener("click", (event)  => {

    if (options.showList) {
      list.style.display = "";
      return;
    }

      // Close the list if clicked outside
      if (event.target !== input) {
          list.style.display = "none";
      }

  });

  Array.from(list.children).forEach(function(item) {
      item.addEventListener("mousedown", function() {
          input.value = this.getAttribute('data-value');
          list.style.display = "none";

          //fire change event
          var event = new Event("change");
          input.dispatchEvent(event);

          var event2 = new Event("focusout");
          input.dispatchEvent(event2);
  
          // focus({ target: input });
      });
  });

  if (!(options.showList)) {
    console.log("Hiding List", options);
    list.style.display = "none";
  } else {
    list.style.display = "";
  }

  return domElement;
}
module.exports.render = render;



  

// async function render(options) {
//     var randomName = uai.generateRandomID(12);
  
//     var inputElement = {
//       "n": "input",
//       "id": randomName,
//       "class": "form-control",
//       "title": options.title,
//       "list": `${randomName}-datalist`,
//       "mustmatch": options.mustmatch
//     };

//     inputElement = await jsonRender.pass({...options}, {...inputElement}, ["name", "class", "icon", "style", "data", "mustmatch"]);
  
//     var dataListItems = [];
  
//     if (options.includeOther) {
//       dataListItems.push({ "n": "option", "value": "Other" });  // 'Other' option is always first
//     }
  
//     dataListItems = dataListItems.concat(options.data.map(item => ({
//       "n": "option",
//       "value": item
//     })));
  
//     var element = {
//       "n": "div",
//       "name": options.name,
//       "c": "form-group pb-3",
//       "i": [
//         {
//           "n": "label",
//           "for": randomName,
//           "c": "pb-2 fs-4",
//           "i": options.title
//         },
//         inputElement,
//         {
//           "n": "datalist",
//           "id": `${randomName}-datalist`,
//           "i": dataListItems
//         }
//       ]
//     };

  
//     var domElement = await jsonRender.render(element);
  
//     domElement.querySelector("input").addEventListener("input", function() {
//       // Case-insensitive search and handling 'Other' input
//       let searchValue = this.value.toLowerCase().trim();
//       if (searchValue === "other") {
//         this.setAttribute('data-other', 'true');
//       } else {
//         this.removeAttribute('data-other');
//       }
//     });

//     domElement.querySelector("input").addEventListener("focus", focus);
//     domElement.querySelector("input").addEventListener("focusout", focus);
  
//     return domElement;
// } module.exports.render = render;
  

async function focus(event) {
    // console.log("focus", event);
  
  
    //get the parent uae element
    var parent = this.closest("uae");
    var input = parent.querySelector("input");

    //show the list
    var list = parent.querySelector("ul.list-group");
    list.style.display = "";
  
    input.classList.remove("is-invalid");
    input.classList.remove("is-valid");

    parent.classList.remove("is-invalid");
    parent.classList.remove("is-valid");
  
      //if the input is not empty
      if (!(input.value.trim() == "")) {
        
        // console.log("Validate",  {
        //   value: input.value,
        // });

        var repo = await validate(parent.getAttribute("name"));


        // console.log("Validate", {
        //     name: this.name,
        //     repo: repo
        // });

        //check if it's valid
        if (repo.success) {
          input.classList.add("is-valid");
  
          if (parent) {
            parent.classList.add("is-valid");
          }
  
        } else {
          input.classList.add("is-invalid");
  
          if (parent) {
            parent.classList.add("is-invalid");
          }
  
        }
  
      }
  }


async function validate(name) {
    //get the uam of the name
    var control = document.querySelector(`uae[name="${name}"]`);
    var rtn = await save(name, control, { success: true, errors: [] });
    return rtn;
}

async function save(name, control, repo) {
  var input = control.querySelector("input");
  var value = input.value.trim();

  input.value = value; // Update the input's value with the trimmed version

  // console.log("Input for save", input);

  if (!(input.checkValidity())) {
      repo.success = false;
      repo.errors.push({
          type: "validation",
          message: input.validationMessage
      });
  }

  //if the value is not empty
  if ((!(value === "")) && (!(value === undefined)) && (!(value === null))) {

    if (input.getAttribute("mustmatch") === "true") {
        var list = control.querySelector("ul.list-group");
        var items = list.querySelectorAll("li");
        var found = Array.from(items).some(item => item.textContent.trim().toLowerCase() === value.toLowerCase());

        if (!found) {
            repo.success = false;
            repo.errors.push({
                type: "mustmatch",
                message: "The value selected must match one of the options in the list."
            });
        }
    }
  }

  repo.data = value; // Store the input value in the repository

  // console.log("Save Result", {
  //     name: name,
  //     control: control,
  //     repo: repo
  // });

  return repo;
} module.exports.save = save;


async function load(name, control, data) {

  // console.log("Loading");

  var value = data;
  var input = control.querySelector("input");

  // console.log("Load", {
  //   name: name,
  //   control: control,
  //   data: data
  // });

  //if tha value is an object
  if (typeof data === "object") { 
    if ("data" in data) {
      //assume the data is a new data set for the dropdown
      var list = control.querySelector("ul.list-group");
      var listitemClass = list.children[0].className;

      //clear the list
      list.innerHTML = "";

      //add the new data
      data.data.forEach(item => {
        var li = document.createElement("li");
        li.className = listitemClass;
        li.textContent = item;
        li.setAttribute("data-value", item);
        list.appendChild(li);
      });

      //add the click event to the new items
      Array.from(list.children).forEach(function(item) {
        item.addEventListener("mousedown", function() {
          input.value = this.getAttribute('data-value');
          list.style.display = "none";

          //fire change event
          var event = new Event("change");
          input.dispatchEvent(event);

          var event2 = new Event("focusout");
          input.dispatchEvent(event2);
            
        });
      });

    }

    if ("value" in data) {
      value = data.value;
    } else {
      //don't update because the data is an object but doesn't have a value property
      return;
    }

  }



  input.value = data;
} module.exports.load = load;

 