/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */

var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.element.error",
  title: "UAE Error Message",
  description: "An error message from a ua element.",
  // editable: false,
  fields: {
    "error": {
      title: "Error",
      type: "object",
      description: "The error or throwable provided by the ua element.",
      required: true
    },
    "_namespace": {
      title: "Namespace",
      type: "string", 
      description: "The orginating namespace of the error",
      required: true
    },
    "args": {
      title: "Args",
      type: "*",
      description: "Any arguments to share with the devleper."
    },
    "showAccordian": {
      title: "Show Accordian",
      type: "boolean",
      description: "Show the stack trace in an accordian.",
      default: false
    }
  },
  errors: {
    "incompatableThrowableMessage": {
      title: "Incompatible Throwable",
      description: "The object thrown has no stack or stacktrace."
    }
  }
});

// var namespace = module.exports.namespace;
var f = require("../scripts/f.js");
var uae = require("../interface/element.js");

// var define = {
//     namespace: "ua.element.error",
//     title: "UAE Error Message",
//     description: "An error message from a ua element.",
//     editable: false,
//     fields: {
//       "error": {
//         type: "object",
//         description: "The error or throwable provided by the ua element.",
//         required: true
//       },
//       "_namespace": {
//         type: "string", 
//         description: "The orginating namespace of the error",
//         required: true
//       },
//       "args": {
//         type: "*",
//         description: "Any arguments to share with the devleper."
//       },
//       "showAccordian": {
//         type: "boolean",
//         description: "Show the stack trace in an accordian.",
//         default: false
//       }
//     }
//   }; module.exports.define = define;

  

  // var incompatableThrowableMessage = "Incompatible Throwable: The object thrown has no stack or stacktrace.";

  /**
   * Renders the error message.
   * @param {*} options The jsonObject to render.
   * @returns The final renderalbe object, dom or ua/json.
   */
  exports.render = async function (options) {

    try {

      var myNamespace = "unknown";
      if (options._namespace) {
        myNamespace = options._namespace;
      }
      
    console.error(`The element: ${myNamespace} encountered an error. ${options.error}`, {
      // stack: options.error.stack,
      error: f.GenerateSafeError(options.error),
      options: options
  });

  var newError = new Error();

  // console.log("Error issues", {
  //   typeOf: typeof options.error,
  //   error: options.error,
  //   stacktrace: newError.stacktrace
  // });

  if (!options.error) {
    options.error = new Error("An unknown error occurred.");
    return {
      "n": "p",
      "c": "border-danger text-danger background-dark",
      "i": `Could not encode error. No error was found.`
    }
  };

  // //if error is a error
  // if (options.error instanceof Error) {
  //     // if ("namespace" in error) {
  //     //     error = `${error}: ${error.toString()}`;
  //     // } else {
  //       options.error  = options.error.toString();
  //     // } 
  // } else {
      
  //     //I could be anything...
  //     options.error  = options.error.toString();

  // }

var accordianId = uae.GenerateRandomID();
var accordianItemId = uae.GenerateRandomID();
var error_namespace = "$unknown";

//is the error a string? Convert it to an error object.
if (typeof options.error === "string") {
  options.error = new Error(options.error);
  options.stack = f.GenerateStacktrace();
}

if (!("stack" in options.error)) {
  options.error.stack = "No stack trace available.";
}

var myErr = options.error.toString();

if ("title" in options.error) {
  myErr = options.error.title;
}

if ("description" in options.error) {
  myErr = `${myErr}: ${options.error.description}`;
}

if ("namespace" in options.error) {
  myErr = `${myErr} (${options.error.namespace})`;
  error_namespace = options.error.namespace;
}

// if ("title" in options.error) {
//   myErr = options.error.title;
//   if ("description" in options.error) {
//     myErr = `${myErr}: ${options.error.description}`;
//   }

//   if ("namespace" in options.error) {
//     myErr = `${myErr} (${options.error.namespace})`;
//   }

// }

var displayStack = _.errors.incompatableThrowableMessage.safe().stack;

if ("stack" in options.error) {
  if (typeof options.error.stack == "string") {
    displayStack = options.error.stack;
  } else if ("stacktrace" in options.error) {
    if (typeof options.error.stacktrace == "string") {
      //Using a UA Error Object (thowable)
      displayStack = options.error.stacktrace;
      myErr = options.error.message;
    }
  }
}  

var accordian = {
  "n": "div",
  "c": "accordion",
  "id": accordianId,
  "i": {
    "n": "div",
    "c": "accordion-item",
    "i": [
      {
        "n": "h2",
        "c": "accordion-header",
        "i": {
          "n": "button",
          "c": "accordion-button collapsed",
          "type": "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": `#${accordianItemId}`,
          // "aria-expanded": "true",
          "aria-controls": `${accordianItemId}`,
          "i": "For more details, click here."
        } 
      }, {
        "n": "div",
        "c": "accordion-collapse collapse", 
        "data-bs-parent": `#${accordianId}`,
        "id": `${accordianItemId}`,
        "i": {
          "n": "div",
          "c": "accordion-body",
          "i": {
              "n": "pre",
              "i": `${myErr}

Stack Trace
${displayStack.replace(/\n/g, `
`)}

Arguments
${JSON.stringify(options.args, null, 2)}

Namespace of Element: ${options._namespace}
Namespace of Error: ${error_namespace}
`
// Error Options
// ${JSON.stringify(options, null, 2)}

            }
        }
      } 
    ]
  }
}

  // var myErr = options.error.toString();

  //if Error: is in the beginning, remove the first 6 characters.
  if (myErr.indexOf("Error:") == 0) {  
    myErr = myErr.substring(6);
  }

  myErr = myErr.trim();

  //if the error is blank, or null, or undefined, or false set it to a default error.
  if (myErr == "" || myErr == "null" || myErr == "undefined" || myErr == "false") {
    myErr = "An unknown error occurred. (string conversion failed)";
  }

  //if myErr doesn't end with a period, exclamation or question mark, add a period.
  if (myErr[myErr.length - 1] != "." && myErr[myErr.length - 1] != "!" && myErr[myErr.length - 1] != "?") {
    myErr = myErr + ".";
  }

  var error = {
    n: "ua.alert",
    icon: "!default",
    alertclass: "alert-danger",
    title: `${options._namespace}`, 
    "not-an-item": true,
    i: [
      {
        "n": "p",
        "c": "p-0 m-0",
        "i": [
          { 
            "n": "strong",
            "i": `${myErr}`
          }, 
          ` The element ${options._namespace} had a problem rendering. Universe App Tools renders this message to help the website owner diagnose the problem. Check the main console log for more information.`]
      }
    ]
  }

  if (options.showAccordian) {
    error.i.push(accordian);
  }

  return error;

  // var rtn = await alert.render({
  //     icon: "!default",
  //     alertclass: "alert-danger",
  //     title: "Universe App Tools",
  //     "not-an-item": true,
  //     inner: [error, {
  //         n: "p",
  //         i: ["The developer is using The Universe App Tools to provide a better user experience. ",
  //         {
  //             n: "a",
  //             i: "Learn more about The Universe App Tools.",
  //             href: "https://egtuniverse.com/"
  //         }
  //     ]
  //     }]
  // });

  // console.log("Return", rtn.innerHTML);
    } catch (error) {

      error = f.GenerateSafeError(error);

        console.log("Error rendering error message.", {
          options, error
        });
        return {
          "n": "p",
          "c": "border-danger text-danger background-dark",
          "i": `Could not encode error. ${error.toString()}`
        };
    }
    


  }