/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(exports, {
  namespace: "ua.magic.glow",
  title: "Magic Glow Animation",
  description: "Applies a magic glow around an element.",
  // control: true,
  // prevalidate: true,
  // supportServer: false,
  fields: {
    "inner": {
      title: "Inner Content",
      type: "inner",
      description: "The inner content of the glow.",
      required: true
    },
    "class": {
      title: "Glow Class",
      type: "string",
      description: "The class of the glow.",
      default: "p-3 bg-blur-75-black"
    }
  },
  passthrough: {
    except: ["class"]
  }
});

// var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");

exports.render = async function (options) {

//   <div class="magic-glow">
// <div class="magic-glow-holster">
//   Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card  Magic Card
// </div>
// </div>

 return {
    "n": "div",
    "c": "magic-glow",
    "i": [{
      "n": "div",
      "c": "magic-glow-holster " + options.class,
      "i": options.inner
    }]
  }
  
}