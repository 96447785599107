/*!
 * Protected by The Universe Terms of Service and Privacy Policy.
 * Subject to the Terms of Service and Privacy Policy.
 * 
 * Justin K Kazmierczak
 * © 2023. The Universe & Justin K Kazmierczak. All rights reserved.
 * 
 * Copy and distribution of this code is strictly prohibited.
 */

var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.a.conic",
  title: "Animation: Conic Border Animation",
  description: "A conic border animation that is rendered around an element.",
  fields: {
    inner: {
      title: "Inner",
      type: "inner",
      description: "The inner content to render the conic animation around.",
      required: true
    }
  }
});


var jsonRender = require("../interface/jsonRender.js");

// var namespace = "ua.a.conic";
// exports.namespace = namespace;


// exports.define = {
  // namespace: namespace,
//   fields: [],
//   required: "inner"
// } 

/**
 * Report if the alert is rendered. (uae)
 * @param  {*} element [description]
 * @return {*} If the element has already been rendered.
 */
exports.isRendered = function (element) {
  return uae.isRendered(element);
}

/**
 * Called by the interface to render the animation
 * @param {*} json The json to render the type on animation on.
 */
exports.render = async function (json) {


    return await jsonRender.render({
        "n": "div",
        "c": "conic",
        "i": json.inner
    }, {
        PassthroughOptions: json
    })

};
