/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Payments by The Universe
 * Universe App Modules
 * 
 * Justin K Kazmierczak
 * 
 * Build's a module's individual components based on it's definition.
 * 
 **/

var namespace = "uam.module";

var errModule = require("./errors.js");
var GenerateStacktrace = require("./functions/generateStacktrace.js").function;
var config = false;
var registryFactory = require("./registry.js");
var validate = require("./functions/validate.js").function;
var generateSafeError = require("./functions/generateSafeError.js").function;
var supportedTypes = require("./functions/validate.js").supportedTypes;
var fm = false;

// var errors = [];
// var fields = registryFactory("uam.fields");

try {
    config = require("./config.js");
    // fm = require("./filemanager.js"); //Won't work in the browser.
    // fm.SaveLogJSONbyNamespaceItem()
} catch (error) {
    // console.error("The module can not import config due to a circuluar refrence.", error);
    //config is disabled for client side modules.
}
// var config = require("./config.js");

try {
    fm = require("./filemanager.js"); //Won't work in the browser.
// fm.SaveLogJSONbyNamespaceItem()
} catch (error) {
    // console.error("Access to file manager is denied for this module.", error);
//config is disabled for client side modules.
}

var storage = false;

/**
 * Builds a module based on it's definition.
 * @param {*} exp The module.exports object.
 * @param {*} define The definition object.
 * @param {*} define.namespace The namespace of the module.
 * @param {*} define.title The title of the module.
 * @param {*} define.description The description of the module.
 * @param {*} define.errors The errors of the module.
 * @param {*} define.config The configuration of the module.
 * @param {*} define.config.title The title of the configuration.
 * @param {*} define.config.description The description of the configuration.
 * @param {*} define.config.default The default value of the configuration.
 * @param {*} define.registry The registry of the module.
 * @param {*} define.fields The fields of the module.
 * @param {*} define.fields.namespace The namespace field, automatically added based ont he object property name and the namespace.
 * @param {*} define.fields.title The title field.
 * @param {*} define.fields.description The description field.
 * @param {*} define.fields.type The type field.
 * @param {*} define.fields.list The list field.
 * @param {*} define.fields.default The default field.
 * @param {*} define.fields.required The required field.
 * @param {*} define.registry An object or Array of objects that define the registry.
 * @param {*} define.registry.namespace The namespace of the registry. Not required if it's just an object. Namespace will automatically be {namespace}.registry.
 * @param {*} define.registry.autosave If the registry should autosave to the .log file.
 * @param {*} define.storage An object or Array of objects that define the storage.
 * @param {*} define.storage.namespace The namespace of the storage. Not required if it's just an object. Namespace will automatically be {namespace}.storage.
 * @param {*} define.storage.title The title of the storage.
 * @param {*} define.storage.description The description of the storage.
 * @returns The module.exports object (can also be used as a _ object).
 */
function Create(exp, define) {

    var errs = [];

    // try {
            
        if (!("namespace" in define)) {
            _.errors.noNamespace.throw(define);
        }

        // SaveDefin(define);

        exp.namespace = define.namespace;
        exp.define = define;

        if (!("passthrough" in define)) {
            exp.define.passthrough = true;
        }

        if ("errors" in define) {

            // try {
                exp.errors = errModule.createFromDefine(define);            
            // } catch (error) {
                // errs.push(error);
            // }

        }

        // //get property count of exp.errors
        // var errorCount = Object.keys(exp.errors).length;

        
        if ("config" in define) {

            if (config) {

                //add namespace to every property in the config.

                try {
                    config.useDefine(define);
                    exp.config = config;
                } catch (error) {
                    errs.push(error);
                }

            } else {
                console.warn("Access to config is denied for this module.")
            }

            // config.useDefine(define);
        }

        if ("registry" in define) {

            //is it an array?
            if (!(Array.isArray(define.registry))) {
                define.registry.namespace = `${define.namespace}.registry`;
                define.registry = [define.registry];
            }

            //for each registry item
            for (var i = 0; i < define.registry.length; i++) {

                var regDefine = define.registry[i];

                //is regDefine a boolean of true
                if (regDefine === true) {
                    regDefine = {
                        namespace: `${define.namespace}.registry`,
                        autosave: true
                    }
                }

                try {

                    if (define.registry[i] == 1) {

                        exp.registry = createRegistry(regDefine.namespace);

                    } else {

                        exp.registry[regDefine.namespace] = createRegistry(regDefine.namespace);
   
                    }

                } catch (error) {
                    error.message = `On Registry: ${regDefine.namespace}; ${error.message}`;
                    errs.push(error);
                }
    
                if ("autosave" in regDefine) {
                    if (regDefine.autosave === true) {
                        if (fm) {
                            exp.registry.on("add", AutoSaveRegistry);
                        } else {
                            console.warn("Access to file manager is denied for this module.");
                        }
                    }
                }

            }

        }

        //work with storage
        if ("storage" in define) {
            if (!(storage)) {
                storage = require("./ObjectStorage.js");
            }

            //is it an array?
            if (!(Array.isArray(define.storage))) {
                define.storage.namespace = `${define.namespace}.storage`;
                define.storage = [define.storage];
            }

            //for each storage item
            for (var i = 0; i < define.storage.length; i++) {

                var stoDefine = define.storage[i];

                //check if the namespace of the module is the begining of stoDefine.namespace
                if (stoDefine.namespace.indexOf(define.namespace + ".") !== 0) {
                    stoDefine.namespace = `${define.namespace}.${stoDefine.namespace}`;
                }

                try {
                    exp.storage = storage.create(stoDefine.namespace, stoDefine.title, stoDefine.description);
                } catch (error) {
                    error.message = `On Storage: ${stoDefine.namespace}; ${error.message}`;
                    errs.push(error);
                }
    
            }

        }

        if ("fields" in define) {

            //validate the fields
            for (var field in define.fields) {
                try {
                    ValidateFieldDefintion(define.fields[field]);
                } catch (error) {
                    error.message = `On Field: ${define.namespace}.${field}; ${error.message}`;
                    errs.push(error);
                }

                var nField = {...define.fields[field]};
                nField.namespace = exp.namespace + "." + field;
                // fields.add(nField);

            }

            exp.fields = define.fields;


            /**
             * Validates an object to the fields.
             * @param {*} obj The object to validate.
             * @throws error if the object is not valid.
             */
            exp.validate = function(obj) {
                ValidateObjectToAllFields(obj, exp.fields);
            }

            /**
             * Validates a property to a field.
             * @param {*} prop The property to validate.
             * @param {*} value The value of the property.
             */
            exp.validateProp = function(prop, value) {
                ValidatePropertyToField(exp.fields, prop, value);
            }

        }

    // } catch (error) {
        // errs.push(error);   
    // }

    SaveDefin(define, errs);

    // if (errs.length > 0) {
    //     // exp.errors = [exp.errors, errs];
    //     // errors = [...errors, errs];
    //     //get all the error messages
    //     var messages = errs.map(function(err) {
    //         return err.message;
    //     });

    //     if (fm) {
    //         var err = {
    //             errors: errs,
    //             define: define,
    //             namespace: define.namespace
    //         }
    //         fm.SaveLogJSONbyNamespaceItem("build.errors", err);
    //     }

    //     console.warn("The module failed to work it's definition. " + messages.join(" "));
    //     //throw the error
    //     // throw new Error("The module failed to work it's definition. " + messages.join(" "));

    // }
    
    return exp;
} module.exports.create = Create;

async function AutoSaveRegistry(registry, item) {
    if (fm) {
        try {
            fm.SaveLogJSONbyNamespaceItem(registry.namespace, item);
        } catch (error) {
            console.warn(`Failed to save ${registry.namespace} registry item.`, error);
        }
    }
}

/**
 * Saves the definition to the file manager.
 * @param {*} define The definition object.
 * @param {*} errs The errors that occured.
 */
function SaveDefin(define, errs = false) {
    if (fm) {

        var nDefine = {...define};
        nDefine.compile = {
            errors: false
        }

        if (errs) {

            if (errs.length > 0) {

                //for each error add it to the compile errors after converting it with generateSafeError
                nDefine.compile.errors = errs.map(function(err) {
                    return generateSafeError(err);
                });
    
                var messages = nDefine.compile.errors.map(function(err) {
                    return err.message;
                });

                console.warn(`${define.namespace} failed to compile. Reasons: ${messages.join(" ")}`);
                fm.SaveLogJSONbyNamespaceItem("build.errors", nDefine);

                // nDefine.compile.errors = errs;
            }
        }

        nDefine.from = GenerateStacktrace(0);

        try {
            fm.SaveLogJSON(nDefine.namespace, nDefine, namespace);
        } catch (error) {
            
        }

        // if (errs) {
        //     if (errs.length > 0) {

        //         //get all messages
        //         var messages = errs.map(function(err) {
        //             return err.message;
        //         });

        //         console.warn(`${define.namespace} failed to compile. Reasons: ${messages.join(" ")}`);
        //         fm.SaveLogJSONbyNamespaceItem("build.errors", nDefine);

        //     }
        // }


    }
}

/**
 * Creates a registry.
 * @param {*} ns The fully qualified namespace of the registry.
 * @returns The registry object.
 */
function createRegistry(ns) {
    return registryFactory(ns);
}

/**
 * Validates a field definition.
 * @param {*} field The field definition.
 * @throws error if the field is not valid.
 * @returns true if the field is valid.
 */ 
function ValidateFieldDefintion(field) {

    //must have a type, description, and title
    //if it has a required field, it must be a boolean
    //if it has a default field, it must validate
    //if it has a list field, it must be an array and each item must validate

    //is the field an object?
    if (typeof field !== "object") {
        _.errors.fieldNotAnObject.throw({
            field
        });
    }

    if (!("type" in field)) {
        _.errors.fieldRequiredType.throw({
            field
        });
    }

    if (!("description" in field)) {
        _.errors.fieldRequiredDescription.throw({
            field
        });
    }

    if (!("title" in field)) {
        _.errors.fieldRequiredTitle.throw({
            field
        });
    }

    if ("required" in field) {
        if (typeof field.required !== "boolean") {
            _.errors.fieldRequiredRequired.throw({
                field
            });
        }
    }

    if ("default" in field) {

        //is it a supported type?
        if (supportedTypes.includes(field.type)) {

            var needToTest = true;
            //if it's a string and a blank one it's valid
            if (field.type === "string") {
                if (field.default === "") {
                    needToTest = false;
                }
            }
        
            //is the default value valid?    
            if ((needToTest) && (!validate(field.default, field.type))) {
                _.errors.fieldDefaultNotValid.throw({
                    field
                });
            }

        }
    }

    if ("list" in field) {

        //if it's not an array or an obhject throw an error

        if (typeof field.list !== "object") {
            _.errors.fieldListIsNotAnObject.throw({
                field
            });
        }

        // if (!Array.isArray(field.list)) {
        //     _.errors.fieldListNotArray.throw({
        //         field
        //     });
        // }


        //if it's an array test the array or if it's an object test must property (it's an array) and the suggest (it's an object)

        // if (Array.isArray(field.list)) {
        //     for (var i = 0; i < field.list.length; i++) {
        //         if (!validate(field.list[i], field.type)) {
        //             _.errors.fieldListAsArrayNotValid.throw({
        //                 field
        //             });
        //         }
        //     }
        // }

        // if (typeof field.list === "object") {

            if ("must" in field.list) {
                if (Array.isArray(field.list.must)) {
                    for (var i = 0; i < field.list.must.length; i++) {

                        //if it's a string and it's empty it's valid
                        if (field.type === "string") {
                            if (field.list.must[i] === "") {
                                continue;
                            }
                        }

                        if (!validate(field.list.must[i], field.type)) {
                            _.errors.fieldListMustNotValid.throw({
                                field
                            });
                        }

                    }
                }
            }

            if ("suggest" in field.list) {
                if (Array.isArray(field.list.suggest)) {
                    for (var i = 0; i < field.list.suggest.length; i++) {

                        //if it's a string and it's empty it's valid
                        if (field.type === "string") {
                            if (field.list.suggest[i] === "") {
                                continue;
                            }
                        }

                        if (!validate(field.list.suggest[i], field.type)) {
                            _.errors.fieldListSuggestNotValid.throw({
                                field
                            });
                        }
                    }
                }
            }
        // }
    }
}



/**
 * Validates an object to a schema.
 * @param {*} obj The object to validate.
 * @param {*} sch The schema to validate against.
 * @returns true if the object is valid.
 * @throws error if the object is not valid.
 */
function ValidateObjectToAllFields(obj, sch) {
    
    //iterate through each property in the object
    for (var prop in obj) {
        //validate the property
        return ValidatePropertyToField(sch, prop, obj[prop]);
    }

}

/**
 * Validates a property to a field.
 * @param {*} sch The definition schema to validate against.
 * @param {*} prop The property to validate.
 * @param {*} value The value of the property.
 * @returns true if the property is valid.
 * @throws error if the property is not valid.
 */
function ValidatePropertyToField(sch, prop, value) {
    //iterate through the fields in schmea to find the field to validate

    for (var field in sch) {
        //if the field is the property
        if (field === prop) {
            //validate the property with the known field
            return ValidatePropertyWithKnownField(sch[field], value);
        }
    }

    //if the field is not found
    _.errors.fieldNotDefined.throw({
        prop, value
    });
}

/**
 * Validates a property with a known field.
 * Warning if the field type is not in the supported types, it will not validate the property - just the fact the property is required.
 * @param {*} field The field definition.
 * @param {*} value The value of the property.
 * @returns true if the property is valid.
 * @throws error if the property is not valid.
 */
function ValidatePropertyWithKnownField(field, value) {
    //validate the property with the known field

    if (field.required) {
        //is the value null or undefined
        if (value === null || value === undefined) {
            _.errors.fieldRequired.throw({
                field
            });
        }
    } else {
        //is the value null or undefined
        if (value === null || value === undefined) {
            if ("default" in field) {
                value = field.default;
            }
        }
    }

    //is it a supported type?
    if (supportedTypes.includes(field.type)) {
        //is the value valid?
     
        //now validate the property
        if (!(validate(prop, field.type))) {
            _.errors.fieldNotValid.throw({
                field,
                value
            })
        }

    }

    return true;
}

// /**
//  * Gets all the errors generated on creation.
//  * @returns All the errors.
//  */
// function GetOnCreateErrors() {
//     return errors;
// }

// function GetAllFields() {
//     return fields.GetObj();
// }

// /**
//  * Gets all the errors.
//  * @returns All the errors.
//  */
// function GetAllErrors() {
//     return errModule.registry.GetObj();
// }

var _ = Create(module.exports, {
    namespace: "uam",
    title: "Universe App Modules",
    description: "The Universe App Modules is a module that allows you to create modules for The Universe. It makes common things like providing error messages, and defining configurations easy and effieceint.",
    errors: {
        "noNamespace": {
            title: "No Namespace",
            description: "The provided definiton object has no namespace.."
        },
        "noFields": {
            title: "No Fields",
            description: "The provided definiton object has no fields."
        },
        "fieldRequired": {
            title: "Field Required",
            description: "The field is required."
        },
        "fieldNotValid": {
            title: "Invalid Field Value",
            description: "The field value is not valid to the specified type."
        },
        "fieldNotDefined": {
            title: "Field Not Defined",
            description: "The field is not defined."
        },
        "fieldRequiredType": {
            title: "Field Required Type",
            description: "The field required type was not found."
        },
        "fieldRequiredInvalid": {
            title: "Field Required Property Invalid",
            description: "The field required required was not a boolean."
        },
        "fieldDefaultNotValid": {
            title: "Field Default Not Valid",
            description: "The field default value is not valid."
        },
        "fieldListIsNotAnObject": {
            title: "Field List is not An Object",
            description: "The field list is not an object."
        },
        "fieldListMustNotValid": {
            title: "Field List Must Not Valid",
            description: "The field list must is not valid."
        },
        "fieldListSuggestNotValid": {
            title: "Field List Suggest Not Valid",
            description: "The field list suggest is not valid."
        },
        "fieldRequiredTitle": {
            title: "Field Required Title",
            description: "The field required title was not found."
        },
        "fieldRequiredDescription": {
            title: "Field Required Description",
            description: "The field required description was not found"
        },
        "fieldNotAnObject": {
            title: "Field Not An Object",
            description: "The field is not an object."
        },
    }
});

module.exports = Create;

module.exports.createRegistry = createRegistry;
module.exports.ValidateFieldDefintion = ValidateFieldDefintion;
module.exports.ValidateObjectToAllFields = ValidateObjectToAllFields;
module.exports.ValidatePropertyToField = ValidatePropertyToField;
module.exports.ValidatePropertyWithKnownField = ValidatePropertyWithKnownField;
// module.exports.GetAllErrors = GetAllErrors;
// module.exports.GetOnCreateErrors = GetOnCreateErrors;
// module.exports.GetAllFields = GetAllFields;

