/*!
 * Wrapper for lottie player.
 * 
 * This is the https://lottiefiles.com/ player specifically for dotlottie files.
 * 
 * Built by @jumpcutking
 */

//Impot lottie player
// var lottie = false;

try {
  // lottie = require("@dotlottie/player-component");
} catch (error) {
  // console.error("Error loading dotlottie player. It's not compatable with servier side rendering.", error);
}

var jsonRender = require("../interface/jsonRender.js");

var namespace = "lottie.dot";
exports.namespace = namespace;

var required = ["src"];

exports.define = {
  namespace: namespace,
  title: "Lottie Animation Player for dotlottie files.",
  description: "Connects to the dotlottie player to play animations.",
  // supportServer: false,
  fields: {
    src: {
      type: "string", 
      description: "The source of the lottie to play (json)."
    },
    autoplay: {
      type: "boolean",
      description: "Should the animation play automatically?",
      default: true
    }, controls: {
      type: "boolean",
      description: "Should the animation show controls?"
    }, loop: {
      type: "boolean",
      description: "Should the animation loop?",
      default: true
    }, speed: {
      type: "number",
      description: "The speed of the animation.",
      default: 1
    }, renderer: {
      type: "string",
      description: "The renderer to use. Defaults to svg.",
      default: "svg"
    }
  },
  // Included by default during page load
  // external: {
  //   name: "dotlottie-player",
  //   src: "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs",
  //   type: "module"
  // },
  required: required
} 
{/* <script src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs" type="module"></script> */}

// console.log(`Module ${namespace} loaded with an external script.`, exports.define);

exports.render = async function (options) {
   //var date = new Date($(this).attr("fromserver"));

  //  if ("UATisServer" in window && window.UATisServer) {

  //   //I'm not comapatible with server side rendering (yet)

  //   var onlyClientSideRender = {
  //     "n": "json",
  //     "type": "ua/interface",
  //     // "render": "no-server",
  //     "i": JSON.stringify(options, null, 0) + ""
  //   };

  //   // console.info("Only client side render", onlyClientSideRender);
  //   return await jsonRender.render(onlyClientSideRender);

  // };
  
   var lottie = {
      n: "dotlottie-player"
   }

   if (!("loop" in options)) {
      options.loop = "";
   }

    if (!("autoplay" in options)) {
      options.autoplay = "";
    }

    // console.lo

    // if (!("controls" in options)) {
    //   options.controls = "";
    // }


  return await jsonRender.render(lottie, {
    PassthroughOptions: options
  });

};
