/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "ua.address.list",
    title: "Show Addresses",
    description: "Shows all addresses.",
    editable: true,
    fields: {
        "addresses": {
            title: "Addresses",
            type: "array",
            description: "The address of the user.",
            required: true
        }
    }, 
    passthrough: {
        except: ["inner", "overlay", "overlayClass", "interval"]
    }
});

// var events = require("../../../uam/events.js");
// var sleep = require("../../../uam/functions/sleep.js").function;

// var addrTypes = {
//     "billing": "Billing",
//     "shipping": "Shipping",
//     "both": "Billing & Shipping"
// };

/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

    // console.log("Vehicle Render", options);

    //for each object in address array
    var addresses = options.addresses;
    var element = {
        "n": "div",
        "i": []
    };

    for (var i = 0; i < addresses.length; i++) {
        element.i.push({
            "n": "ua.address.show",
            ...addresses[i]
        });
    }

    return element;

}