/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "ua.random",
    title: "Random Object",
    description: "Showcases a diffrent object everytime the page is refreshed.",
    editable: true,
    fields: {
        inner: {
           type: "inner",
           title: "Items",
           description: "The random element to show.",
           required: true
        },
        amount: {
            type: "number",
            title: "Amount",
            description: "The amount of items to show.",
            default: 1
        },
        unique: {
            type: "boolean",
            title: "Unique",
            description: "Show only unique items.",
            default: true
        }
    }, 
    passthrough: {
        except: ["inner"]
    },
    supportServer: false
});

var events = require("../../../uam/events.js");
var uai = require("../interface/interface.js");
var uae = require("../interface/element.js");

/**
 * Renders one of the inner objects randomly.
 * @param {*} options The jsonObject to render (should use fields)
 */
exports.render = async function (options) {

    var rtn = [];

    //return only one random item, if random item is an object - or only one item - return it
    if (Array.isArray(options.inner)) {

        var itemsClone = options.inner;
        if (options.unique) {
            itemsClone = [...options.inner];
        }

        var sentOunt = 0;

        while (sentOunt < options.amount) {
            var randomIndex = Math.floor(Math.random() * itemsClone.length);
            rtn.push(itemsClone[randomIndex]);

            if (options.unique) {
                itemsClone.splice(randomIndex, 1);
            }

            sentOunt++;
        }
        // var randomIndex = Math.floor(Math.random() * itemsClone.length);

    } else {

        rtn = options.inner;
        
    }

    return rtn;

}