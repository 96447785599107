/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */

var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.getdirections",
  title: "Get Directions",
  description: "A button that opens a dialog showing directions through supported map apps.",
  fields: {
    "address": {
      title: "Address",
      type: "string", 
      description: "The address to give directions to.",
      required: true,
      addtouae: true, // this will add it to the uae element for the purposes of json rendering.
    }, 
    "inner": {
      title: "Inner",
      type: "inner", 
      description: "The contents od the button to open the directions dialog."
    },
    "moduleClass": {
      title: "Module Class",
      type: "class",
      description: "The class to add to the dialog box."
    }
  },
  supportServer: false
});
// var namespace = module.exports.namespace;

// var namespace = "ua.getdirections";
// exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");


// var required = ["address"]; 
// exports.required = required;

// exports.define = {
//   namespace: namespace,
//   title: "Get Directions",
//   description: "A button that opens a dialog showing directions through supported map apps.",
//   fields: {
//     "address": {
//       type: "string", 
//       description: "The address to give directions to."
//     }, 
//     "inner": {
//       type: "inner", 
//       description: "The contents od the button to open the directions dialog."
//     },
//     "moduleClass": {
//       type: "class",
//       description: "The class to add to the dialog box."
//     }
//   },
//   required: required,
//   supportServer: false
// } 



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // if ("UATisServer" in window && window.UATisServer) {

  //  return await jsonRender.generateJsonWrapper(options);

  // };


  if (!("inner" in options)) {
    options.inner = [{
        "n": "i",
        "c": "bi bi-geo-alt-fill"
      }, "Get Directions"];
  }

  var button = {
    "n": "button",
    "class": "btn btn-primary",
    "i": options.inner
  };

  button = await jsonRender.render(button, {
    PassthroughOptions: options
  });

  // console.log("The button is: ", {
  //   btn: btn,
  //   btnType: typeof btn,
  //   PassthroughOptions: options
  // });

  button.addEventListener("click", async function(event) {

    var address = event.target.getAttribute("address");
    var modal = {
      "n": "ua.dialog.getdirections",
      "address": options.address,
    };

    if ("moduleClass" in options) {
      modal.class = options.moduleClass;
    }

    var modal = await jsonRender.render(modal, {
      PassthroughOptions: options
    });

    document.body.appendChild(modal);

  });

  return button;

}

/** 
 * 
 * 
 * 
 * <a href="https://www.google.com/maps/place/16001+Needles+Rd,+Johnstown,+OH+43031" target="_blank">
                    <img src="../imgs/google-maps.png" alt="Google Maps"> Google Maps
                </a>

                <a href="https://maps.apple.com/?address=16001%20Needles%20Drive,+Johnstown,+OH+43031" target="_blank">
                    <img src="../imgs/apple-maps.png" alt="Apple Maps"> Apple Maps
                </a>
 * 
 *
*/
