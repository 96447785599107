/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * @jumpcutking/console
 *  
 * 
 * Justin K Kazmierczak
 * 
 * Creates a new stacktrace and conforms it to a stacktrace object {@link module:@jumpcutking/console~parseStackTrace} to a standard format.
 * 
 */

var namespace = "generateStacktrace";
var parseStackTrace = require("./parseStackTrace.js").function;

/**
 * Creates a new stacktrace and conforms it to a stacktrace object {@link module:@jumpcutking/console~parseStackTrace} to a standard format.
 * @param {*} stacktrace The error.stack string.
 * @param {*} _levelToRemove The number of lines (or calls) to remove from the stacktrace. It will automatically remove the "Error" line, and it's own call. levlToRemove is appended to "2", removing the first 2 lines.
 * @param {*} JSONReady If true, the stacktrace will be cloned and returned as a new object. This is useful for debugging and testing.
 * @returns {Object} The stacktrace object.
 * @see {@link module:@jumpcutking/console~parseStackTrace} for the stacktrace object format.
 * @public
 */
function GenerateStacktrace(_levelToRemove = 0) {//, JSONReady = false) {

    // if (JSONReady) {
    //     var tra = parseStackTrace((new Error()).stack, 2 + _levelToRemove);
    //     return JSON.parse(JSON.stringify(tra, Object.getOwnPropertyNames(tra)));
    // }

    return parseStackTrace((new Error()).stack, 2 + _levelToRemove);

} 


module.exports = {
    namespace: namespace,
    function: GenerateStacktrace,
    tests: [{
        namespace: `${namespace}.test`,
        invalidTest: true,
        must: true,
        run: async () => {
    
            var myStack = GenerateStacktrace(1);
            console.log("My stack", myStack);
            return true;

           }
    }] 
};