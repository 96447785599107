/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.fs.sidebyside.image",
  title: "Fullscreen Image with a Side by Side",
  description: "Shows a full screen image with scrollable content, side by side in individual columns.",
  fields: {
    "inner": {
      required: true,
      type: "inner",
      description: "The inner content to render on the image.",
      title: "Inner"
    }, 
    "ua-observe": {
      title: "Observe",
      type: "string", 
      description: "Activates the animation when the element is in view."
    }, 
    "image": {
      title: "Image",
      type: "uri",
      filetype: "image",
      description: "The path to the image to display.",
      required: true
    }, 
    "imageClass": {
      title: "Image Class",
      type: "class",
      description: "The classes to append to the image.",
    }, "side": {
      title: "Side",
      description: "The side to display the image on.",
      type: "string",
      list: {
        must: ["left", "right"]
      },
      default: "right"
    },
    "imageSize": {
      title: "Image File Style",
      type: "string",
      list: {
        must: ["contain", "cover", "auto"]
      },
      description: "The size of the image to display.",
      default: "contain"
    }
  },
  passthrough: true
});

// var namespace = "ua.fs.sidebyside.image";
// exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
// var uae = require("./templates/ua.element.js");

// var required = ["inner"];
// exports.required = required;

// exports.define = {
//   namespace: namespace,
//   fields: {
//     "ua-observe": {
//       type: "string", 
//       description: "Activates the animation when the element is in view."
//     }, 
//     "image": {
//       type: "uri",
//       description: "The path to the image to display."
//     }, 
//     "imageClass": {
//       type: "string",
//       description: "The classes to append to the image.",
//     }, "side": {
//       type: "list",
//       list: ["left", "right"],
//       default: "right"
//     },
//     "imageSize": {
//       type: "string",
//       description: "The size of the image to display.",
//       default: "contain"
//     }
//   },
//   required: required
// } 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
  * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  var imageProperties = {
    class: "d-inline-block",
    style: ""
  };

  if ("image" in options) {
   imageProperties.style = `background-image: url('${options.image}'); background-size: contain; background-repeat: no-repeat; background-position: center;`;
  }

  if ("imageClass" in options) {
    imageProperties.class = options.imageClass + " " + imageProperties.class;
  }

  if (!"imageSize" in options) {
    options.imageSize = "contain";
  }

  var mobileImage, desktopImage = {};

  if ("image" in options) {
    mobileImage = {
      "n": "img",
      //"style": `width:20rem; height:20rem; padding:0; ${imageProperties.style}}`,
      "class": `img-fluid rounded image-shadow ${imageProperties.class}`,
      "src": options.image,
      // "class": imageProperties.class
    };
  
    desktopImage = {
      "n": "div",
      "style": `width:100%; height:100%; padding:0; margin:0; ${imageProperties.style}; background-size: ${options.imageSize};`,
      "src": options.image,
      "class": imageProperties.class
    };

  } else {
    mobileImage = {
      "n": "div",
      "style": `width:20rem; height:20rem; padding:0; ${imageProperties.style}`,
      "class": `${imageProperties.class}`
    };
  
    desktopImage = {
      "n": "div",
      "style": `width:100%; height:100%; padding:0; margin:0; ${imageProperties.style}; background-size: ${options.imageSize};`,
      "class": imageProperties.class
    };
  }



  var articleInner = [];

  if ("side" in options && options.side == "left") {
    var articleInner = [
    {
      "n": "div",
      "class": "col-12 d-flex d-lg-none p-5 justify-content-center",
      "inner": mobileImage
    },{
      "n": "div",
      "class": "col-6 d-none d-lg-block",
      "inner": desktopImage
    }, {
      "n": "div",
      "class": "col-lg-6 col-xs-12",
      "inner": options.inner
    }
  ];

} else {

  var articleInner = [{
      "n": "div",
      "class": "col-12 d-flex d-lg-none p-5 justify-content-center",
      "inner": mobileImage
    },
    {
      "n": "div",
      "class": "col-lg-6 col-xs-12",
      "inner": options.inner
    },
    {
      "n": "div",
      "class": "col-6 d-none d-lg-block",
      "inner": desktopImage
    }
  ];

}

    var article = {
      "n": "article",
      "class": "container-fluid vh-100-mobile d-flex p-3",
      "style": "overflow: scroll;",
      "inner": [
        {
          "n": "div",
          "class": "row m-auto",
          "inner": articleInner
        }
      ]
    };

    // if ("ua-observe" in options) {
    //   article.i.i["ua-observe"] = "";
    // };
    
    return await jsonRender.render(article, {
      PassthroughOptions: options
    });

};

/** 
{
  "n": "article",
  "class": "container-fluid vh-100 d-flex hide-scrollbar",
  "style": "position:relative; top:-100vh; overflow: scroll;",
  "inner": [
    {
      "n": "div",
      "class": "row m-auto",
      "inner": [
        {
          "n": "div",
          "class": "col-12 d-flex d-lg-none p-5",
          "inner": [
            {
              "n": "div",
              "style": "width:20rem; height:20rem; padding:0;",
              "title": "home",
              "class": "navbar-brand mybrand d-inline-block m-auto",
              "style": `background-image: url('${options.image}'); background-size: contain; background-repeat: no-repeat; background-position: center;`
            }
          ]
        },
        {
          "n": "div",
          "class": "col-lg-6 col-xs-12",
          "inner": options.inner
        },
        {
          "n": "div",
          "class": "col-6 d-none d-lg-block",
          "inner": [
            {
              "n": "div",
              "style": "width:100%; height:100%; padding:0; margin:0;",
              "title": "home",
              "class": "navbar-brand mybrand d-inline-block",
              "style": `background-image: url('${options.image}'); background-size: contain; background-repeat: no-repeat; background-position: center;`
            }
          ]
        }
      ]
    }
  ]
}
*/
