/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */

/*
<div class="form-group pb-3">
                            <label for="address-line-1" class="pb-2 fs-4">Address</label>
                            <div class="input-group pb-3">
                                <div class="input-group-prepend">
                                    <div class="input-group-text text-color rounded-0 rounded-start"><i class="bi bi-person-rolodex"></i></div>
                                </div><input id="address-line-1" type="text" autocomplete="street-address" class="form-control is-invalid" title="Address Line 1" placeholder="123 First Lane" required="" maxlength="250">
                            </div>
                            <div class="input-group pb-3">
                                <input id="address-line-2" type="text" autocomplete="address-line2" class="form-control" title="Address Line 2" placeholder="Order 66" maxlength="250">
                            </div>
                            <div class="input-group pb-3">
                                <input id="address-city" type="text" class="form-control is-invalid" title="City" placeholder="City" maxlength="250" required="">
                            </div>

                            <div class="input-group pb-3">
                                <!-- <div class="input-group-prepend">
                                            <div class="input-group-text text-color rounded-0 rounded-start"><i
                                                    class="bi bi-person-lines-fill"></i></div>
                                         </div> -->
                                <select class="form-select" id="state" required="">
                                    <option value="" selected="">State</option>
                                    <option value="alabama">Alabama</option>
                                    <option value="alaska">Alaska</option>
                                    <option value="arizona">Arizona</option>
                                    <option value="arkansas">Arkansas</option>
                                    <option value="california">California</option>
                                    <option value="colorado">Colorado</option>
                                    <option value="connecticut">Connecticut</option>
                                    <option value="delaware">Delaware</option>
                                    <option value="florida">Florida</option>
                                    <option value="georgia">Georgia</option>
                                    <option value="hawaii">Hawaii</option>
                                    <option value="idaho">Idaho</option>
                                    <option value="illinois">Illinois</option>
                                    <option value="indiana">Indiana</option>
                                    <option value="iowa">Iowa</option>
                                    <option value="kansas">Kansas</option>
                                    <option value="kentucky">Kentucky</option>
                                    <option value="louisiana">Louisiana</option>
                                    <option value="maine">Maine</option>
                                    <option value="maryland">Maryland</option>
                                    <option value="massachusetts">Massachusetts</option>
                                    <option value="michigan">Michigan</option>
                                    <option value="minnesota">Minnesota</option>
                                    <option value="mississippi">Mississippi</option>
                                    <option value="missouri">Missouri</option>
                                    <option value="montana">Montana</option>
                                    <option value="nebraska">Nebraska</option>
                                    <option value="nevada">Nevada</option>
                                    <option value="new-hampshire">New Hampshire</option>
                                    <option value="new-jersey">New Jersey</option>
                                    <option value="new-mexico">New Mexico</option>
                                    <option value="new-york">New York</option>
                                    <option value="north-carolina">North Carolina</option>
                                    <option value="north-dakota">North Dakota</option>
                                    <option value="ohio">Ohio</option>
                                    <option value="oklahoma">Oklahoma</option>
                                    <option value="oregon">Oregon</option>
                                    <option value="pennsylvania">Pennsylvania</option>
                                    <option value="rhode-island">Rhode Island</option>
                                    <option value="south-carolina">South Carolina</option>
                                    <option value="south-dakota">South Dakota</option>
                                    <option value="tennessee">Tennessee</option>
                                    <option value="texas">Texas</option>
                                    <option value="utah">Utah</option>
                                    <option value="vermont">Vermont</option>
                                    <option value="virginia">Virginia</option>
                                    <option value="washington">Washington</option>
                                    <option value="west-virginia">West Virginia</option>
                                    <option value="wisconsin">Wisconsin</option>
                                    <option value="wyoming">Wyoming</option>
                                    <option value="territories">Other Territories</option>
                                </select>

                                <input id="country" type="text" autocomplete="country" class="form-control" title="country" readonly="" disabled="" value="United States" required="" maxlength="250">

                                <input id="postalcode" type="text" autocomplete="postal-code" class="form-control is-invalid" title="Zip Code" placeholder="00000-1234" required="" minlength="4">
                            </div>


                        </div>
*/
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.address",
  title: "Form Group: An address box",
  description: "A series of form groups for an adress.",
  control: true,
  prevalidate: false,
  supportServer: false,
  fields: {
    "name": {
      title: "Name",
      type: "string",
      description: "The name of the control.",
      required: true
    },
    "title": {
      title: "Title",
      type: "string",
      description: "The title of the control.",
      required: true
    },
    "inputClass": {
      title: "Input Class",
      type: "class",
      description: "The class of the input."
    }, "inputStyle": {
      title: "Input Style",
      type: "style",
      description: "The style of the input."
    }, "description": {
      title: "Description",
      type: "inner",
      description: "The helpful text at the bottom of the control."
    }
  },
  passthrough: {
    except: ["inner", "type", "id", "inputclass", "inputstyle"]
  }
});
var namespace = module.exports.namespace;

var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");
var sanitize = require("../../../uam/sanitize.js");

// var define = {
//   namespace: "ua.address",
//   title: "Form Group: An address box",
//   description: "A series of form groups for an adress.",
//   control: true,
//   prevalidate: false,
//   supportServer: false,
//   fields: {
//     "name": {
//       type: "string",
//       description: "The name of the control.",
//       required: true
//     },
//     "title": {
//       type: "string",
//       description: "The title of the control.",
//       required: true
//     },
//      "inputClass": { 
//       type: "string",
//       description: "The class of the input."
//     }, "inputStyle": {
//       type: "string",
//       description: "The style of the input."
//     }, "description": {
//       type: "inner",
//       description: "The helpful text at the bottom of the control."
//     }
//   },
//   passthrough: {
//     except: ["inner", "type", "id", "inputclass", "inputstyle"]
//   }
// }; module.exports.define = define;

 
/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

  // var randomName = uai.generateRandomID(12);

  var addressEle = {
    n: "div",
    c: "form-group",
    i: [
      {
        n: "label",
        c: "pb-2 fs-4",
        i: options.title
      },
      {
        n: "div",
        c: "input-group pb-3",
        i: [
          {
            n: "div",
            c: "input-group-prepend",
            i: {
              n: "div",
              c: "input-group-text text-color rounded-0 rounded-start",
              i: {
                n: "i",
                c: "bi bi-person-rolodex"
              }
            }
          },
          {
            n: "input",
            id: options.name + "-line-1",
            type: "text",
            autocomplete: "street-address",
            c: "form-control",
            title: options.title + " Line 1",
            placeholder: "123 First Lane",
            required: true,
            maxlength: 250,
            minlength: 3
          }
        ]
      },
      {
        n: "div",
        c: "input-group pb-3",
        i: {
          n: "input",
          id: options.name + "-line-2",
          type: "text",
          autocomplete: "address-line2",
          c: "form-control",
          title: options.title + " Line 2",
          placeholder: "Order 66",
          maxlength: 250
        }
      },
      {
        n: "div",
        c: "input-group pb-3",
        i: {
          n: "input",
          id: options.name + "-city",
          type: "text",
          c: "form-control",
          title: "City",
          placeholder: "City",
          maxlength: 250,
          minlength: 2,
          required: true
        }
      },
      {
        n: "div",
        c: "input-group pb-3",
        i: [
          {
            n: "select",
            c: "form-select",
            id: options.name + "-state",
            required: true,
            i: [
              {
                n: "option",
                value: "",
                selected: true,
                i: "State"
              },
              {
                n: "option",
                value: "Alabama",
                i: "Alabama"
              },
              {
                n: "option",
                value: "Alaska",
                i: "Alaska"
              },
              {
                n: "option",
                value: "Arizona",
                i: "Arizona"
              },
              {
                n: "option",
                value: "Arkansas",
                i: "Arkansas"
              },
              {
                n: "option",
                value: "California",
                i: "California"
              },
              {
                n: "option",
                value: "Colorado",
                i: "Colorado"
              },
              {
                n: "option",
                value: "Connecticut",
                i: "Connecticut"
              },
              {
                n: "option",
                value: "Delaware",
                i: "Delaware"
              },
              {
                n: "option",
                value: "Florida",
                i: "Florida"
              },
              {
                n: "option",
                value: "Georgia",
                i: "Georgia"
              },
              {
                n: "option",
                value: "Hawaii",
                i: "Hawaii"
              },
              {
                n: "option",
                value: "Idaho",
                i: "Idaho"
              },
              {
                n: "option",
                value: "Illinois",
                i: "Illinois"
              },
              {
                n: "option",
                value: "Indiana",
                i: "Indiana"
              },
              {
                n: "option",
                value: "Iowa",
                i: "Iowa"
              },
              {
                n: "option",
                value: "Kansas",
                i: "Kansas"
              },
              {
                n: "option",
                value: "Kentucky",
                i: "Kentucky"
              },
              {
                n: "option",
                value: "Louisiana",
                i: "Louisiana"
              },
              {
                n: "option",
                value: "Maine",
                i: "Maine"
              },
              {
                n: "option",
                value: "Maryland",
                i: "Maryland"
              },
              {
                n: "option",
                value: "Massachusetts",
                i: "Massachusetts"
              },
              {
                n: "option",
                value: "Michigan",
                i: "Michigan"
              },
              {
                n: "option",
                value: "Minnesota",
                i: "Minnesota"
              },
              {
                n: "option",
                value: "Mississippi",
                i: "Mississippi"
              },
              {
                n: "option",
                value: "Missouri",
                i: "Missouri"
              },
              {
                n: "option",
                value: "Montana",
                i: "Montana"
              },
              {
                n: "option",
                value: "Nebraska",
                i: "Nebraska"
              },
              {
                n: "option",
                value: "Nevada",
                i: "Nevada"
              },
              {
                n: "option",
                value: "New Hampshire",
                i: "New Hampshire"
              },
              {
                n: "option",
                value: "New Jersey",
                i: "New Jersey"
              },
              {
                n: "option",
                value: "New Mexico",
                i: "New Mexico"
              },
              {
                n: "option",
                value: "New York",
                i: "New York"
              },
              {
                n: "option",
                value: "North Carolina",
                i: "North Carolina"
              },
              {
                n: "option",
                value: "North Dakota",
                i: "North Dakota"
              },
              {
                n: "option",
                value: "Ohio",
                i: "Ohio"
              },
              {
                n: "option",
                value: "Oklahoma",
                i: "Oklahoma"
              },
              {
                n: "option",
                value: "Oregon",
                i: "Oregon"
              },
              {
                n: "option",
                value: "Pennsylvania",
                i: "Pennsylvania"
              },
              {
                n: "option",
                value: "Rhode Island",
                i: "Rhode Island"
              },
              {
                n: "option",
                value: "South Carolina",
                i: "South Carolina"
              },
              {
                n: "option",
                value: "South Dakota",
                i: "South Dakota"
              },
              {
                n: "option",
                value: "Tennessee",
                i: "Tennessee"
              },
              {
                n: "option",
                value: "Texas",
                i: "Texas"
              },
              {
                n: "option",
                value: "Utah",
                i: "Utah"
              },
              {
                n: "option",
                value: "Vermont",
                i: "Vermont"
              },
              {
                n: "option",
                value: "Virginia",
                i: "Virginia"
              },
              {
                n: "option",
                value: "Washington",
                i: "Washington"
              },
              {
                n: "option",
                value: "West Virginia",
                i: "West Virginia"
              },
              {
                n: "option",
                value: "Wisconsin",
                i: "Wisconsin"
              },
              {
                n: "option",
                value: "Wyoming",
                i: "Wyoming"
              },
              {
                n: "option",
                value: "Other Territories",
                i: "Other Territories"
              }
            ]
          },
          {
            n: "input",
            id: options.name + "-country",
            type: "text",
            autocomplete: "country",
            c: "form-control",
            title: "Country",
            readonly: true,
            disabled: true,
            value: "United States",
            required: true,
            maxlength: 250,
            minlength: 3
          },
          {
            n: "input",
            id: options.name + "-postalcode",
            type: "text",
            autocomplete: "postal-code",
            c: "form-control",
            title: "Zip Code",
            placeholder: "00000-1234",
            required: true,
            minlength: 4
          }
        ]
      }
    ]
  }

  var domElement = await jsonRender.render(addressEle);

  //add focus events to the inputs
  var inputs = domElement.querySelectorAll("input");
  for (var i = 0; i < inputs.length; i++) {
    // inputs[i].addEventListener("focus", focus);
    // inputs[i].addEventListener("focusout", focus);
    inputs[i].addEventListener("change", change);
  }

  //on change on select
  var select = domElement.querySelector("select");
  select.addEventListener("change", change);

  return domElement;


}

async function focus(event) {
  // console.log("focus", event);
  var parent = this.closest("uae");
  validation(parent, true);

}

async function change(event) {
  // console.log("change", event);
  var parent = this.closest("uae");
  save(parent.getAttribute("name"), parent, {});
}


function validation(control, focus = false) {
  // var input = control.querySelector("input");
  var errors = [];

  var inputs = control.querySelectorAll("input");
  var select = control.querySelector("select");


  // control.classList.remove("is-invalid");
  // control.classList.remove("is-valid");

  var isinvalid = false;

  //validate select

  select.classList.remove("is-invalid");
  select.classList.remove("is-valid");

  if (select.checkValidity() == false) {
    isinvalid = true;
    
    select.classList.add("is-invalid");

    errors.push({
      type: "validation",
      message: `${select.id}: ${select.validationMessage}`,
      input: {
        id: select.id
      }
    });
  } else {
    if ((select.value == "") || (select.value == "State")) {
      isinvalid = true;
      select.classList.add("is-invalid");

      errors.push({
        type: "validation",
        message: `${select.id}: ${select.validationMessage}`,
        input: {
          id: select.id
        }
      });
    } else {
      select.classList.add("is-valid");
    }
  }


  for (var i = 0; i < inputs.length; i++) {

    var input = inputs[i];

    if ((focus) && (input.value.trim() == "")) {
      continue;
    }

    input.classList.remove("is-invalid");
    input.classList.remove("is-valid");

    if (input.checkValidity() == false) {

      isinvalid = true;
      // control.classList.add("is-invalid");
      input.classList.add("is-invalid");

      errors.push({
        type: "validation",
        message: `${input.id}: ${input.validationMessage}`,
        input: {
          id: input.id
        }
      });
    } else {
      input.classList.add("is-valid");
    }

  }

  if (!(focus)) {

    //get the select
    var select = control.querySelector("select");
    if (select.checkValidity() == false) {
      isinvalid = true;
      // control.classList.add("is-invalid");
      select.classList.add("is-invalid");

      errors.push({
        type: "validation",
        message: `${select.id}: ${select.validationMessage}`,
        input: {
          id: select.id
        }
      });
    } else {
      //if the select is equal to "State" then it is invalid
      if (select.value == "") {
        isinvalid = true;
        // control.classList.add("is-invalid");
        select.classList.add("is-invalid");

        errors.push({
          type: "validation",
          message: `${select.id}: ${select.validationMessage}`,
          input: {
            id: select.id
          }
        });
      } else {
        select.classList.add("is-valid");
      }
    }
  }

  if (focus) {

    control.classList.remove("is-invalid");
    control.classList.remove("is-valid");

    if (isinvalid) {
      control.classList.add("is-invalid");
    } else {
      // if (!(focus)) {
        control.classList.add("is-valid");
      // }
    }
  }

  if (!(focus)) {
    if (!(sanitize.address)) {
      errors.push({
        type: "validation",
        message: `Address: The address is not invalid, please check the address.`,
        input: {
          id: control.id
        }
      });
    }
  }

  return errors;
}


/**
 * Saves the code from the iframe.
 * Called by UATools.
 * @param {*} name The name of the control.
 * @param {*} control The control to save.
 * @param {*} repo The repo (used to send back), with the prevalidation results.
 * @property {*} repo.success The success object (is this field ready to be saved).
 * @property {*} repo.data The actual data object that will be saved. JSON encodable only (no functions or promises).
 * @property {*} repo.errors The error's applied to the object. Should be an array, can have more than one item.
 * @property {*} repo.errors.input If appliable, the direct input that caused the erorr - it must be an object. If input is not provided the control will be highlighted.
 * @property {*} repo.errors.input.id The id of the input field, if applicable.
 * @property {*} repo.errors.input.name The name of the input field if applicable.
 * @property {*} repo.errors.type The type of error that occured.
 *  - Supports: "validation" - The input or field or control is invalid
 *  - Supports: "thowable" - Processing this field caused a throwable to error out.
 * @property {*} repo.errors.message The message to display to the user.
 * @returns The repo object with the data to save
 */
async function save(name, control, repo) {

  // console.log("Starting save", {
  //   control, repo
  // });

  control.classList.remove("is-invalid");
  control.classList.remove("is-valid");


  repo.errors = validation(control);
  if (repo.errors.length > 0) {
    repo.success = false;
    // control.classList.add("is-invalid");
  } else {
    repo.success = true;
    control.classList.add("is-valid");
  }

  //build an address object
  var address = {
    line1: control.querySelector(`#${name}-line-1`).value,
    line2: control.querySelector(`#${name}-line-2`).value,
    city: control.querySelector(`#${name}-city`).value,
    state: control.querySelector(`#${name}-state`).value,
    country: control.querySelector(`#${name}-country`).value,
    postalcode: control.querySelector(`#${name}-postalcode`).value
  }


  repo.data = address;

  // console.log("Ending save", {
  //   control, repo
  // });

  return repo;

} module.exports.save = save;

/**
 * Loads the control with data.
 * @param {*} name The name of the control.
 * @param {*} control The control itself (including placeholder tag).
 * @param {*} data The data to load into the control.
 */
async function load(name, control, data) {

  //
  if (!(data)) {
    //check to make sure the control line is empty
    if ((control.querySelector(`#${name}-line-1`).value == "")
      && (control.querySelector(`#${name}-line-2`).value == "")
      && (control.querySelector(`#${name}-city`).value == "")
      && (control.querySelector(`#${name}-state`).value == "")
      && (control.querySelector(`#${name}-country`).value == "")
      && (control.querySelector(`#${name}-postalcode`).value == "")) {
      return;
    }
  }

  //must be an object or throw an error
  if (!(sanitize.address(data))) {
    throw new Error("Invalid Address Data.");
  }

  //in each property of the data object, set the input, know a property may be missing
  for (var key in data) {
    switch (key) {
      case "line1":
        control.querySelector(`#${name}-line-1`).value = data[key];
        break;
      case "line2":
        control.querySelector(`#${name}-line-2`).value = data[key];
        break;
      case "city":
        control.querySelector(`#${name}-city`).value = data[key];
        break;
      case "state":
        control.querySelector(`#${name}-state`).value = data[key];
        break;
      case "country":
        control.querySelector(`#${name}-country`).value = data[key];
        break;
      case "postalcode":
        control.querySelector(`#${name}-postalcode`).value = data[key];
        break;
      default:
        break;
    }
  }

  save(name, control, {});


} module.exports.load = load;