/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.notification.icon",
  title: "Notification Icon",
  description: "Adds a notification icon to the page.",
  fields: {
    "description": {
      title: "Description",
      type: "string",
      description: "A description of the notification."
    }, 
    "inner": {
      required: true,
      title: "Contents of Notification",
      type: "string",
      description: "The inner html of the notification."
    }, 
    "position": {
      title: "Absolute Position",
      type: "boolean",
      description: "Should it be a position of absolute?",
      default: true
    }
  }
});

/**
 * Adds notification badge to the top corner of an element
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  var span = {
    n: "span",
    c: "top-0 badge rounded-pill bg-info",
    i: options.inner
  }

  if ("position" in options) {
    if (options.position) {
      span.c = span.c + " position-absolute";
    }
  } else {
    // span.c = span.c + " position-absolute";
  }

  //support for visual description and accessibility
  if ("description" in options) {
    var inner = [
      options.inner, {
        n: "span",
        c: "visually-hidden",
        i: options.description
      }
    ];

    span.i = inner;

  }
  
  return span;
  
};

/* <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    99+
    <span class="visually-hidden">unread messages</span>
  </span> */