/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */

var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.list.item",
  title: "List Item",
  description: "A list item for a list group.",
  fields: {
    "inner": {
      title: "Inner",
      type: "inner", 
      description: "The inner contents of this item.",
      required: true
    }, "active": {
      title: "Active",
      type: "boolean",
      description: "If the item is active.",
      default: false
    }
  },
});

// var namespace = "ua.list.item";
// exports.namespace = namespace;

// var jsonRender = require("../interface/jsonRender.js");
// var uae = require("./templates/ua.element.js");

// var required = ["inner"];
// exports.required = required;



// exports.define = {
//   namespace: namespace,
//   fields: {
//     "inner": {
//       type: "inner", 
//       description: "The inner contents of this item."
//     }, "active": {
//       type: "boolean",
//       description: "If the item is active.",
//       default: false
//     }
//   },
//   required: required
// } 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  var href = false;
  var active = false;
  
  if ("href" in options) {
    href = options.href;
  }

  if ("active" in options) {
    active = options.active;
  }

  var item = createItem(options.inner, href, active);
    
  return item;

};

/**
 * Creates a list group item
 * @param {*} inner The inner json or html.
 * @param {*} href The link to the item. Defaults to false (no link).
 * @param {*} active If the item is active. Defaults to false.
 * @returns The list group item.
 */
function createItem(inner, href = false, active = false) {
  var item = {
      n: "div",
      c: "list-group-item list-group-item-action",
      i: inner
    };

  if (href) {
    item.n = "a";
    item.href = href;
  }

  if (active) {
    item.c += " active";
    item["aria-current"] = "true";
  }

  return item;
} module.exports.createItem = createItem;

/** 
<a href="#" class="list-group-item list-group-item-action active" aria-current="true">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">List group item heading</h5>
      <small>3 days ago</small>
    </div>
    <p class="mb-1">Some placeholder content in a paragraph.</p>
    <small>And some small print.</small>
  </a>
  <a href="#" class="list-group-item list-group-item-action">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">List group item heading</h5>
      <small class="text-body-secondary">3 days ago</small>
    </div>
    <p class="mb-1">Some placeholder content in a paragraph.</p>
    <small class="text-body-secondary">And some muted small print.</small>
  </a>
  <a href="#" class="list-group-item list-group-item-action">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">List group item heading</h5>
      <small class="text-body-secondary">3 days ago</small>
    </div>
    <p class="mb-1">Some placeholder content in a paragraph.</p>
    <small class="text-body-secondary">And some muted small print.</small>
  </a> */
