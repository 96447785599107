/*!
 * Protected by The Universe Terms of Service and Privacy Policy.
 * Subject to the Terms of Service and Privacy Policy.
 * 
 * Justin K Kazmierczak
 * © 2023-2025. The Universe & Justin K Kazmierczak. All rights reserved.
 * 
 * Copy and distribution of this code is strictly prohibited.
 */

var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.a.fade50",
  title: "Animation: Fade In on Scroll (50%)",
  description: "Fades in content when it scrolls into view, but fades it out half way through the screen.",
  supportServer: false,
  fields: {
    inner: {
        title: "Inner",
        type: "inner",
        description: "The content to fade in and out at 50 vh"
    }
  }
});
var namespace = module.exports.namespace;
// var define = exports.define;

var namespace = "ua.a.fade50";
exports.namespace = namespace;

// var f = require("../scripts/f.js");
// var log = require("../scripts/log.js");
var uae = require("../elements/templates/ua.element.js");
// var uai = require("../interface/interface.js");
var events = require("../../../uam/events.js");

var jsonRender = require("../interface/jsonRender.js");

var required = [];
// exports.required = required;

// exports.define = {
//   namespace: namespace,
//   title: "Universe Animations Fade in at 50 vh",
//   description: "Fades in content when it scrolls into view, but fades it out half way through the screen.",
//   fields: [{
//     "inner": {
//         type: "inner",
//         description: "The content to fade in and out at 50 vh"
//     }
//   }],
//   supportServer: false,
//   required: required
// } 

/**
 * Report if the alert is rendered. (uae)
 * @param  {*} element [description]
 * @return {*}         [description]
 */
exports.isRendered = function (element) {
  return uae.isRendered(element);
}

/**
 * Called by the interface to render the animation
 * @param {*} json The json to render the type on animation on.
 */
exports.render = async function (json) {

    // var options = uae.GetOptions(json, required);

    var ele = document.createElement("div");
    ele.setAttribute("ua-anim", namespace);
     ele.classList.add("ua-fadeOut");
    ele.appendChild(await jsonRender.render(json.inner, {
        passthroughOptions: json
    }));

    return ele;

};

var vhDef = 50;

function isElementAtCenter(el) {
    const rect = el.getBoundingClientRect();
    const viewportCenter = window.innerHeight / 2;
    
    return rect.top <= viewportCenter && rect.bottom >= viewportCenter;
  }

// function isElementLowerPortionAtCenter(el) {
//     const rect = el.getBoundingClientRect();
//     const viewportCenter = window.innerHeight / 2;
  
//     return rect.bottom <= viewportCenter && rect.bottom >= 0;
//   }
  

function NextFrame(ele) {
    //do I still have the class ua-fade?
    if (ele.classList.contains("ua-fade")) {

        //if the element is in the percentage of the viewprt of vhDef
        //reomve the class ua-fade
        if (isElementAtCenter(ele)) {
            ele.classList.remove("ua-fade");
        } else {
            //get next animation frame 
            requestAnimationFrame(() => {
                NextFrame(ele);
            });
        }
    }
}

// Function to handle fading
function handleFade(entries, observer) {
    entries.forEach((entry) => {
    //     console.log("handleFade", {
    //         entry: entry
    // });
        if (entry.isIntersecting) {
            // Add the class to fade in the element
            entry.target.classList.add('ua-fade');
            
            //get next animation frame 
            requestAnimationFrame(() => {
                NextFrame(entry.target);
            });



        } else {
            entry.target.classList.remove('ua-fade');
        }

    });
  }
  
  // Set up the IntersectionObserver
  const observer = new IntersectionObserver(handleFade, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5, // Adjust this threshold as needed
  });

events.on("interface.oninteraction", async () => {
      
  // Get the elements you want to observe
  const elementsToObserve = document.querySelectorAll(`[ua-anim="${namespace}"]`);
  
  // Observe each element
  elementsToObserve.forEach((element) => {
    observer.observe(element);
  });

})



// /** Get all span objects with the attribute "ua-anim-typeOn", convert that atrribute contents to
//  * an json array, and than animate it so each string in the array "types on the screen" like a typewriter,
//  * wait a moment, backspace all the letters, and advance to the next string in the array. At the end of the
//  * array return to the first string in the array and repeat the process.
//  * Example HTML:
//  *  <p class="text-center">Your <span ua-anim-typeOn='["shopping", "searching", "dreaming", "gaming", "streaming", "coding", "browsing", "sharing", "creating"]'>coding</span> experience just got better.</p>
//  */
// function TypeOn() {
//   // Get all span elements with the attribute "ua-anim-typeOn"
//   const spanElements = document.querySelectorAll('span[ua-typeOn]');
//   const blinkingCursor = `<span class="blinking">_</span>`;
//   // Iterate over each span element
//   spanElements.forEach((span) => {
//     const attributeValue = span.getAttribute('ua-anim-typeOn');
//     const stringsArray = JSON.parse(attributeValue);
//     const typingDelay = 75; // Delay between typing each character
//     const waitDelay = 800; // Delay between erasing each character

//     let currentIndex = 0; // Index of the current string in the array
//     let currentString = stringsArray[currentIndex]; // Current string to display
//     let currentText = ''; // Current text being typed

//     // Function to animate typing effect
//     function typeWriter() {
//       if (currentText.length < currentString.length) {
//         currentText += currentString.charAt(currentText.length);
//         span.innerHTML = currentText + blinkingCursor;
//         setTimeout(typeWriter, typingDelay); // Delay between typing each character
//       } else {
//         setTimeout(eraseText, waitDelay); // Delay before erasing text
//       }
//     }

//     // Function to erase typed text
//     function eraseText() {
//       if (currentText.length > 0) {
//         currentText = currentText.slice(0, -1);
//         span.innerHTML = currentText + blinkingCursor;
//         setTimeout(eraseText, typingDelay); // Delay between erasing each character
//       } else {
//         currentIndex = (currentIndex + 1) % stringsArray.length; // Move to the next string in the array
//         currentString = stringsArray[currentIndex];
//         setTimeout(typeWriter, typingDelay); // Delay before typing next string
//       }
//     }

//     // Start the typing animation
//     typeWriter();
//   });
// }

    
//   // Call the TypeOn function to initiate the animation
//   TypeOn();