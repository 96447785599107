/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.ts",
  title: "Timestamp",
  description: `A universal timestamp element that shows a database date to the end user.`,
  fields: {
    "inner": {
      title: "Inner",
      type: "date", 
      description: "The database date to convert to the proper time.",
      required: true
    }, "hidetime": {
      title: "Hide Time",
      type: "boolean",
      description: "If true, the time will not be shown.",
      default: false
    }
  }
});


var f = require("../scripts/f.js");
// var uae = require("./templates/ua.element.js");

// var namespace = "ua.ts";
// exports.namespace = namespace;

// var define = {
//   namespace: namespace,
//   title: "Timestamp",
//   description: `A universal timestamp element that shows a database date to the end user.`,
//   fields: {
//     "inner": {
//       type: "date", 
//       description: "The database date to convert to the proper time.",
//       required: true
//     }, "hidetime": {
//       type: "boolean",
//       description: "If true, the time will not be shown.",
//       default: false
//     }
//   }
// }; module.exports.define = define;

exports.render = function (options) {

  // console.log("Timestamp Render", ele);

  //check if the date is just YYYY-MM-DD
  if (options.inner.length == 10) {
    options.inner = options.inner + "T00:00:00";
  }

   //var date = new Date($(this).attr("fromserver"));
  //  var ele = document.createElement("span");
   var date = new Date(options.inner);

  //  console.log("Timestamp Render", date);

   var rtn = f.uaMonthNames[date.getMonth()] + " "
      + date.getDate() + ", " + date.getFullYear() + " "; 

    if (!(options.hidetime)) {

      if (date.getHours() > 12) {
        rtn = rtn + (date.getHours() - 12) + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + " PM";
      } else {
        rtn = rtn + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + " AM";
      }

    }


  return {
    n: "span",
    i: rtn,
    "data-toggle": "tooltip",
    title: rtn + " " + f.getTimezoneName(date)
  };

  // ele.innerText = rtn;

  // rtn = rtn + " " + f.getTimezoneName(date);
  // ele.setAttribute("data-toggle", "tooltip");
  // ele.setAttribute("title", rtn);
  // // uae.SetRendered(ele);

  // return ele;

};