/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.alert",
  title: "Alert",
  description: "Creates an alert box.",
  fields: {
    "title": {
      title: "Title",
      type: "string",
      description: "The title of the alert."
    },
    "icon": {
      title: "Icon",
      type: "icon",
      description: "The class of the icon.",
      default: "!default"
    },
    "alertclass": {
      title: "Alert Class",
      type: "string",
      list: {
        must: ["alert-info", "alert-warning", "alert-danger"]
      },
      description: "The class of the alert."
    },
    "inner": {
      title: "Inner",
      type: "inner",
      description: "The inner content of the alert.",
      required: true
    },
  }
});

// var namespace = module.exports.namespace;
var jsonRender = require("../interface/jsonRender.js");

// var f = require("../scripts/f.js");
var log = require("../scripts/log.js");
// var uae = require("./templates/ua.element.js");
// var required = ["alertclass", "icon", "inner"];

/**
 * Updates the message in the box.
 * @param  {*} element The element to update. 
 * @param  {*} html    The html to update the element with.
 */
exports.update = function (element, html) {
  var alert = element.querySelector("alert");
  if (!(alert == null)) {
    var content = alert.querySelector(".alertcontent");
    content.innerHTML = html;
  } else {
    log.warn("Can not update an alert that is not rendered.", control);
  }
}

exports.render = async function (options) {

  // var options = ele;
  // if (!(options.uarendered === true)) {
    var alert = document.createElement("alert");

    // var options = uae.GetOptions(ele, required);
    alert.setAttribute("role", "alert");


    if (options.icon == "!default") {
      if ((options.alertclass == "alert-danger") ||
        (options.alertclass == "danger")) {
        options.alertclass = "alert-danger";
        options.icon = "bi-exclamation-triangle-fill"
      } else if ((options.alertclass == "alert-warning") || 
        (options.alertclass == "warning")) {
        options.alertclass = "alert-warning";
        options.icon = "bi-exclamation-circle"
      } else if ((options.alertclass == "alert-info") ||
        (options.alertclass == "info")) {
        options.alertclass = "alert-info";
        options.icon = "bi-info-circle"
      } else {
        log.warn("Unknown alert class for a default icon set.", element, options);
      }
    }

    alert.classList.add("alert");
    alert.classList.add(options.alertclass);


    var div = document.createElement("div");
    div.appendChild(await jsonRender.convertJSONToDOM(options.inner));

    div.classList.add("d-inline");
    div.classList.add("alertcontent");

    if (!("title" in options)) {
      alert.innerHTML = `
        <i class="${options.icon}"></i>`;
    } else {
      alert.innerHTML = `
        <h3>
          <i class="${options.icon}"></i> ${options.title}
        </h3>`;
    }

    alert.appendChild(div);
    return alert;

  // }

};
