/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.select.datalist",
  title: "Searchable List Box",
  description: "A searchable list box.",
  control: true,
  prevalidate: true,
//   supportServer: false,
  fields: {
    "name": {
      title: "Name",
      type: "string",
      description: "The name of the control.",
      required: true
    },
    "title": {
      title: "Title",
      type: "string",
      description: "The title of the control.",
      required: true
    },
    "icon": {
        title: "Icon",
        type: "string",
        description: "The icon of the control.",
        required: false
      },
    "data": {
      title: "Data",
      type: "array",
      description: "The data items to be listed in the search box.",
      required: true
    },
    "mustmatch": {
      title: "Must Match",
      type: "boolean",
      description: "Does the value have to match the data exactly?",
      required: false
    }
  },
  passthrough: {
    except: ["inner", "type", "id", "inputclass", "inputstyle", "data", "includeOther"]
  }
});

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");

var define = {
  namespace: "ua.select.datalist",
  title: "Searchable List Box",
  description: "A searchable list box.",
  control: true,
  prevalidate: true,
//   supportServer: false,
  fields: {
    "name": {
      title: "Name",
      type: "string",
      description: "The name of the control.",
      required: true
    },
    "title": {
      title: "Title",
      type: "string",
      description: "The title of the control.",
      required: true
    },
    "icon": {
        title: "Icon",
        type: "string",
        description: "The icon of the control.",
        required: false
      },
    "data": {
      title: "Data",
      type: "array",
      description: "The data items to be listed in the search box.",
      required: true
    },
    "mustmatch": {
      title: "Must Match",
      type: "boolean",
      description: "Does the value have to match the data exactly?",
      required: false
    }
  },
  passthrough: {
    except: ["inner", "type", "id", "inputclass", "inputstyle", "data", "includeOther"]
  }
}; module.exports.define = define;

async function render(options) {
    var randomName = uai.generateRandomID(12);
  
    var inputElement = {
      "n": "input",
      "id": randomName,
      "class": "form-control",
      "title": options.title,
      "list": `${randomName}-datalist`,
      "mustmatch": options.mustmatch
    };
  
    // Applying options to the input element, including the icon if specified
    inputElement = await jsonRender.pass({...options}, {...inputElement}, ["name", "class", "icon", "style", "data", "mustmatch"]);
  
    var dataListItems = [];
  
    if (options.includeOther) {
      dataListItems.push({ "n": "option", "value": "Other" });  // 'Other' option is always first
    }
  
    dataListItems = dataListItems.concat(options.data.map(item => ({
      "n": "option",
      "value": item
    })));
  
    var inputGroupElement = {
      "n": "div",
      "c": "input-group",
      "i": [inputElement]
    };
  
    // Add icon to the input group if specified
    if (options.icon) {
      inputGroupElement.i.unshift({
        "n": "div",
        "c": "input-group-text text-color",
        "i": [{ "n": "i", "class": options.icon }]
      });
    }
  
    var element = {
      "n": "div",
      "name": options.name,
      "c": "form-group pb-3",
      "i": [
        {
          "n": "label",
          "for": randomName,
          "c": "pb-2 fs-4",
          "i": options.title
        },
        inputGroupElement,
        {
          "n": "datalist",
          "id": `${randomName}-datalist`,
          "i": dataListItems
        }
      ]
    };
  
    var domElement = await jsonRender.render(element, {
      PassthroughExcept: _.define.passthrough.except
    });
  
    domElement.querySelector("input").addEventListener("input", function() {
      // Case-insensitive search and handling 'Other' input
      let searchValue = this.value.toLowerCase().trim();
      if (searchValue === "other") {
        this.setAttribute('data-other', 'true');
      } else {
        this.removeAttribute('data-other');
      }
    });
  
    domElement.querySelector("input").addEventListener("focus", focus);
    domElement.querySelector("input").addEventListener("focusout", focus);
  
    return domElement;
  } module.exports.render = render;
  

// async function render(options) {
//     var randomName = uai.generateRandomID(12);
  
//     var inputElement = {
//       "n": "input",
//       "id": randomName,
//       "class": "form-control",
//       "title": options.title,
//       "list": `${randomName}-datalist`,
//       "mustmatch": options.mustmatch
//     };

//     inputElement = await jsonRender.pass({...options}, {...inputElement}, ["name", "class", "icon", "style", "data", "mustmatch"]);
  
//     var dataListItems = [];
  
//     if (options.includeOther) {
//       dataListItems.push({ "n": "option", "value": "Other" });  // 'Other' option is always first
//     }
  
//     dataListItems = dataListItems.concat(options.data.map(item => ({
//       "n": "option",
//       "value": item
//     })));
  
//     var element = {
//       "n": "div",
//       "name": options.name,
//       "c": "form-group pb-3",
//       "i": [
//         {
//           "n": "label",
//           "for": randomName,
//           "c": "pb-2 fs-4",
//           "i": options.title
//         },
//         inputElement,
//         {
//           "n": "datalist",
//           "id": `${randomName}-datalist`,
//           "i": dataListItems
//         }
//       ]
//     };

  
//     var domElement = await jsonRender.render(element);
  
//     domElement.querySelector("input").addEventListener("input", function() {
//       // Case-insensitive search and handling 'Other' input
//       let searchValue = this.value.toLowerCase().trim();
//       if (searchValue === "other") {
//         this.setAttribute('data-other', 'true');
//       } else {
//         this.removeAttribute('data-other');
//       }
//     });

//     domElement.querySelector("input").addEventListener("focus", focus);
//     domElement.querySelector("input").addEventListener("focusout", focus);
  
//     return domElement;
// } module.exports.render = render;
  

async function focus(event) {
    // console.log("focus", event);
  
  
    //get the parent uae element
    var parent = this.closest("uae");
  
      this.classList.remove("is-invalid");
      this.classList.remove("is-valid");
  
      //if the input is not empty
      if (this.value.trim() != "") {

        var repo = await validate(parent.getAttribute("name"));


        // console.log("Validate", {
        //     name: this.name,
        //     repo: repo
        // });

        //check if it's valid
        if (repo.success) {
          this.classList.add("is-valid");
  
          if (parent) {
            parent.classList.add("is-valid");
          }
  
        } else {
          this.classList.add("is-invalid");
  
          if (parent) {
            parent.classList.add("is-invalid");
          }
  
        }
  
      }
  }


async function validate(name) {
    //get the uam of the name
    var control = document.querySelector(`uae[name="${name}"]`);
    var rtn = await save(name, control, { success: true, errors: [] });
    return rtn;
}

async function save(name, control, repo) {
  var input = control.querySelector("input");
  input.value = input.value.trim();

  var value = input.value;
  if (!(input.checkValidity())) {
    // console.log("Input invalid", input.validationMessage);
    repo.success = false;
    repo.errors.push({
      type: "validation",
      message: input.validationMessage
    });
  }

  if (input.getAttribute("mustmatch") === "true") {

    // console.log("Must match", {
    //   value: value
    // });

    var dataList = control.querySelector("datalist");
    var options = dataList.querySelectorAll("option");
    var found = false;
    for (var i = 0; i < options.length; i++) {

        // console.log("Matching", {
        //     value: value,
        //     option: options[i].value
        // });
    
        if (value === options[i].value) {
            // console.log("Found", {
            //     value: value,
            //     option: options[i].value
            // });
            found = true;
            break;
        }

    //   if (options[i].value.toLowerCase() === value.toLowerCase()) {
    //     found = true;
    //     break;
    //   }
    }

    if (!(found)) {
      repo.success = false;
      repo.errors.push({
        type: "mustmatch",
        message: "The value selected must match one of the options in the list."
      });
    } 
    // else {
    //     console.log("It believes I am found", {
    //         value: value//,
    //         // option: options[i].value
    //     });
    // }

  }


//   repo.success = true;
  repo.data = value;  // Preserve the case if 'Other' is not selected
  return repo;
} module.exports.save = save;

async function load(name, control, data) {
  var input = control.querySelector("input");
  input.value = data;
} module.exports.load = load;

 