/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "ua.element.scripts",
    title: "Universe App Element External Scripts",
    description: "Adds external scripts based. Used in the interface to add external scripts by the element's definition.",
    fields: {
        "src": {
          title: "Source",
          type: "uri",
          description: "The source of the script.",
          required: true
        },
        "type": {
          title: "Type",
          type: "string",
          description: "The type of the script."
        },
          "defer": {
              title: "Defer",
              type: "boolean",
              description: "The defer attribute of the script. Should I render the script after the page is loaded?"
          },
          "async": {
              title: "Async",
              type: "boolean",
              description: "The async attribute of the script. Should I render the script asynchronously while other scripts are loading?"
          },
          "integrity": {
              title: "Integrity",
              type: "string",
              description: "The integrity attribute of the script. A hash of the script to verify it's integrity."
          },
          "crossorigin": {
              title: "Cross Origin",
              type: "crossorigin",
              description: "The crossorigin attribute of the script. How should I handle cross origin requests?"
          },
          "referrerpolicy": {
              title: "Referrer Policy",
              type: "referrerpolicy",
              description: "The referrer policy attribute of the script. How should I handle the referrer policy?"
          },
          "from": {
              title: "From",
              type: "namespace",
              description: "The namespace of the object it came from at registration time."
          },
          "name": {
              title: "Name",
              type: "string",
              description: "The name of the script."
          }
      }
});
// var namespace = module.exports.namespace;

// var define = {
//     namespace: "ua.element.scripts",
//     title: "Universe App Element External Scripts",
//     description: "Adds external scripts based. Used in the interface to add external scripts by the element's definition.",
//     editable: false,
//     fields: {
//       "src": {
//         title: "Source",
//         type: "uri",
//         description: "The source of the script.",
//         required: true
//       },
//       "type": {
//         title: "Type",
//         type: "string",
//         description: "The type of the script."
//       },
//         "defer": {
//             title: "Defer",
//             type: "boolean",
//             description: "The defer attribute of the script. Should I render the script after the page is loaded?"
//         },
//         "async": {
//             title: "Async",
//             type: "boolean",
//             description: "The async attribute of the script. Should I render the script asynchronously while other scripts are loading?"
//         },
//         "integrity": {
//             title: "Integrity",
//             type: "string",
//             description: "The integrity attribute of the script. A hash of the script to verify it's integrity."
//         },
//         "crossorigin": {
//             title: "Cross Origin",
//             type: "crossorigin",
//             description: "The crossorigin attribute of the script. How should I handle cross origin requests?"
//         },
//         "referrerpolicy": {
//             title: "Referrer Policy",
//             type: "referrerpolicy",
//             description: "The referrer policy attribute of the script. How should I handle the referrer policy?"
//         },
//         "from": {
//             title: "From",
//             type: "namespace",
//             description: "The namespace of the object it came from at registration time."
//         },
//         "name": {
//             title: "Name",
//             type: "string",
//             description: "The name of the script."
//         }
//     }
//   }; module.exports.define = define;


/**
 * Creates a script object and adds it to the document.
 * @param {*} options The jsonObject to render.
 * @returns The rendered DOM object.
 */
async function render(options) {

    // console.log(`Processing External Scripts...`, {
    //     options
    // });

    var sc = document.createElement("script");
    sc.src = options.src;

    if ("name" in options) {
        sc.setAttribute("name", options.name);
    }

    if ("type" in options) {
        sc.setAttribute("type", options.type);
    }

    if ("defer" in options) {
        sc.setAttribute("defer", options.defer);
    }

    if ("async" in options) {
        sc.setAttribute("async", options.async);
    }

    if ("integrity" in options) {
        sc.setAttribute("integrity", options.integrity);
    }

    if ("crossorigin" in options) {
        sc.setAttribute("crossorigin", options.crossorigin);
    }

    if ("referrerpolicy" in options) {
        sc.setAttribute("referrerpolicy", options.referrerpolicy);
    }

    if ("from" in options) {
        sc.setAttribute("ua-from", options.from);
    }
    
    // console.log(`Adding External Script...`, {
    //     html: sc.outerHTML
    // });

    return sc;
} module.exports.render = render;