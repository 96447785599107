/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "pages.blog",
    title: "Blog",
    description: "A blog post.",
    fields: {
        title: {
          title: "Title",
          type: "string",
          description: "The title of the blog."
        }, description: {
            title: "Description",
            type: "string",
            description: "The description of the blog."
        }, image: {
            title: "Image",
            type: "uri",
            description: "The image of the blog."
        }, inner: {
            title: "Inner",
            type: "inner",
            description: "The inner content of the blog."
        }, url: {
            title: "URL",
            type: "uri",
            description: "The url of the blog."
        }, author: {
            title: "Author",
            type: "inner",
            namespaces: "pages.blog.author",
            description: "The author of the blog."
        }, on: {
            title: "On",
            type: "inner",
            namespaces: "pages.on",
            description: "The dates of the blog."
        }
      },
});
// var namespace = module.exports.namespace;

var jsonRender = require("../interface/jsonRender.js");
var describeAccountingDate = require("../../../uam/functions/describeAccountingDate.js").function;

// exports.define = {
//   namespace: namespace,
//   fields: {
//     "title": {
//       type: "string",
//       description: "The title of the blog."
//     }, "description": {
//         type: "string",
//         description: "The description of the blog."
//     }, "image": {
//         type: "uri",
//         description: "The image of the blog."
//     }, "inner": {
//         type: "inner",
//         description: "The inner content of the blog."
//     }, "url": {
//         type: "uri",
//         description: "The url of the blog."
//     }, "author": {
//         type: "pages.blog.author",
//         description: "The author of the blog."
//     }, "on": {
//         type: "pages.on",
//         description: "The dates of the blog."
//     }
//   },
//   required: required
// } 

/**
 * Splits a string into two parts, the first word or emoji + first word and the rest of the string.
 * @param {*} inputString The string to split.
 * @returns {Array<string>} The array of the first word or emoji + first word and the rest of the string. ["this", "is an example."]
 */
function splitStringWithEmoji(inputString) {
    // Define a regular expression pattern to match the emoji at the start of the string
    const emojiPattern = /^([\uD800-\uDBFF][\uDC00-\uDFFF]|\uD83C[\uDF00-\uDFFF])/;
  
    // Use the regular expression to split the string
    const matches = inputString.match(emojiPattern);
  
    if (matches) {
      // If an emoji is found at the start, split on the emoji and the first word
      const words = inputString.split(' ');
      return [words[0] + " " + words[1], words.slice(2).join(' ')];
    } else {
      // If no emoji is found, split on the first word
      const words = inputString.split(' ');
      return [words[0], words.slice(1).join(' ')];
    }

} 

/**
 * Truncates a string to the specified maxLength, stripping HTML tags and adding an ellipsis if necessary.
 * @param {*} input The html object
 * @param {*} maxLength  The max length of the string
 * @returns {string} The truncated string
 */
function truncateAndStripHTML(input, maxLength) {
    // console.log("The input is...", JSON.parse(JSON.stringify(input, Object.getOwnPropertyNames(input))));
    // Remove HTML tags while preserving line breaks
    var text = input.textContent;
  
    // Trim the text to the specified maxLength
    if (text.length > maxLength) {
      text = text.substring(0, maxLength);
  
      // Trim to the last space to avoid cutting words in half
      text = text.substring(0, text.lastIndexOf(' '));
  
      //trim any and all multiple spaces
      text = text.replace(/\s\s+/g, ' ');

      // Add an ellipsis
      text += '...';
    }
  
    return text;
  }

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The rendered DOM object.
 */
exports.render = async function (options) {

 //convert options.data from 20231010 to October 10, 2023
 options.on.publishedDescription = describeAccountingDate(options.on.published);

 if (!("url" in options)) {
    console.log("Blog url can not be found.", {
        options: options,
        test: ("url" in options)
    });
    options.url = window.location.href;
 }

 //Get the blog description for email
//  var preRenderedInner = ""
//  try {
//     preRenderedInner = jsonRender.render(options.inner);
//     preRenderedInner = truncateAndStripHTML(preRenderedInner, 512);
//  } catch (error) {
//     preRenderedInner = ""
//  }

//  var preRenderedInner = jsonRender.render(options.inner);

 var meta = {
    "n": "pages.meta",
    "isArticle": true,
    "title": options.title,
    "description": options.description,
    "image": options.image,
    "url": options.url,
    "author": options.author.name,
    "published": options.on.publishedDescription,
    // "keywords": options.keywords,
    "social": {
        "author": {},
        "site": {},
        "app": {}
    }
};

//if modified

//  console.log("The preRenderedInner is...", {
//     preRenderedInner: JSON.stringify(preRenderedInner, null, 4),
//     type: typeof preRenderedInner
//  });

 var mailto = `mailto:Your Friend?subject=${encodeURI(options.title)}&body=You%20have%20to%20check%20this%20article%20out.%0A%20${encodeURI(options.title)}%0ARead%20more%20at:%20${options.url}%20`

 var twitterShareExtra = "";
 var facebookShareExtra = "";
 var linkedinShareExtra = "";

 if ("social" in options.author) {
    if ("twitter" in options.author.social) {
        twitterShareExtra = `&via=${options.author.social.twitter}`;
    }

    if ("hashtag" in options.author.social) {
        twitterShareExtra = twitterShareExtra + `&hashtags=${options.author.social.hashtag}`;
    }

    if ("linkedin" in options.author.social) {
        linkedinShareExtra = `&source=${options.author.social.linkedin}`;
    }

 }

 var share = [
    "Share: ",
    {
        "n": "a",
        "href": `http://www.facebook.com/share.php?u=${encodeURI(options.url)}${facebookShareExtra}`,
        "class": "btn btn-primary btn-hover-white btn-sm",
        "target": "_blank",
        "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
        "title": "Share on Facebook",
        "inner": {
            "n": "i",
            "class": "bi bi-facebook"
        }
    },{
        "n": "a",
        "href": `https://twitter.com/intent/tweet?text=${encodeURI(options.title)}&url=${encodeURI(options.url)}&image=${encodeURI(options.image)}${twitterShareExtra}`,
        "class": "btn btn-primary btn-hover-white btn-sm",
        "target": "_blank",
        "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
        "title": "Share on Twitter",
        "inner": {
            "n": "i",
            "class": "bi bi-twitter-x"
        }
    }, {
        "n": "a",
        "href": `https://linkedin.com/shareArticle?mini=true&url=${encodeURI(options.url)}&title=${encodeURI(options.title)}${linkedinShareExtra}`,
        "class": "btn btn-primary btn-hover-white btn-sm",
        "target": "_blank",
        "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
        "title": "Share on LinkedIn",
        "inner": {
            "n": "i",
            "class": "bi bi-linkedin"
        }
    }, {
        "n": "a",
        "href": mailto,
        "target": "_blank",
        "class": "btn btn-primary btn-hover-white btn-sm",
        "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
        "title": "Share via Email",
        "inner": [
            {
                "n": "i",
                "class": "bi bi-envelope"
            }
        ]
    }, {
        "n": "button",
        "class": "btn btn-info btn-hover-white text-shadow btn-sm",
        "target": "_blank",
        "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
        "title": "Share using Your Favorite App",
        "inner": {
            "n": "i",
            "class": "bi bi-share-fill"
        },
        "onclick": `navigator.share({title: '${santizeStringForShare(options.title)}', text: '${santizeStringForShare(options.description)}', url: '${santizeStringForShare(options.url)}'});`
    }
    // {
    //     "n": "button",
    //     "class": "btn btn-info btn-hover-white btn-sm",
    //     "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
    //     "inner": [
    //         {
    //             "n": "i",
    //             "class": "bi bi-share"
    //         }
    //     ]
    // }, 
    // {
    //     "n": "br"
    // }, {
    //     "n": "a",
    //     "class": "text-info text-decoration-none",
    //     "style": "word-break: break-all;",
    //     "inner": options.url,
    //     "href": options.url
    // }
 ];

 var links = [];

    if ("social" in options.author) {
        if ("twitter" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://twitter.com/${options.author.social.twitter}`,
                "title": `Visit ${options.author.name} on "X"`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-twitter"
                    }
                ]
            });
            // meta.twitter.author = options.author.social.twitter;
            meta.social.author.twitter = options.author.social.twitter;
        }

        if ("facebook" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://facebook.com/${options.author.social.facebook}`,
                "title": `Visit ${options.author.name} at Facebook`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-facebook"
                    }
                ]
            });
            meta.social.author.facebook = options.author.social.facebook;
        }

        if ("instagram" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://instagram.com/${options.author.social.instagram}`,
                "title": `Visit ${options.author.name} on Instagram`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-instagram"
                    }
                ]
            });
            meta.social.author.instagram = options.author.social.instagram;
        }

        if ("linkedin" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://linkedin.com/in/${options.author.social.linkedin}`,
                "title": `Visit ${options.author.name} at LinkedIn`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-linkedin"
                    }
                ]
            });
            meta.social.author.linkedin = options.author.social.linkedin;
        }

        if ("youtube" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://youtube.com/${options.author.social.youtube}`,
                "title": `Visit ${options.author.name} on YouTube`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-youtube"
                    }
                ]
            });
            meta.social.author.youtube = options.author.social.youtube;
        }

        if ("tiktok" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://tiktok.com/${options.author.social.tiktok}`,
                "title": `Visit ${options.author.name} on TikTok`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-tiktok"
                    }
                ]
            });
            meta.social.author.tiktok = options.author.social.tiktok;
        }

        if ("twitch" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://twitch.tv/${options.author.social.twitch}`,
                "title": `Visit ${options.author.name} on Twitch`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-twitch"
                    }
                ]
            });
            meta.social.author.twitch = options.author.social.twitch;
        }

        if ("snapchat" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://snapchat.com/add/${options.author.social.snapchat}`,
                "title": `Send ${options.author.name} a Snap`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-snapchat"
                    }
                ]
            });
            meta.social.author.snapchat = options.author.social.snapchat;
        }

        if ("reddit" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://reddit.com/u/${options.author.social.reddit}`,
                "title": `Visit ${options.author.name} on Reddit`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-reddit"
                    }
                ]
            });
            meta.social.author.reddit = options.author.social.reddit;
        }

        if ("pinterest" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://pinterest.com/${options.author.social.pinterest}`,
                "title": `Visit ${options.author.name} on Pinterest`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-pinterest"
                    }
                ]
            });
            meta.social.author.pinterest = options.author.social.pinterest;
        }

        if ("github" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://github.com/${options.author.social.github}/`,
                "title": `Visit ${options.author.name} on GitHub`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-github"
                    }
                ]
            });
            meta.social.author.github = options.author.social.github;
        }

        if ("tumblr" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://${options.author.social.tumblr}.tumblr.com/`,
                "title": `Visit ${options.author.name} on Tumblr`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-tumblr"
                    }
                ]
            });
            meta.social.author.tumblr = options.author.social.tumblr;
        }

        if ("soundcloud" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://soundcloud.com/${options.author.social.soundcloud}`,
                "title": `Visit ${options.author.name} on Soundcloud`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-soundcloud"
                    }
                ]
            });
            meta.social.author.soundcloud = options.author.social.soundcloud;
        }

        if ("spotify" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://open.spotify.com/user/${options.author.social.spotify}`,
                "title": `Visit ${options.author.name} on Spotify`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-spotify"
                    }
                ]
            });
            meta.social.author.spotify = options.author.social.spotify;
        }

        if ("mixcloud" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://mixcloud.com/${options.author.social.mixcloud}`,
                "title": `Visit ${options.author.name} at Mixcloud`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-mixcloud"
                    }
                ]
            });
            meta.social.author.mixcloud = options.author.social.mixcloud;
        }

        if ("bandcamp" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://${options.author.social.bandcamp}.bandcamp.com/`,
                "title": `Visit ${options.author.name} at Bandcamp`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-bandcamp"
                    }
                ]
            });
            meta.social.author.bandcamp = options.author.social.bandcamp;
        }

        if ("cashapp" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://cash.app/${options.author.social.cashapp}`,
                "title": `Tip ${options.author.name} via Cash App`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-cashapp"
                    }
                ]
            });
            meta.social.author.cashapp = options.author.social.cashapp;
        }

        if ("venmo" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://venmo.com/${options.author.social.venmo}`,
                "title": `Tip ${options.author.name} via Venmo`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-venmo"
                    }
                ]
            });
        }

        if ("paypal" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://paypal.me/${options.author.social.paypal}`,
                "title": `Tip ${options.author.name} via PayPal`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-paypal"
                    }
                ]
            });
        }

        //threads
        if ("threads" in options.author.social) {
            links.push({
                "n": "a",
                "class": "btn btn-info btn-hover-white btn-sm",
                "target": "_blank",
                "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                "href": `https://threads.com/${options.author.social.threads}`,
                "title": `Visit ${options.author.name} on Threads`,
                "inner": [
                    {
                        "n": "i",
                        "class": "bi bi-threads"
                    }
                ]
            });
        }

        //for each website in wesbites array [name, url]
        if ("links" in options.author) {
            for (var i = 0; i < options.author.links.length; i++) {
                links.push({
                    "n": "a",
                    "class": "btn btn-info btn-hover-white btn-sm",
                    "target": "_blank",
                    "style": "height:2.5rem; padding:.2rem; margin:.2rem;",
                    "title": options.author.links[i].name,
                    "href": options.author.links[i].url,
                    "inner": [
                        {
                            "n": "i",
                            "class": "bi bi-globe"
                        }
                    ]
                });
            }
        }
    }


    var author = [{
        "n": "h5",
        "inner": options.author.name
        }, {
                "n": "p",
                "class": "fs-6",
                "inner": links
        }];

    //split options.title into two parts the first word or the emohi and the first word and others

    // var title = [{
    //     "n": "h1",
    //     "class": "text-start text-uppercase text-primary d-lg-inline-block d-none",
    //     "style": "font-size: 3em; font-weight:800; line-height: 1.5em;",
    //     "inner": options.title
    // }, {
    //     "n": "h1",
    //     "class": "text-start text-uppercase text-primary d-inline-block d-lg-none",
    //     "style": "font-weight:800; line-height: 1.5em;",
    //     "inner": options.title
    // }];
    
    // title = splitStringWithEmoji(options.title);
    // console.info("title", {
    //     title: title,
    //     length: title.length
    // });

    // if (title[0].length > 1) {

    //     title = {
    //         "n": "h1",
    //         "class": "text-start text-uppercase",
    //         "style": "font-size: 2.5em;font-weight:800;line-height: 1.5em;",
    //         "inner": [
    //             {
    //                 "n": "div",
    //                 "class": "d-block",
    //                 "style": "font-size: 4rem; line-height: 2.5em;",
    //                 "inner": title[0]
    //             },
    //             title[1]
    //         ]
    //     };

    // } else {

    //     title = {
    //         "n": "h1",
    //         "class": "text-start text-uppercase",
    //         "style": "font-size: 2.5em;font-weight:800;line-height: 1.5em;",
    //         "inner": [
    //             {
    //                 "n": "div",
    //                 "class": "d-block",
    //                 "style": "font-size: 4rem line-height: 2.5em;",
    //                 "inner": title[0]
    //             }
    //         ]
    //     };

    // }

 var blog = [
    {
        "n": "div",
        "class": "d-flex",
        "inner": [
            {
                "n": "div",
                "class": "col-4 col-md-4 col-sm-12 d-none d-md-block",
                "inner": [
                    {
                        "n": "img",
                        "class": "image-fill image-shadow",
                        "src": options.author.image
                    }, {
                        "n": "p",
                        "class": "p-1",
                        "inner": share
                    }, {
                        "n": "div",
                        "style": "padding-top:0rem;",
                        "inner": [
                            {
                                "n": "div",
                                "style": "border-top: .5rem #fff solid;",
                                "class": "w-50"
                            }
                        ]
                    }, {
                        "n": "div",
                        "class": "p-3",
                        "inner": [
                            {
                                "n": "p",
                                "inner": options.on.publishedDescription
                            }, author
                        ]
                    }
                ]
            }, {
                "n": "div",
                "class": "col-lg-8 col-md-8 col-sm-12 bg-white text-black",
                "inner": [{
                    "n": "div",
                    "class": "d-block align-items-center p-3 text-shadow box-shadow tiny-outline",
                    "style": `background: url('${options.image}') no-repeat center center;background-size: cover;padding-bottom: 20rem !important;`,
                    "i": [
                      {
                        "n": "h1",
                        "class": "text-start text-uppercase text-white break-letter d-none d-lg-block",
                        "style": "font-size: 3em; font-weight:800; line-height: 1.5em;",
                        "inner": [{
                          "n": "span",
                          "class": "bg-75-black",
                          "i": options.title
                        }]
                      }, {
                        "n": "h1",
                        "class": "text-start text-uppercase text-white break-letter d-block d-lg-none",
                        "style": "font-size: 2.2em; font-weight:800; line-height: 1.5em;",
                        "inner": [{
                          "n": "span",
                          "class": "bg-75-black",
                          "i": options.title
                        }]
                      },
                      {
                        "n": "div",
                        "style": "padding-top:0rem; padding-bottom: 4rem;",
                        "inner": [
                          {
                            "n": "div",
                            "style": "border-top: .5rem var(--bs-blue) solid;",
                            "class": "w-50",
                          }
                        ]
                      },
                    ]
                  }, {
                    "n": "div",
                    "class": "d-flex d-md-none bg-50-success tiny-outline box-shadow",
                    "inner": [
                        {
                            "n": "div",
                            "class": "col-4 box-shadow",
                            "style": `background: url(${options.author.image}) no-repeat top center; background-size: cover;`
                        }, {
                            "n": "div",
                            "class": "col-8",
                            "inner": [
                                {
                                    "n": "div",
                                    "style": "padding-left:1rem; padding-top:1rem;",
                                    "inner": [
                                        {
                                            "n": "p",
                                            "inner": share
                                        }
                                    ]
                                }, {
                                    "n": "div",
                                    "style": "padding-top:0rem;",
                                    "inner": [
                                        {
                                            "n": "div",
                                            "style": "border-top: .2rem var(--bs-blue) solid;",
                                            "class": "w-50",
                                            "inner": []
                                        }
                                    ]
                                }, {
                                    "n": "div",
                                    "style": "padding-left:1rem;",
                                    "inner": [
                                        {
                                            "n": "p",
                                            "inner": `Published on ${options.on.publishedDescription}`
                                        }, author
                                    ]
                                }
                            ]
                        }
                    ]
                },  {
                        "n": "article",
                        "class": "p-4",
                        "inner": [
                            // {
                            //     "n": "blockquote",
                            //     "class": "blockquote text-center text-muted",
                            //     "i": {
                            //         n: "p",
                            //         c: "p-2",
                            //         inner: options.description
                            //     }
                            // },
                            ...options.inner, {
                                "n": "img",
                                "src": options.image,
                                "class": "image-fill image-shadow"
                            }
                        ]
                    }
                ]
            }
        ]
    }
 ];


//  if (!"keywords" in options) {

//     options.keywords = "";
//  } else {
//     //if keywords is an array combine it to a string ", "
//     if (Array.isArray(options.keywords)) {
//         options.keywords = options.keywords.join(", ");
//     }

//  }

 //Let's build meta tags


  return await jsonRender.render([
    meta, ...blog
  ], {
    PassthroughOptions: options
  });
  
};


/**
 * Santizes a string about to sent to navigator.share API
 * If string is undefined, null, or empty, return empty string.
 * @param {*} string 
 */
function santizeStringForShare(string) {
    if (!string) {
        return "";
    }
    // console.info("This is the string...", string);
    //change single quotes to \' for sharing
    return string.replace(/'/g, "\\'");
}