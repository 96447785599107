/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */

var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.select.group",
  title: "Radio Button Group",
  description: "A group of radio buttons with optional icons or images, and additional classes.",
  control: true,
  prevalidate: true,
  supportServer: false,
  fields: {
    "name": { 
      title: "Name",
      type: "string",
      description: "The name of the control, shared by all radio buttons.",
      required: true
    },
    "title": {
      title: "Title",
      type: "string",
      description: "The title label of the radio button group.",
      required: true
    },
    "inner": {
      title: "Inner",
      type: "array",
      description: "The list of options, each with title, value, icon, image, and class, checked.",
      required: true
    },
    "required": {
      title: "Required",
      type: "boolean",
      description: "Whether the radio group is required.",
      required: false,
      addtouae: true
    },
    "description": {
      title: "Description",
        type: "inner",
        description: "The helpful text at the bottom of the control."
    }, "icon": {
      title: "Icon",
      type: "string",
      description: "The icon of the control.",
      required: false
    }
  }
});

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");

// Define the component metadata and configuration
// var define = {
//   namespace: "ua.select.group",
//   title: "Radio Button Group",
//   description: "A group of radio buttons with optional icons or images, and additional classes.",
//   control: true,
//   prevalidate: true,
//   supportServer: false,
//   fields: {
//     "name": {  
//       type: "string",
//       description: "The name of the control, shared by all radio buttons.",
//       required: true
//     },
//     "title": {
//       type: "string",
//       description: "The title label of the radio button group.",
//       required: true
//     },
//     "inner": {
//       type: "array",
//       description: "The list of options, each with title, value, icon, image, and class, checked.",
//       required: true
//     },
//     "required": {
//       type: "boolean",
//       description: "Whether the radio group is required.",
//       required: false,
//       addtouae: true
//     },
//     "description": {
//         type: "inner",
//         description: "The helpful text at the bottom of the control."
//     }, "icon": {
//       type: "string",
//       description: "The icon of the control.",
//       required: false
//     }
//   }
// };
// module.exports.define = define;

// Render function to create the radio button group
async function render(options) {
    var randomName = uai.generateRandomID(12);
    var radioButtons = options.inner.map((option, index) => {
        var id = `${randomName}-${index}`;
        var radioButton = {
            "n": "input",
            "type": "radio",
            "c": "btn-check",
            "name": options.name,
            "id": id,
            "autocomplete": "off",
            "value": option.value
        };
        var labelClass = `btn ${option.class || 'btn-outline-primary'}`;

        var labelContent = [{"n": "div", "i": option.title}];
        if (option.icon) {
            labelContent.unshift({"n": "i", "class": option.icon});
        }
        if (option.image) {
            labelContent.unshift({"n": "img", "src": option.image, "alt": option.title});
        }

        var label = {
            "n": "label",
            "class": labelClass,
            "for": id,
            "i": labelContent
        };

        //if checked is in the option and it is set to true
        if (option.checked) {
            radioButton.checked = true;
        }

        return [radioButton, label];
    });

    var element = {
        "n": "div",
        "name": options.name,
        "c": "form-group pb-3",
        "i": [
            {
                "n": "label",
                "class": "pb-2 fs-4",
                "i": [{"n": "text", "i": " " + options.title}]
            }, {
              "n": "div",
              "c": "input-group btn-group",
              "i": [...radioButtons]
            }
            // ...radioButtons.flat()
        ]
    };

    if ("class" in options) {
        element.c += " " + options.class;
    } 

    //add the icon
    if (options.icon) {
        element.i[0].i.unshift({ "n": "i", "class": options.icon });
    }

    // //description
    // if (options.description) {
    //     element.i.push({
    //         "n": "div",
    //         "id": `${randomName}-description`,
    //         "c": "form-text",
    //         "i": options.description
    //     });
    // }


    if (options.required) {
      element.required = true;
    }

    if ("description" in options) {
        element.i.push({
          "n": "div",
          "id": `${randomName}-description`,
          "c": "form-text",
          "i": options.description
        });
      }

    var domElement = await jsonRender.render(element);
    // domElement.addEventListener("onclick", function (event) {
    //     //get the uae element plceholder
    //     var uaeElement = this.closest("uae");
    //     console.info(
    //         "Radio Group Click",
    //         { uaeElement }
    //     );
    //     save(options.name, uaeElement, {});

    // });

    //for each button, add an event listener
    domElement.querySelectorAll('input[type="radio"]').forEach(input => {
      // console.log("Radio Group Input", { input });
        input.addEventListener("change", function (event) {
            //get the uae element plceholder
            var uaeElement = this.closest("uae");
            // console.info(
            //     "Radio Group Change",
            //     { uaeElement }
            // );
            save(options.name, uaeElement, {});
        });
    });

    return domElement;
}
module.exports.render = render;

// Save function to process the selected radio button
async function save(name, control, repo) {
    var inputs = control.querySelectorAll('input[type="radio"]');
    var selected = Array.from(inputs).find(input => input.checked);

    //does control's first child have the attribute required?
    var required = false;

    if (control.hasAttribute("required")) {
        required = control.getAttribute("required");
    }

    control.classList.remove("is-invalid");
    control.classList.remove("is-valid");


    if ((!(selected)) && (required)) {
        repo.success = false;
        repo.errors.push({
            type: "validation",
            message: "Selection is required."
        });

        //is invalid
        control.classList.add("is-invalid");

    } else {
      
        repo.success = true;
        repo.data = selected ? selected.value : null;
        control.classList.add("is-valid");
    }

    return repo;
}
module.exports.save = save;

// Load function to set the selected radio button
async function load(name, control, data) {
    var inputs = control.querySelectorAll('input[type="radio"]');
    inputs.forEach(input => {
        input.checked = input.value === data;
    });
}
module.exports.load = load;
