/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.appbar",
  title: "Side Nav Bar",
  description: "A beutiful side bar for your app. Only displayed on medium to larger devices, not on mobile.",
  fields: {
    "items": {
      title: "Items",
      type: "inner",
      namespaces: ["ua.navbar.items"],
      description: "The items to add to the navbar.",
      default: []
    }, "inner": {
      title: "Inner",
      type: "inner",
      description: "The inner content of the application bar.",
      required: true
    },
    "src": {
      title: "Source",
      type: "string",
      description: "The source file to retrieve."
    },
    "hideSpacer": {
      title: "Hide Spacer",
      type: "boolean",
      description: "Hide the spacer.",
      default: false
    }
  },
});
var namespace = module.exports.namespace;

// var namespace = "ua.appbar";
// exports.namespace = namespace;

// var jsonRender = require("../interface/jsonRender.js");
// var events = require("../../../uam/events.js");
var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");

// var uaNavBarItem = require("./ua.navbar.item.js");

// var required = ["inner"]; 
// exports.required = required;

// exports.define = {
//   namespace: namespace,
//   title: "Application Bar",
//   description: "A beutiful side bar for your app. Only displayed on medium to larger devices, not on mobile.",
//   fields: {
//     "items": {
//       type: "inner",
//       namespaces: ["ua.navbar.items"],
//       description: "The items to add to the navbar.",
//       default: []
//     }, "inner": {
//       type: "inner",
//       description: "The inner content of the application bar.",
//       required: true
//     },
//     "src": {
//       type: "string",
//       description: "The source file to retrieve."
//     },
//     "hideSpacer": {
//       type: "boolean",
//       description: "Hide the spacer.",
//       default: false
//     }
//   },
//   required: required
// } 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  //is there a src file?
  if ("src" in options) {
    var file = await uai.getObject(options.src, "json");
    // console.log("File", file);
    if (file) {
      // if there are items, append them from the src
        options.items = [...options.items, ...file]
    }
  }

  var appBar = {
    n: "div",
    c: "ua-app-bar row",
    i: [{
      "n": "div",
      "c": "ua-app-bar-items col-3 p-0 m-0 d-none d-md-block position-sticky top-0 vh-100 mt-5",
      "i": [...options.items]
    }, {
      "n": "div",
      "c": "ua-app-bar-inner col-12 col-md-9",
      "i": options.inner
    }]
  }

  if (!(options.hideSpacer)) {
    appBar.i[0].i.unshift({
      "n": "div",
      "c": "pt-5",
      "i": "&nbsp;"
    });
  }

  if ("class" in options) {
    appBar.c += " " + options.class;
  }

  return appBar;

}

/** 
 * 
 * <nav id="navMain" class="navbar navbar-expand navbar-dark bg-blur-dark fixed-top" aria-label="Navigation">
        <div class="container">
<ul class="navbar-nav">
    <a class="navbar-brand mybrand d-inline-block" style="width:4rem; height:4rem;" href="/" title="home"></a>
</ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#home">Home</a>
                </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#developers">Developers</a>
              </li>
<li class="nav-item">
    
</li>
            </ul>
<ul class="navbar-nav">
    <li class="nav-item"><button class="btn btn-sm btn-info btn-hover-white text-uppercase ls-1" type="submit">Join the Beta</button></li>
</ul>
            
            
            
            
        </div>
      </nav>
*/
