/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.navbar.item",
  title: "Enhanced Navigation Bar Item",
  description: "A navigation item for the enhanced navigation bar.",
  fields: {
    src: {
      title: "Destination",
      type: "uri",
      description: "The page or link to navigate to.",
      required: true
    },
    type: {
      title: "Type",
      type: "string",
      description: "The type of link to display.",
      list: {
        must: {
          "text": "Show a link as text only.",
          "icon": "Show a link as an icon with text.",
          "icon-only": "Show a link as an icon only.",
          "image": "Show a link as an image with text.",
          "big-image": "Show a link as text on the background of the image."
        }
      },
      default: "text"
    }, 
    // items: {
    //   title: "Menu Items",
    //   type: "inner",
    //   namespaces: "ua.navbar.items",
    //   description: "The menu items to display on click."
    // },
    title: {
      title: "Title",
      type: "string",
      description: "The title of the link."
    },
    icon: {
      title: "Icon",
      type: "icon",
      description: "The icon to use for the link."
    },
    image: {
      title: "Image",
      type: "uri",
      description: "The image to use for the link."
    },
    hideText: {
      title: "Hide Text",
      type: "boolean",
      description: "If the text should be hidden.",
      default: false
    },
    quickName: {
      title: "Quick Name",
      type: "string",
      description: "The quick name of the link to display on the navbar when the menu is fully collapsed."
    },
    // pin: {
    //   title: "Pin",
    //   type: "boolean",
    //   description: "If the link should be pinned to the top of the expanded navbar menu.",
    //   default: false
    // },
    // ignorePin: {
    //   title: "Ignore Pin",
    //   type: "boolean",
    //   description: "Used by the enhanced navbar (ua.navbar) to ignore a pin when the maximum number of pinned items is reached.",
    //   default: false
    // },
    target: {
      title: "Target",
      description: "The target of the link.",
      type: "list",
      items: {
        "_self": "Open the link in the same tab.",
        "_blank": "Open the link in a new tab.",
        "_parent": "Open the link in the parent frame.",
        "_top": "Open the link in the full body of the window."
      },
    },
    active: {
      title: "Active",
      type: "boolean",
      description: "If the link should be active.",
      default: false
    },
    navBarClass: {
      title: "Navbar Class",
      type: "class",
      description: "Modifies the display of the item in the navbar only."
    },
    navItemClass: {
      title: "Nav Item Class",
      type: "class",
      description: "Modifies the display of the line item (LI) of the navbar. Best place to use mobile hiding features."
    },
    menu: {
      title: "Menu",
      type: "object",
      description: "The menu to display when the item is clicked.",
      fields: {
        inner: {
          title: "Inner",
          type: "inner",
          description: "The inner items of the menu."
        },
        class: {
          title: "Class",
          type: "class",
          description: "The class to apply to the menu."
        },
        type: {
          title: "Type",
          type: "string",
          description: "The type of menu to display.",
          list: {
            must: ["halfscreen"]
          },
          default: "halfscreen"
        }
      }
    }
  },
  errors: {
    invalidMenu: {
      title: "Invalid Menu",
      description: "The menu provided is not a valid object."
    }
  }
});

// var namespace = "ua.navbar.item";
// exports.namespace = namespace;

// var jsonRender = require("../interface/jsonRender.js");
var events = require("../../../uam/events.js");
// var uai = require("../interface/interface.js");
// var uaNavBarMenu = require("./ua.navbar.menu.js");
var uae = require("../interface/element.js");

// var required = ["inner"]; 
// exports.required = required;

// exports.define = {
//   namespace: namespace,
//   title: "Enhanced Navigation Bar",
//   description: "A beutiful navigation bar for your app. If you use the fixed-top class, it will automatically add margin to the top of the body (this may overide other settings) so it doesn't overlap the content.",
//   fields: {
//     src: {
//       type: "uri",
//       description: "The page or link to navigate to.",
//       required: true
//     },
//     type: {
//       type: "list",
//       items: {
//         "text": "Show a link as text only.",
//         "icon": "Show a link as an icon with text.",
//         "icon-only": "Show a link as an icon only.",
//         "image": "Show a link as an image with text.",
//         "big-image": "Show a link as text on the background of the image."
//       },
//       default: "text"
//     }, "items": {
//       "type": "list of namespaces",
//       "namespaces": ["ua.navbar.items"],
//       "description": "The menu items to display on click."
//     },
//     title: {
//       type: "string",
//       description: "The title of the link."
//     },
//     icon: {
//       type: "icon",
//       description: "The icon to use for the link."
//     },
//     image: {
//       type: "uri",
//       description: "The image to use for the link."
//     },
//     hideText: {
//       type: "boolean",
//       description: "If the text should be hidden.",
//       default: false
//     },
//     quickName: {
//       type: "string",
//       description: "The quick name of the link to display on the navbar when the menu is fully collapsed."
//     },
//     pin: {
//       type: "boolean",
//       description: "If the link should be pinned to the top of the expanded navbar menu.",
//       default: false
//     },
//     ignorePin: {
//       type: "boolean",
//       description: "Used by the enhanced navbar (ua.navbar) to ignore a pin when the maximum number of pinned items is reached.",
//       default: false
//     },
//     target: {
//       type: "list",
//       items: {
//         "_self": "Open the link in the same tab.",
//         "_blank": "Open the link in a new tab.",
//         "_parent": "Open the link in the parent frame.",
//         "_top": "Open the link in the full body of the window."
//       },
//     },
//     active: {
//       type: "boolean",
//       description: "If the link should be active.",
//       default: false
//     },
//     navBarClass: {
//       type: "class",
//       description: "Modifies the display of the item in the navbar only."
//     },
//     navItemClass: {
//       type: "class",
//       description: "Modifies the display of the line item (LI) of the navbar. Best place to use mobile hiding features."
//     },
//     menu: {
//       type: "object",
//       description: "The menu to display when the item is clicked.",
//       fields: {
//         inner: {
//           type: "inner",
//           description: "The inner items of the menu."
//         },
//         class: {
//           type: "class",
//           description: "The class to apply to the menu."
//         },
//         type: {
//           "type": "list",
//           "items": {
//             "halfscreen": "The menu is a half screen menu."
//           },
//           default: "halfscreen"
//         }
//       }
//     }
//   },
//   errors: {
//     invalidMenu: {
//       title: "Invalid Menu",
//       description: "The menu provided is not a valid object."
//     }
//   },
//   // supportServer: false, 
//   required: required
// };

// var errors = _.errors;

var navbarItemClass = "ua-navbar-item";
var bigImageAnchorColClass = "bg-blur-og-50-black-hover";
var bigImageAnchorClass = "bg-blur-black p-3 rounded ";
var bigImageClass = `${navbarItemClass} ua-navbar-big-image`;
// var imageAnchorClass = " bg-blur-black";

// var pinnedColPerRow = 3;
 
/**
 * Returns a simple version of the navigation item, using just an a tag.
 * @param {*} options The options to render the simple navigation item.
 * @returns A ua/json object - requires rendering.
 */
async function GetSimple(options) {

  // var anchor = {
  //   n: "a",
  //   c: "nav-link " + "ua-nav-item-" + options.type,
  //   href: options.src,
  //   i: options.title
  // }

  // if ("class" in options) {
  //   anchor.c += " " + options.class;
  // }

  // if (("hideText" in options && options.hideText) || options.type == "icon-only") {
  //   anchor.i = "";
  // }

  // if ("target" in options) {
  //   anchor.target = options.target;
  //   // console.log("Adding Target...");
  // }

  // // get icon or image
  // var img = false;

  // if ((options.type == "icon") || (options.type == "icon-only")) {
  //   img = {
  //     n: "i",
  //     c: "bi bi-" + options.icon
  //   }
  // }

  // if (options.type == "image") {
  //   img = {
  //     n: "img",
  //     c: "img-fluid rounded",
  //     src: options.image
  //   }
  // }

  // if (img) {
  //   anchor.i = [img, " " + anchor.i];
  // }

  var hideText = false;

  if ("hideText" in options && options.hideText) {
    hideText = true;
  }

  if (options.type == "icon-only") {
    hideText = true;
  }


  var itemEle = [];
  if ("icon" in options) {
    itemEle.push({
      n: "i",
      c: `bi bi-${options.icon}`
    });

    //add the extra space for display purposes
    if (!(hideText)) itemEle.push("");
  }

  if (!(hideText)) {
    if ("quickName" in options) {
      itemEle.push(options.quickName);
    } else {
      itemEle.push(options.title);
    }
  }

  var a =  {
    n: "a",
    c: "nav-link",
    t: options.title,
    href: options.src,
    i: itemEle
  };

  if ("target" in options) {
    a.target = options.target;
  }

  // if (!(options.type == "compact")) {
    if ("navBarClass" in options) {
      a.c += ` ${options.navBarClass}`;
    }
  // }

  return a;

} module.exports.GetSimple = GetSimple;

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) { 

  var holder = {};

  if (!("type" in options)) {
    //if image is defined
    if ("image" in options) {
      options.type = "image";
    } else if ("icon" in options) {
      options.type = "icon";
    } else {
      options.type = "text";
    }
  }

  if (options.type == "icon-only") {
    options.hideText = true;
    options.type = "icon";
  }

  var anchor = {
    n: "a",
    c: "text-decoration-none text-decoration-underline-hover text-white",
    href: options.src, 
    title: options.title,
    i: " " + options.title
  }

  if ("class" in options) {
    anchor.c += " " + options.class;
  }

  if ("hideText" in options && options.hideText) {
    anchor.i = "";
  }

  if ("target" in options) {
    anchor.target = options.target;
    // console.log("Adding Target...");
  }
 
  var anchorCol = {
    n: "div",
    c: "col text-start",
    i: [anchor]
  }

  // if (("pin" in options && options.pin)) {
  //   anchorCol.c += " pt-3";
  // } else {
  //   anchorCol.c += " text-start p-3";
  // }

  var img = {};


  var imageCol = {
    n: "div",
    c: "col",
    i: [
       
    ]
  };

  // if (!("pin" in options) || !options.pin) {
    imageCol.c = "col-3";
  // }

  if (options.type == "text") {
    imageCol = "";
  } else if (options.type == "icon") {

//     <svg class="bi" width="32" height="32" fill="currentColor">
//   <use xlink:href="bootstrap-icons.svg#heart-fill"/>
// </svg> 
    //needs fiding
// img = {
    //   n: "svg",
    //   c: "img-fluid",
    //   fill: "currentColor", 
    //   i: [
    //     {
    //       n: "use",
    //       "xlink:href": uai.resolveURL(`@!/uat/bi/${options.icon}.svg`)
    //     }
    //   ]
    // }

    img  = {
      n: "i",
      c: "fs-3 bi bi-" + options.icon,
      // src: uai.resolveURL(`@!/uat/bi/${options.icon}.svg`),
      // style: "filter: brightness(0) invert(1); width: 1.5rem; height: 1.5rem;"
    }

  } else if (options.type == "image") {
    img = {
      n: "img",
      c: "img-fluid rounded",
      src: options.image
    }
  }

  if (!(imageCol == "")) {
    imageCol.i.push(img);
  }

  
  if (options.type == "big-image") { 
    anchorCol.c += " " + bigImageAnchorColClass
    anchorCol.i[0].c += " " + bigImageAnchorClass;
    holder = {
      n: "div",
      c: bigImageClass,
      style: `background-image: url(${options.image}); background-size: cover; background-position: center; background-repeat: no-repeat;`,
      i: [
        anchorCol]
    }
  } else {
    holder = {
      n: "div",
      c: navbarItemClass + " p-2",
      i: [
        imageCol,
        anchorCol]
    }  
  }

  // if ("pin" in options && options.pin) {
  //   holder.c += " ua-navbar-item-pin";
  // }

  if ("menu" in options) {

    if (!(typeof options.menu == "object")) {
      _.errors.invalidMenu.throw();
    }

    // var menu = await uaNavBarMenu.render(options.menu, );
    var menu = await uae.render({
      ...options.menu,
      namespace: "ua.navbar.menu"
    }, _.namespace , { 
      doNotPrerender: true
    });

    holder["ua-navbar-menu"] = menu.id;
    holder.i.push(menu);
    holder.c += " ua-navbar-item-controls-menu";
  }

  return holder;

}

//when the page has completed loading highlight the anchor tag with the window.location 
// document.addEventListener("DOMContentLoaded", function (event) {
events.on("interface.afterrender", async function() {
  //get the window.location

  try {
    var url = window.location.href;

    // var hasLink = false;
    //clear previous nav-link active classes
    var activeLinks = document.querySelectorAll(".nav-link.active");
    activeLinks.forEach(link => {
      link.classList.remove("active");
    });

    var anchor = FindNavLinkByHREF(url);

    //for each add active
    anchor.forEach(a => {
      a.classList.add("active");
    });

    // if (anchor) {
    //   anchor.classList.add("active");
    // }

  } catch (error) {
    
  }
  

});

/**
 * Returns all nav-link with the href provided.
 * @param {*} href The href to search for.
 * @returns The nav-link with the href, or false if it does not exist.
 */
function FindNavLinkByHREF(href) {
  
  //try to find via href, if not remove the ? and everything after and try again, if still can't find it remove the # and everything after and try again

  var anchors = FindNavLinkByHREF_Part(href);
  if (anchors) {
    return anchors;
  }

  //does the ? exist?
  var index = href.indexOf("?");
  if (index > -1) {
    href = href.substring(0, index);
    anchors = FindNavLinkByHREF_Part(href);
    if (anchors) {
      return anchors;
    }
  }

  //does the # exist?
  index = href.indexOf("#");
  if (index > -1) {
    href = href.substring(0, index);
    anchors = FindNavLinkByHREF_Part(href);
    if (anchors) {
      return anchors;
    }
  }

  //does it end on a /?
  if (href.endsWith("/")) {
    href = href.substring(0, href.length - 1);
    anchors = FindNavLinkByHREF_Part(href);
    if (anchors) {
      return anchors;
    }
  }

  //we've looked for everyhting
  return false;

}

/**
 * Returns all nav-link with the href provided.
 * @param {*} href The href to search for.
 * @returns The nav-link with the href, or false if it does not exist.
 */
function FindNavLinkByHREF_Part(href) {

  var anchors = [];

  var anchor = document.querySelector(`a[href*="${href}"]`);
  if (anchor) {
    if (anchor.classList.contains("nav-link")) {
      anchors.push(anchor);
    }
  }

  href = href.split("#").pop();
  anchor = document.querySelector(`a[href*="#${href}"]`);
  if (anchor) {
    if (anchor.classList.contains("nav-link")) {
      anchors.push(anchor);
    }
  }

  return anchors;
}