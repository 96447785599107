/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.navbar.menu",
  title: "Enhanced Navigation Menu",
  description: "A menu for the navbar that can be toggled on and off.",
  fields: {
    menuIconClass: {
      type: "class",
      title: "Menu Icon Class",
      description: "The class to use for the menu icon.",
    }, 
    type: {
      title: "Menu Type",
      type: "string",
      description: "The type of menu to displau.",
      default: "mobile",
      items: ["mobile", "halfscreen"]
    },
    items: {
      title: "Menu Items",
      type: "ua.navbar.item",
      title: "Navigation Items",
      description: "The items to display in the navbar menu."
    },
    inner: {
      type: "inner",
      title: "Inner Content",
      description: "The inner content to display in the menu."
    }
  },
  errors: {
    itemAlreadyRegistered: {
      title: "Menu Is Already Registered",
      description: "A menu with the supplied hash is already registered."
    }
  },
});

// var namespace = "ua.navbar.menu";
// exports.namespace = namespace;

// var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
var uae = require("../interface/element.js");
var events = require("../../../uam/events.js");
var generateHash = require("../../../uam/functions/generateHash.js").function;

// var required = ["inner"]; 
// exports.required = required;

// exports.define = {
  
// }

var errors = uae.getErrors(exports.define);
module.exports.errors = errors;

var registeredMenus = {};
window.getRegisteredMenus = () => {
  return registeredMenus;
}; 

/**
 * Renders a navbar menu.
 * @param {*} options The options from the define object.
 * @returns ua/json 
 */
exports.render = async function (options) { 

    var myid = "uaNavBarMenu" + uai.generateRandomID(12);
    // var items = options.items;

    // var menuItems = [];
    var pinned = [];
    var unpinned = [];

    //insert a copy of the special border before every unpinned item

    if ("items" in options) {
      for (var i = 0; i < options.items.length; i++) {
        var item = options.items[i];
        if ("pinned" in item) {
          if (item.pinned) {
            pinned.push(item);
          } else {
            unpinned.push(item);
          }
          // pinned.push(item);
        } else {
          unpinned.push(item);
        }
      }
    }
  
    var menuItemsArr = [];

    if (pinned.length > 0) {
      menuItemsArr.push({
        n: "div",
        c: "pinned",
        i: pinned
      });
    }

    if (unpinned.length > 0) {
      menuItemsArr.push({
        n: "div",
        c: "unpinned",
        i: unpinned
      });
    }

    if ("inner" in options) {
      menuItemsArr.push({
        n: "div",
        c: "inner",
        i: options.inner
      });
    }

    //build the menu top bar
    var menuTop = {
      n: "div",
      c: "ua-navbar-menu-top row",
      i: [
        {
          n: "button",
          c: "ua-navbar-menu-close col-2 btn",
          "ua-navbar-menu": myid,
          i: returnIcon
        }, {
          n: "div",
          c: "col navbar-menu-breadcrumb",
        }
      ]
    };

    menuItemsArr.unshift(menuTop);

    //work with meny types
    var menuClass = "";
    switch (options.type) {
      case "mobile":
        menuClass = "ua-navbar-menu ua-navbar-menu-mobile";
        // menuItemsArr.unshift(menuTop);
        // menuItemsArr.push(close);
        break;
      case "halfscreen":
        menuClass = "ua-navbar-menu ua-navbar-menu-halfscreen";
        //doesn't need a close
        break;
    }
  
    var menu = {
      n: "nav",
      c: menuClass,
      type: options.type, 
      id: myid,
      i: [{ 
        n: "div",
        c: "holster",
        i: [menuItemsArr]
      }]
    }; 

    var findMenu = FindMenuByHash(generateHash(menu));
    if (findMenu) {
      return {
        n: "ua.none",
        id: findMenu.id
      };
    }

    var regMenu = RegisteredMenu(menu);
    menu.hash = regMenu.hash;

    return menu; 

    // var renderedMenu = await jsonRender.render(menu);
    // document.body.appendChild(renderedMenu);
  
    // return {
    //   n: "ua.none",
    //   id: myid
    // };

};


function RegisteredMenu(menu) {
  var hash = generateHash(menu);
  var id = menu.id;
  return RegisteredMenuById(id, hash);
}

function RegisteredMenuById(id, hash) {

  if (FindMenuByHash(hash)) {
    return FindMenuByHash(hash);
  }

  registeredMenus[id] = {
    id: id,
    hash: hash
  };

  return registeredMenus[id];
} module.exports.RegisteredMenuById = RegisteredMenuById;

function FindMenuByHash(hash) {
  for (var key in registeredMenus) {
    if (registeredMenus[key].hash == hash) {
      return registeredMenus[key];
    }
  }
  return false;
} module.exports.FindMenuByHash = FindMenuByHash;

/**
 * Adds a potential close button to the body.
 */

// var closeIcon = {
//   n: "i",
//   c: "bi bi-x-circle",
//   i: ""
// };

var returnIcon = {
  n: "i",
  c: "bi bi-arrow-90deg-left",
  i: ""
}

var closeBtn = false;
var menuAddTop = -10;

events.on("interface.afterrender", async function() {

  //Register Pre-Rendered Navbar Menus
  var menus = document.querySelectorAll(".ua-navbar-menu");

  menus.forEach(menu => {
    try {
      var hash = menu.getAttribute("hash");
      var id = menu.id;
      RegisteredMenuById(id, hash);

      try {
        //adjust the hallf menues to appear underneath the navigation
        adjustHalfMenuPosition(menu);
      } catch (error) {
        
      }

    } catch (error) {

      //the menu is already added
      if (!(errors.itemAlreadyRegistered.is(error))) {
        console.error(error);
      }

    }

  });


  //move all menus from where they are to the body
  menus.forEach(menu => {
    document.body.appendChild(menu);
  });


  // await updateFixedNavBarPosition();

  //Set Close Button

  // closeBtn = document.getElementById("ua-navbar-menu-close");

  // if (!(closeBtn)) {

  //   var close = {
  //     "n": "button",
  //     "id": "ua-navbar-menu-close",
  //     "c": "ua-navbar-menu-close ua-navbar-item-controls-menu",
  //     "i": [
  //       closeIcon, returnIcon
  //     ]
  //   }; 

  //   document.body.appendChild(await jsonRender.render(close));

  // }

});

// var updateFixedNavBarPosition = async function() {

//   try {
//     //adjust the hallf menues to appear underneath the navigation
//     var fixedNavBar = document.querySelector(".ua-navbar.fixed-top");
//     if (menu.classList.contains("ua-navbar-menu-halfscreen")) {
//       if (menu && fixedNavBar) {
//         //is their an announcement?
//         var announcements = uamElement.querySelector(".ua-navbar-menu-announcements");
//         if (announcements) {
//           menu.style.top = `${fixedNavBar.clientHeight + announcements.clientHeight}px`;
//           return;
//         }

//         menu.style.top = `${fixedNavBar.clientHeight}px`;
//       }
//     }
//   } catch (error) {
    
//   }

// };

var breadcrumb = [];

// var defZIndex = 1000;

/**
 * Shows the menu with the specified id.
 * @param {*} id The id of the menu to show.
 * @param {*} _breadcrumb Should the breadcrumb be updated?
 * @returns The menu that was shown.
 */
async function Show(id, _breadcrumb = true) {

  var curMenu = document.querySelector(".ua-navbar-menu.show");
  if (curMenu) {
    curMenu.classList.remove("show");
  }

  var menu = document.getElementById(id);
  if (menu) {
    menu.classList.add("show");
    if (_breadcrumb) {
      breadcrumb.push(id);
    } 
  }

  return menu;

} module.exports.Show = Show;

/**
 * Hides the current menu.
 * @param {*} _breadcrumb Should the breadcrumb be updated?
 */
async function Hide(_breadcrumb = true) {

  // hide the current menu
  var curMenu = document.querySelector(".ua-navbar-menu.show");
  if (curMenu) {
    // console.log(`Hiding menu: ${curMenu.getAttribute("id")}`);
    curMenu.classList.remove("show");
    //verify the id is the last item in the breadcrumb
    if (_breadcrumb) {
      if (curMenu.getAttribute("id") == breadcrumb[breadcrumb.length - 1]) {
          breadcrumb.pop();
      } else {
        console.error("Breadcrumb does not match the current menu.");
      }
    }

  } else {
    // console.error("No menu to hide.");
  }

  if (_breadcrumb) {
 
    var lastMenu = breadcrumb[breadcrumb.length - 1];
    var menu = document.getElementById(lastMenu);
    if (menu) {
      menu.classList.add("show");
    }

  }

} module.exports.Hide = Hide;


function adjustHalfMenuPosition(menu) {
  var fixedNavBar = document.querySelector(".ua-navbar-sticky");
  if (menu && fixedNavBar) {

    // var annouce = document.querySelector(".ua-navbar-menu-announcements");
    // if (annouce) {
    //   menu.style.top = (menuAddTop + annouce.clientHeight + fixedNavBar.clientTop + fixedNavBar.clientHeight) + "px";
    //   // return;
    // } else  {
      menu.style.top = (menuAddTop + fixedNavBar.clientTop + fixedNavBar.clientHeight) + "px";
    // }
  }
}


//on ua-navbar-item-menu-halfscreen-hover hover
document.addEventListener("mouseover", function (event) {
  if (event.target.matches(".ua-navbar-item-menu-halfscreen-hover") || event.target.closest(".ua-navbar-item-menu-halfscreen-hover i")) {
    // event.preventDefault();
    var id = event.target.getAttribute("ua-navbar-menu");
    var menu = document.getElementById(id);

    menu.classList.add("ua-navbar-menu-halfscreen");
    menu.classList.remove("ua-navbar-menu-mobile");

    adjustHalfMenuPosition(menu);

    Show(id, false);
    return;
  }

  //if its .ua-navbar-menu-halfscreen 
  if (event.target.matches(".ua-navbar-menu-halfscreen")) {
    event.preventDefault();
    Hide(false);
  }

  if (event.target.matches(".nav-item a")) {
    event.preventDefault();
    Hide(false);
  }

  return;
});


//add a handler for clicking on a .ua-navbar-item element
document.addEventListener("click", function (event) {
  
  // console.log("Clicked on:", event.target);

    //does the element have a ua-navbar-menu attribute?
    // if (event.target.hasAttribute("ua-navbar-menu")) {
    if (event.target.matches(".ua-navbar-item-controls-menu") || event.target.closest(".ua-navbar-item-controls-menu")) {
      event.preventDefault();

      var uaNavBarItem = event.target.closest(".ua-navbar-item-controls-menu");
      var id = uaNavBarItem.getAttribute("ua-navbar-menu");

      var menu = document.getElementById(id);
      if (menu) {
        menu.classList.remove("ua-navbar-menu-halfscreen");
        menu.classList.add("ua-navbar-menu-mobile");
      }

      if (Show(id)) {
        return;
      }

      // var menu = document.getElementById(id);
      // if (menu) { 
      //   menu.classList.toggle("show");
      //   return;
      // }

      // //does the event have a ua-navbar-menu-close class?
      // if (event.target.classList.contains("ua-navbar-menu-close")) {
      //   //get the menu
      //   var id = event.target.getAttribute("ua-navbar-menu");
      //   var menu = document.getElementById(id);
      //   if (menu) {
      //     menu.classList.remove("show");
      //   } else {
      //     console.error(`The menu with id ${id} does not exist.`);
      //   }
      //   return;
      // }

      // //get the menu
      // var id = event.target.getAttribute("ua-navbar-menu");
      // var menu = document.getElementById(id);
      // if (menu) {
      //   menu.classList.add("show");
      //   return;
      // }
    }

  //check if the element is a .ua-navbar-item element or is a child of a .ua-navbar-item element
  if (event.target.matches(".ua-navbar-item") || event.target.closest(".ua-navbar-item")) {

    //if it's an anchor tag, don't prevent the default action
    if (event.target.matches(".ua-navbar-item a") || event.target.closest(".ua-navbar-item a")) {
      return;
    }

    uaNavBarItem = event.target.closest(".ua-navbar-item");

    //prevent the default action
    event.preventDefault();
    
    //get the anchor element
    var anchor = uaNavBarItem.querySelector("a");
    //check if the anchor exists
    if (anchor) {
      //get the href
      var href = anchor.getAttribute("href");

      //trigger the click event on the anchor
      anchor.click();

         //hide all menus
         var menus = document.querySelectorAll(".ua-navbar-menu");
         menus.forEach(menu => {
           menu.classList.remove("show");
         }); 
         
      // //check if the href exists
      // if (href) {
      //   //navigate to the href
      //   window.location.href = href;
      //   return;
      // }
    } 

  }

  //if an event target is ".ua-navbar-menu" but not any of it's children
  // if (event.target.matches(".ua-navbar-menu") && !event.target.closest(".ua-navbar-menu")) {
    // console.log("Clicked on the menu but not any of it's children.", event.target);
    // event.target.classList.remove("show");
  // }

  if (event.target.matches(".ua-navbar-menu")) {
    // console.log("Clicked on the menu.", event.target);
    event.preventDefault();
    Hide();
  }

  if ((event.target.matches(".ua-navbar-menu-close") || event.target.matches(".ua-navbar-menu-close i"))) {
    // console.log("Clicked on the close button.", event.target);
    Hide();
    event.preventDefault();
  }

});


