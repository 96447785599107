/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.tabs",
  title: "Tabs",
  description: "A tabbed interface.",
  fields: {
    "inner": {
      title: "Inner",
      type: "list",
      of: "object", 
      description: "The tabs to display.",
      fields: {
        "inner": {
          title: "Inner",
          type: "inner",
          description: "The inner content of the tab.",
          required: true
        },
        "order": {
          title: "Order",
          type: "number",
          description: "The order of the tab."
        },
        "title": {
          title: "Title",
          type: "string",
          description: "The title of the tab.",
          required: true
        },
        "icon": {
          title: "Icon",
          type: "string",
          description: "The icon of the tab."
        },
        "alt": {
          title: "Alt",
          type: "string",
          description: "The alt text of the tab."
        },
        "active": {
          title: "Active",
          type: "boolean",
          description: "If true, the tab will be active."
        },
        "header": {
          title: "Header",
          type: "inner",
          namespaces: "ua.header",
          description: "The header of the tab."
        }
      },
      required: true
    },
    container: {
      title: "Container Styling",
      type: "object",
      description: "The style of the container of the tabs.",
      fields: {
        "class": {
          title: "Class",
          type: "string",
          description: "The class of the container."
        },
        "style": {
          title: "Style",
          type: "string",
          description: "The style of the container."
        }
      }
    },
    "fade": {
      title: "Fade",
      type: "boolean", 
      description: "If true, the tabs will fade in and out."
    },
    "active": {
      title: "Active",
      type: "number",
      description: "The tab to start with."
    },
    "id": {
      title: "ID",
      type: "string",
      description: "The id of the tabs.",
      required: true
    }
  },
  passthrough: true
});

var generateRandomID = require("../../../uam/functions/generateRandomID.js").function;

// var namespace = module.exports.namespace;
// var f = require("../scripts/f.js");
// var uae = require("./templates/ua.element.js");
// var log = require('../scripts/log.js');
// var required = ["id", "inner"];

// var example = {
//   "id": "mytab",
//   "namespace": "ua.e.nav.tabs",
//   "inner": [{
//     "order": 0,
//     "title": "Tab 1",
//     "icon": "bi-message",
//     "alt": "The my thing button.",
//     "inner": [{
//       "$": "p",
//       "inner": "Example tab"
//     }]
//   },{
//     "order": 0,
//     "title": "tab 2",
//     "icon": "bi-inbox",
//     "alt": "The my thing button.",
//     "inner": [{
//       "$": "p",
//       "inner": "Example tab 2"
//     }]
//   }]
// };

/**
 * The ID to be appended to the id of the holder.
 */
var contentHolderId = "Content";
var navHolderId = "Navbar"

exports.namespace = "ua.e.nav.tabs";
exports.render = async function (options) {

  // var options = uae.GetOptions(ele, required);

  //if no id, add one
  if (!("id" in options)) {
    options.id = generateRandomID();
  }

  var parent = {
    n: "div",
    c: "navtabs",
    i: options.id,
    role: "tablist",
    i: []
  };

  var ul = {
    n: "ul",
    c: "nav nav-tabs",
    i: [],
    role: "tablist",
    id: options.id + navHolderId,
  };

  var holder = {
    n: "div",
    c: "tab-content",
    id: options.id + contentHolderId,
    i: []
  };

  if ("container" in options) {
      
      if ("class" in options.container) {
        holder.c = holder.c + " " + options.container.class;
      }
  
      if ("style" in options.container) {
        holder.style = options.container.style;
      }
  }

  var fade = false;

  if ("fade" in options) {
    fade = options.fade;
  }

  //for each tab
  for (var i = 0; i < options.inner.length; i++) {
    var nav = options.inner[i];
    var myid = generateRandomID();
    var navLinkID = "navlink" + myid;
    var navContentId = "navcont" + myid;
    var active = false;

    if ("active" in nav) {
      active = nav.active;
    }

    var li = {
      n: "li",
      c: "nav-item",
      role: "presentation",
      i: []
    };

    var a = {
      n: "a",
      id: navLinkID,
      "data-bs-toggle": "tab",
      href: "#" + navContentId,
      role: "tab",
      "aria-controls": navContentId,
      c: "nav-link",
      i: []
    };

    if (active) {
      a.c = a.c + " active";
      a["aria-selected"] = true;
    }

    if ("alt" in nav) {
      a.title = nav.alt;
    }

    if ("icon" in nav) {
      a.i.push({
        n: "i",
        c: nav.icon
      });

      a.i.push(nav.title);

    } else {

      a.i.push(nav.title);

    }

    var content = {
      n: "div",
      c: "tab-pane",
      id: navContentId,
      role: "tabpanel",
      "aria-labelledby": navLinkID,
      i: []
    };

    //I'm starting with this panel
    if (active) {
      content.c = content.c + " active show";
    }

    if (fade) {
      content.c = content.c + " fade";
    }

    if ("header" in nav) {
      content.i.push(nav.header);
    }

    content.i.push(nav.inner);

    ul.i.push(li);
    holder.i.push(content);

  }

  parent.i.push(ul);
  parent.i.push(holder);
  return parent;

};