/*!
 * This Factory will create a registry to be used internally by the bject that is utilizing it.
 * 
 * The factory uses namespaces to prevent the same object from being added to the registry.
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * all components that use modularity
 * 
 * Justin K Kazmierczak
 * All Rights Reserved.
 * 
 * May be subject to The Universe Terms of Service.
 **/
//My personal log.
// var log = require("./logger")();

var namespace = "uam.registry";

// var events = require("./events.js");
// events.register({
//   namespace: `${namespace}.create`,
//   description: "Fired when a new registry is created.",
//   params: [
//     {
//       name: "namespace",
//       type: "string",
//       description: "The namespace of the registry.",
//       required: true
//     }
//   ]
// }, {
//   namespace: `${namespace}.add`,
//   description: "Fired when an item is added to the registry.",
//   params: [
//     {
//       name: "registry",
//       type: "object",
//       description: "The registry object.",
//       required: true
//     },
//     {
//       name: "item",
//       type: "object",
//       description: "The item being added.",
//       required: true
//     }
//   ]
// });
// , {
//   namespace: `${namespace}.search`,
//   description: "Fired when an item is searched for in the registry.",
//   params: [
//     {
//       name: "registry",
//       type: "object",
//       description: "The registry object.",
//       required: true
//     },
//     {
//       name: "item",
//       type: "object",
//       description: "The item being searched for.",
//       required: true
//     }
//   ]
// }
//);



// const { kStringMaxLength } = require("buffer");
// const { findSourceMap } = require("module");
// const { level_info } = require("./logger");
var santize = require("./sanitize.js"); 
var fm = require("./filemanager.js");

var ParseStackTrace = require("./functions/parseStackTrace.js").function;
var GenerateStacktrace = require("./functions/generateStacktrace.js").function;

 /**
  * Creates a registry that has events (registry.events) it may call.
  * It will call validation before adding (and checking namespace);
  * It will call onAdd after adding a new item.
  * @returns A registry.
  */
function create(namespace) {


  // fm.SaveLogJSONbyNamespaceItem("registry", {
  //   namespace: namespace
  // });

  //if no namespace error out
  if (!namespace) {
    console.warn("The registry must have a namespace.");
    throw "The registry must have a namespace.";
  }

  //sanitize namespace
  santize("string", namespace);

  // events.fire(`${namespace}.create`, {
  //   namespace: namespace
  // });

  return {
      ParseStackTrace: ParseStackTrace,
      GenerateStacktrace: GenerateStacktrace,
      created: Date.now(),
      namespace: namespace,
      registry: [],
      // events: {
      //   validation: [],
      //   onAdd: [],
      //   search: []
      // },
      GetObj: GetObj,
      search: search,
      get: search,
      find: search,
      add: add,
      addFromDir: addFromDir,
      addFromDirSingle: addFromDirSingle,
      //addEvent: addEvent,
      listRegistered: listRegistered,
      list: listRegistered,
      length: length,
      getUris: getUris,
      on: manageEvents,
      events: {
        add: []
      }
  };
} module.exports.create = create;

/**
 * Manages events for the registry.
 * Most Properties send to the events are as follows
 * @property {*} registry The orginating registry.
 * @property {*} item The item that was added.
 * @param {*} type The type of event to manage. The type can be "add".
 * @param {*} event The function to add.
 */
function manageEvents(type, event) {
  switch (type) {
    case "add":
      // santize("function", event);
      this.events.add.push(event);
      break;
    default:
      console.error("The event type is not recognized.", {
        type: type,
        event: event
      });
      break;
  }
}


function GetObj() {
  var obj = {};

  //for each item in the registry
  for (var i = 0; i < this.registry.length; i++) {
    var item = this.registry[i];
    obj[item.namespace] = item;
  }

  return obj;

}

/**
 * Reports the length of the registry.
 * @returns {number} The length of the registry.
 */
function length() {
  return this.registry.length;
}

// /**
//  * Will safely push a delegate.
//  * @param {string} type  The type of event to add. The type can be "validate" or "onAdd".
//  * @param {function} delegate 
//  */
// function addEvent(type, event) {
//   // log.add("Module: Registry.addevent", "New Event:", {
//   //   type: type,
//   //   event: event
//   // }, i);
  
//   console.log("New Event:", {
//     type: type,
//     event: event
//   });
  
//   santize("function", event);


//   if (type === "validate") {
//     events.on(`${this.namespace}.add`, event);
//   } else if (type === "onAdd") {
//     events.on(`${this.namespace}.onAdd`, event);
//     // this.events.onAdd.push(event);
//   } 
// } 

/**
 * Lists all the registered namespaces (easier to use in fast loops and indexing).
 * @returns {Array} An array of namespaces.
 */
function listRegistered() {
  var arr = [];
  for (var i = 0; i < this.registry.length; i++) {
    arr.push(this.registry[i].namespace);
  }
  return arr;
}

/**
 * Gets all the uris of the registered items.
 * @returns {Array} An array of uris.
 */
function getUris() {

  var arr = [];
  for (var i = 0; i < this.registry.length; i++) {
 
    var item = this.registry[i];
    if ("$uri" in item) {
      arr.push(item.$uri);
    }

  }

  return arr;
}


/**
 * Searches for an item in the current registry by it's namespace.
 * @param {String} namespace 
 * @returns the item or false
 */
function search(namespace, reportToLog = false) {
  var found = false;

  santize("string", namespace);

  namespace = NormalizeNamespace(namespace);

  //search for namespace
  for (var i = 0; i < this.registry.length; i++) {
    if (this.registry[i].namespace == namespace)  {
      return this.registry[i];
    }
  }

  if (!found) {
    if (reportToLog) {
      console.error("Could not find namespace: ", namespace, {
        stack: GenerateStacktrace()
      });
      // log.add("search",  `Could not find namespace: ${namespace}.`, 3, this.namesapce);
      // log.add("Module: Registry.search.namespace", `Could not find namespace: ${namespace}.`, this.namesapce, 3);
      //log.e(_proc, _st, "NamespaceNotFound", `Could not find namespace: ${namespace}.`, namespace);
    }
    
    return false;
    //throw `Could not find namespace: ${namespace}.`;
  }
}

/**
 * Normalizes a namespace to be all lowercase and trims all spaces.
 * @param {*} namespace The namespace to normalize.
 * @returns {string} The normalized namespace.
 */
function NormalizeNamespace(namespace) {
  return namespace.trim().toLowerCase();
} module.exports.NormalizeNamespace = NormalizeNamespace;

/**
 * Adds an item with a namespace to the registry.
 * - checks if the item has a namespace, if not, it checks the defination
 *   for a namespace item.define.namespace.
 * - uses validation functions on registry.events.validation (self, item);
 * - uses addition (after completion) function on registry.events.add (self, item)
 * - All namespaces are normalized using the normalize namespace function during
 *   search or add.
 * @param {*} item The module or object to add. 
 * @param {*} from The location of the item that was added (how it was added). If false, will autogenerate. Defaults to false.
 * @returns {boolean} If the item was added successfully.
 */
function add(item, from = false) {

  // santize.log(log, "object", item);
  santize("object", item);

  // if (events.has(`${namespace}.validation`)) {
  //   // log.i(this._proc, this._st, "Validation-start", "Attempting Validation", this.events.validation);
    
  //   console.log("Attempting Validation for registry", item);

  //   events.fireNoAwait(`${this.namespace}.validation`, {
  //     registry: this,
  //     item: item
  //   });

  // }

  if (!("namespace" in item)) {

    //lets check if the defination has a namespace
    if (("define" in item)) {
      if ("namespace" in item.define) {
        item.namespace = item.define.namespace;
      }
    }
  
    //if the item still doesn't have a namespace, the item is invalid.
    if (!("namespace" in item)) {

      console.error("The object must register with a namesapce.", {
        item: item,
        stack: GenerateStacktrace()
      });

      console.log("The object must register with a namesapce.", {
        item: item,
        stack: GenerateStacktrace()
      });

      // log.add("Module: Registry.add.namespace", "The object must register with a namesapce.", item, 3);
      //log.e(this._proc, this._st, "NamespacePropNotFound", `"The object does not have a namespace."`, item);
      return false;

    }
  }

  //force the namespace to be normalized
  item.namespace = NormalizeNamespace(item.namespace);


  if (this.registry.length < 1) {
    //I have no items in my registry, so I'm safe.
  } else {
    if (this.search(item.namespace,false) !== false) {
      console.error("The registry already has an object registered by the namesapce", item.namespace, {
        item: item,
        stack: GenerateStacktrace()
      });
      // log.add("Module: Registry.add.namespace.duplicate", `The registry already has an object registered by the namesapce ${item.namespace}.`, item, 3);
      return false;
    }
  }

  if ("define" in item) {
    if (!(from)) {
      item.define.from = GenerateStacktrace();
    } else {
      item.define.from = from;
    }
  }

  //add outside of for so this new element isn't counted
  this.registry.push(item);

  //call all functions in the add event, knowing some could be async
  for (var i = 0; i < this.events.add.length; i++) {
    this.events.add[i](this, item);
  }

  // events.fire(`${namespace}.add`, {
  //   registry: this,
  //   item: item
  // });
  
  // if (this.events.onAdd.length > 0) {
  //   for (var i = 0; i < this.events.onAdd.length; i++) {
  //     var event = this.events.onAdd[i];
  //     var repo = event(this, item);
  //     if (!repo.success) {
  //       console.error("The object failed the onAdd event", {
  //         repo: repo,
  //         event: event,
  //         item: item,
  //         stack: GenerateStacktrace()
  //       });
  //       // log.add("Module: Registry.add.event.onadd", "The object failed the onAdd event", {
  //       //   repo: repo,
  //       //   event: event,
  //       //   item: item
  //       // }, 3);
  //       //log.e(this._proc, this._st, "onAdd", `"The object falied the onAdd event."`, repo, event, item);
  //     }
  //   }
  // }

  return true;
}

// module.exports = create();

/**
 * Adds all objects in a directory to the registry.
 * @param {*} locations An array or a string of locations to add.
 * @param {string} type The file extension of the object to add. Defaults to "js"
 * @param {boolean} ignoreUnderscore If true, will ignore files that start with a underscore.
 */
function addFromDir(locations, type = "js", ignoreUnderscore = false) {

  //is it an array?
  if (Array.isArray(locations)) {
    for (var i = 0; i < locations.length; i++) {
      this.addFromDirSingle(locations[i], type, ignoreUnderscore);
    }
  } else {
    this.addFromDirSingle(locations, type, ignoreUnderscore);
  }

}


/**
 * Registers objects into the registry with the proper extension.
 * @param {string} location The folder location of the objects to register.
 * @param {string} type The file extension of the object to add. Defaults to "js"
 */
function addFromDirSingle(location, type = "js", ignoreUnderscore) {


  location = fm.resolve(location);

  if (type.charAt(0) !== ".") {
    type = `.${type}`;
  } 

  if (!santize("string", location)) {
    console.error("The paramater location failed sanitization.", location, {
      stack: GenerateStacktrace()
    });
    // log.add("Module: Registry.addFromDir.santize", "The paramater location failed sanitization.", location, 3);
  }

  var _on = "addDromDir";
  
  var fs = require("fs");
  const path = require("path");

  var files = fs.readdirSync(location);
 
  var i = 0;
  while (files.length > i) {
      var cur = files[i];
      var fullpath = location + "/" + cur;
      if (path.extname(cur) == type) {

        //if ignoreDallorSign is true, ignore files that start with a dollar sign.
        if (ignoreUnderscore) {
          if (cur.charAt(0) === "_") {
            i = i + 1;
            continue;
          }
        }

        var rq = require(fullpath);
        rq.$uri = fullpath;
        this.add(rq, GenerateStacktrace(1));

      }

      i = i + 1;
  }

}

module.exports = create;
// module.exports.log = log;
