/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.devmode",
  title: "Development Mode",
  description: "Shows the development mode bug.",
  fields: {
    "title": {
      title: "Title",
      type: "inner",
      description: "The title of the mode.",
      required: true
    }, "inner": {
      title: "Inner",
      type: "inner",
      description: "The full description of the mode.",
      required: true
    }
  },
  supportServer: false
});
// var namespace = module.exports.namespace;

// var namespace = "ua.devmode";
// exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");

var devmodeID = "devmode";

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  //check if the devmode is already on the page, if so delete it.
  var devmode = document.getElementById(devmodeID);
  if (devmode) {
    console.warn("A devmode has been replaced.", {
      text: devmode.innerText
    });
    devmode.remove();
  }

  // try {
    var div = document.createElement("div");
    div.id = devmodeID;
  
    var divContainer = document.createElement("div");
    divContainer.classList.add("dev-mode-pill");
    divContainer.classList.add("text-shadow");
    divContainer.classList.add("box-shadow");
    divContainer.appendChild(await jsonRender.render(options.title));
  
    var divMessage = document.createElement("div");
    divMessage.classList.add("dev-mode-message");
    divMessage.classList.add("box-shadow");
    divMessage.classList.add("text-shadow");
    divMessage.classList.add("bg-blur-og-75-black");
    divMessage.appendChild(await jsonRender.render(options.inner));


    var divClose = document.createElement("div");
    divClose.classList.add("dev-mode-pill");
    divClose.classList.add("dev-mode-btn");
    divClose.classList.add("text-shadow");
    divClose.classList.add("box-shadow");
    divClose.innerHTML = '<i class="bi bi-x-circle"></i>';
    divClose.addEventListener("click", close);

    //blur overlay
    var divBlur = document.createElement("div");
    divBlur.classList.add("dev-mode-message-overlay");
    divBlur.classList.add("bg-blur-black");
    divBlur.appendChild(divMessage);

    // var divCloseMessageAnchor = document.createElement("a");
    // // divCloseMessageAnchor.href = "#";
    // divCloseMessageAnchor.innerHTML = `<i class="bi bi-x-circle"></i> Close Message`;
    // divCloseMessageAnchor.addEventListener("click", close);

    // var pCloseHolder = document.createElement("p");
    // pCloseHolder.classList.add("p-3");
    // pCloseHolder.appendChild(divCloseMessageAnchor);
    // divMessage.appendChild(pCloseHolder);


    divContainer.addEventListener("click", function() {
      divBlur.classList.add("show");

      var divCloseMessage = document.createElement("div");
      divCloseMessage.classList.add("dev-mode-pill");
      divCloseMessage.classList.add("dev-mode-close-message");
      divCloseMessage.classList.add("text-shadow");
      divCloseMessage.classList.add("box-shadow");
      divCloseMessage.innerHTML = '<i class="bi bi-x-circle"></i>';
      divCloseMessage.addEventListener("click", close);

      // divCloseMessageAnchor.addEventListener("click", function() {
      //   divCloseMessage.remove();
      // });

      document.body.appendChild(divCloseMessage);

    });


    function close() {
      divBlur.remove();
      divMessage.remove();
      divContainer.remove();
      divClose.remove();

      //delete the element that called the close function
      this.remove();
    
    }

    div.appendChild(divContainer);
    div.appendChild(divClose);
    div.appendChild(divBlur);

    return div;

  // } catch (error) {
    

  // }



  // return div;

};

/** 
<article class="container-fluid vh-100 d-flex">
<div class="row m-auto">
    <div class="col" data-ua-observe>
        <h1 class="text-center">Welcome to The Universe</h1>
    </div>
</div>
</article> */
