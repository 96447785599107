/*!
 * Designed to help the app self report errors.
 */

var namespace = "uam.errors";
var registry = require("./registry.js");
var errorz = registry("errors");


var generateStacktrace = require("./functions/generateStacktrace.js").function;
var parseStackTrace = require("./functions/parseStackTrace.js").function;
var generateSafeError = require("./functions/generateSafeError.js").function;
var literals = require("./functions/literals.js").function;

// var jckConsole = require("@jumpcutking/console");

// var unInitilized = new Error("The Error is not registered with the registry.");
// unInitilized.namespace = namespace + ".unInitilized";
// unInitilized.title = "Uninitialized Error";
// unInitilized.description = "An error element should register itself with the registry to help promote transparency across the app. It helps developers diagnose issues and helps users report issues.";

// var propertyExists = new Error("A property with the same name already exists.");
// propertyExists.title = "Property Exists";
// propertyExists.namespace = namespace + ".propertyExists";
// propertyExists.description = propertyExists.message;

// var dataPassingFailed = new Error("Data failed to pass.");
// dataPassingFailed.title = "Data Passing Failed";
// dataPassingFailed.namespace = namespace + ".dataPassingFailed";
// dataPassingFailed.description = dataPassingFailed.message;

// var noTitle = new Error("The error does not have a title.");
// noTitle.title = "No Title";
// noTitle.namespace = namespace + ".noTitle";
// noTitle.description = noTitle.message;

// var noDescription = new Error("The error does not have a description.");
// noDescription.title = "No Description";
// noDescription.namespace = namespace + ".noDescription";
// noDescription.description = noDescription.message;

// var noNamespace = new Error("The error does not have a namespace.");
// noNamespace.title = "No Namespace";
// noNamespace.namespace = namespace + ".noNamespace";
// noNamespace.description = noNamespace.message;

var fm = false;

try {
    fm = require("./filemanager.js"); //won't work in the browser
} catch (error) {
    
}

/**
 * The internal error object.
 * This is a list of errors that are used by the errors object only.
 */
var myErrors = {
    unInitilized: {
        title: "Uninitialized Error",
        description: "An error element should register itself with the registry to help promote transparency across the app. It helps developers diagnose issues and helps users report issues.",
        // template: "The error ${namespace} is not registered with the registry.",
        namespace: "uam.errors.unInitilized"
    },
    propertyExists: {
        title: "Property Exists",
        description: "A property with the same name already exists.",
        // template: "The property ${id} already exists.",
        namespace: "uam.errors.propertyExists"
    },
    dataPassingFailed: {
        title: "Data Passing Failed",
        description: "Data failed to pass.",
        // template: "The data failed to pass.",
        namespace: "uam.errors.dataPassingFailed"
    },
    noTitle: {
        title: "No Title",
        description: "The error does not have a title.",
        // template: "The error ${namespace} does not have a title.",
        namespace: "uam.errors.noTitle"
    },
    noDescription: {
        title: "No Description",
        description: "The error does not have a description.",
        // template: "The error ${namespace} does not have a description.",
        namespace: "uam.errors.noDescription"
    },
    noNamespace: {
        title: "No Namespace",
        description: "The error does not have a namespace.",
        // template: "The error ${namespace} does not have a namespace.",
        namespace: "uam.errors.noNamespace"
    },
    unknonwError: {
        title: "Unknown Error",
        description: "The internal error by that ID does not exist.",
        // template: "The error ${namespace} does not exist.",
        namespace: "uam.errors.unknownError"
    },
};

/**
 * Gebnerates an internal object.
 * @param {*} id The id in the myErrors object.
 * @returns An error object.
 */
function ThrowInternalError(id, data = false) {
    var err = myErrors[id];
    if (!(err)) {
        err = myErrors.unknonwError;
    }

    var e = new Error(err.title);
    e.namespace = err.namespace;
    e.title = err.title;
    e.description = err.description;
    e.message = `${err.description} (${err.namespace})`;

    if (data) {
        try {
            e.data = JSON.parse(JSON.stringify(data), Object.getOwnPropertyNames(data));
        } catch (error) {
            error.message = `The data failed to pass. ${error.message}`;
            e.data = error;
        }
    }

    delete e.stack;
    e.stack = generateStacktrace(2);

    throw e;
}


/**
 * Adds an error to the registry.
 * @param {*} namespace The namespace used for identification.
 * @param {*} title The title or message of the error.
 * @param {*} description A more detailed description of the error.
 * @param {*} template The literal string that replaces the error description (and updates message), when data is an object with matching properties.
 * @returns The error.
 */
function AddError(namespace, title, description, template = false) {

    var loggable = {
        namespace, title, description, template,
        from: generateStacktrace(1)
    };

    
    /* validate the error */
    //if namespace is null, undefined, or empty
    if (namespace === null || namespace === undefined || namespace === "") {
        ThrowInternalError("noNamespace", loggable);
    }

    if (title === null || title === undefined || title === "") {
        ThrowInternalError("noTitle", loggable);
    }

    if (description === null || description === undefined || description === "") {
        ThrowInternalError("noDescription", loggable);
    }

    var err = new Error(title);

    //namespace = namespace to lower and trim
    namespace = namespace.toLowerCase().trim();
    err.title = title;
    err.namespace = namespace;
    err.description = description;
    err.message = `${description} (${namespace})`;
    err.template = template;
    err.from = loggable.from

    // //for each array in err.from
    // for (var i = 0; i < err.from.length; i++) {
    //     //make err.from object properties public
    //     err.from[i] = JSON.parse(JSON.stringify(err.from[i], Object.getOwnPropertyNames(err.from[i])));
    // }

    // err.from = JSON.parse(JSON.stringify(err.from, Object.getOwnPropertyNames(err.from)));
    // err.stacktrace = parseStackTrace(err.stacktrace);

    // var stacktrace = generateStacktrace();

    // if (stacktrace[0].file == "./uam/module.js") {
    //     err.from = stacktrace[1];
    // } else {
    //     err.from = stacktrace[0];
    // }
    // err.from = generateStacktrace(3)[0];

    //make err.from object properties public and all it's properties public
    // err.from = JSON.parse(JSON.stringify(err.from, Object.getOwnPropertyNames(err.from)));

    // delete err.stacktrace;

    stacktrace = false;

    try {
        errorz.add(err);

        // if (fm) {
        //     try {
        //         fm.SaveLogJSONbyNamespaceItem("errors", err);
        //     } catch (error) {
                
        //     }
            
        // }

    } catch (error) {
        console.warn(`Error adding ${namespace} to registry. ${error.message}`);
    }

    return err;
} module.exports.add = AddError;

/**
 * Returns the error registry.
 * @returns {*} The error registry.
 */
function GetRegistry() {
    return errorz; 
} module.exports.getRegistry = GetRegistry;

/**
 * Adds an array of errors to the registry.
 * @param {*} errors An array of errors to add.
 */
function AddErrors(errors) {
    for (var i = 0; i < errors.length; i++) {
        AddError(errors[i].namespace, errors[i].title, errors[i].description);
    }
} module.exports.addErrors = AddErrors;

/**
 * Searches the registry for an error with the given namespace.
 * @param {*} namespace The namespace to search for.
 * @param {*} data The extra data to share to the developer.
 * @returns The error if found.
 */
function error(namespace, data = null) {

    // console.info(`Searching for error with namespace ${namespace}`, errors.registry);

    var e = errorz.search(namespace);

    if (e) {

    //if data is not null
        if (data) {
            var nErr = new Error(`${e.message}`);
            nErr.namespace = e.namespace;
            nErr.title = e.title;
            nErr.description = e.description;
            nErr.data = JSON.parse(JSON.stringify(data), Object.getOwnPropertyNames(data));
            nErr.from = e.from;
            return nErr;
            // e.data = data;
            // //add it to the error
            // //Let's clone the error so we don't modify the original
            // var nErr = new Error(`${e.message}`);
            // nErr.namespace = e.namespace;
            // nErr.title = e.title;
            // nErr.description = e.description;
            // nErr.data = data;
            // nErr.from = e.from;
            // e = nErr; 
        }

    // if (e) {
        return e;
    } else {
        throw SafeError_Error(unInitilized, {
            namespace: namespace,
            data: data
        });
    }

} module.exports.error = error;

/**
 * Generates a safe throwable error that will be passable.
 * This uses the jckConsole to generate a safe error.
 * The safe error rebuilds the error object so it can be passed as a JSON object
 * and doesn't contain any circular references or other non-serializable objects.
 * @param {*} namespace The namespace to search for.
 * @param {*} data The extra data to share to the developer.
 * @returns A safe error object.
 */
function SafeError(namespace, data = null) {
    try {
        // console.info("Generating safe error", {
        //     namespace: namespace,
        //     data: data,
        //     typeof: typeof data
        
        // });

        //if data is not null
        if (data) {
            console.info(`Data from safe error ${namespace}`, {
                data: data
            });
        }

        var e = error(namespace, data);
        var stack = generateStacktrace(2);
        e = generateSafeError(e);
        
        e.data = null;
        try {

            //is data object null, or undefined
            if (data === null || data === undefined) {
                e.data = undefined;
            } else {
                // e.data = JSON.parse(JSON.stringify(data), Object.getOwnPropertyNames(data));

                //if a template is a non-empty string

                if (typeof e.template == "string" && e.template.length > 0) {
                    //is data an object
                    if (typeof data == "object") {
                        e.description = literals(e.template, data);
                        e.message = `${e.title} ${e.description}`;
                    }
                }

                // //is data an object
                // if (typeof data == "object") {
                //     e.description = literals(e.template, data);
                //     e.message = `${e.title} ${e.description}`;
                // }

            }

        } catch (error) {
            var nError = SafeError_Error(dataPassingFailed, error);
            e.data = nError;
        }
        
        // e.data = JSON.stringify(data);
        // e.data = JSON.parse(JSON.stringify(data), Object.getOwnPropertyNames(data));
        delete e.stack;
        e.stack = stack;

        delete e.stacktrace;
        //rebuild stacktrace using e.stack
        e.stacktrace = `${e.message}`;

        //for each stack itewm
        for (var i = 0; i < stack.length; i++) {
            e.stacktrace += `
    ${stack[i].s}`;
        }

        // e.from = parseStackTrace(e.stacktrace);//[5];

        //reduce the lines of the stracktrace
        // e.stacktrace = () {
            varRemoveFrom = 1;
            varRemoveLines = 2;
            var lzstack = e.stacktrace.split("\n");

            //zero based only remove the total times of varRemoveLines starting at the removeFrom index

            // for (var i = 0; i < lzstack.length; i++) {
            //     if (i >= varRemoveFrom && i < varRemoveFrom + varRemoveLines) {
            //         lzstack.splice(i, 1);
            //     }
            // }

            var i = 0;
            var nStack = [];
            while (i < lzstack.length) {
                if (i >= varRemoveFrom) {
                    if (i <= varRemoveFrom + varRemoveLines) {
                        i++;
                        continue;
                    }
                }
                nStack.push(lzstack[i]);
                i++;
            }

            e.stacktrace = nStack.join("\n");

        // }

        return e;
    } catch (err) {
        console.error("Error generating safe error", {
            err: err,
            namespace: namespace,
            data: data
        })
        return err;
    }
} module.exports.safeError = SafeError;

/**
 * Generates a safe throwable error from a throwable error
 * @param {*} namespace The namespace to search for.
 * @param {*} data The extra data to share to the developer.
 * @param {*} keepOrginalStack Whether to keep the orginal stack trace.
 * @returns A safe error object.
 */
function SafeError_Error(e, data = null, keepOrginalStack = false) {
    try {
        // var e = error(namespace, data);
        var stack = {};
        if (keepOrginalStack) {
            stack = parseStackTrace(e.stack);
        } else {
            stack = generateStacktrace(1);
        }
        
        e = JSON.parse(JSON.stringify(e, Object.getOwnPropertyNames(e)));
        e.data = data;

        e.stack = stack;
        return e;
    } catch (err) {
        return err;
    }
} module.exports.SafeError_Error = SafeError_Error;

// /**
//  * Produces a sage error message with the stack trace.
//  * @param {*} error A throwable error.
//  * @returns 
//  */
// function MakeErrorSafe(error) {

//     //if error is not an instance of an error

//     // if (!(error instanceof Error)) {
//     //     throw notAnError;
//     // }
//     var stack = jckConsole.parseStackTrace(error.stack);
//     error = JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
//     error.stack = stack;
//     return error;
// } module.exports.makeErrorSage = MakeErrorSafge;


/**
 * Creates a new error registry.
 * @param {*} namespace 
 * @returns The error registry.
 * @property {*} add Adds an error to the registry.
 * @property {*} addErrors Adds an array of errors to the registry.
 * @property {*} safe Returns a safe error object.
 * @property {*} generateSafeError Generates a safe error object.
 * @property {*} SafeError_Error Generates a safe error object from a throwable error.
 * @property {*} errorName When a new error is added to the object, the error id is added to the object, with the error object as the value.
 * @property {*} errorName.throws Throws an error with the given data.
 * @property {*} errorName.safe Returns a safe error object.
 * @property {*} errorName.is Checks if the error is the same as the error with the given id.
 */
function Create(namespace) {
    return {
        namespace: namespace,
        /**
         * Adds an error to the registry.
         * @param {*} id The message id.
         * @param {*} title The title or message of the error.
         * @param {*} description A more detailed description of the error.
         */
        add: function (id, title, description, template = false) {

            //if the id is already added to the object
            if (this[id]) {
                throw new Error(`The error with the id ${id} (or a known property of the same name) already exists.`);
            }

            //check to make sure the id is not a known property
            // if (id in this) {

            this[id] = AddError(`${namespace}.${id}`, title, description, template);
            // this[id].namespace = namespace;
            // this[id].id = id;
            this[id].throw = function (data) {

                //is data object a throwable
                if (data instanceof Error) {
                    data = SafeError_Error(data);
                }


                throw SafeError(`${this.namespace}`, data);
            },
            this[id].safe = function (data) {
                //is data object a throwable
                if (data instanceof Error) {
                    data = SafeError_Error(data);
                }

                return SafeError(`${this.namespace}`, data);
            },
            this[id].is = function (err) {
                if ("namespace" in err) {
                    // console.log("Checking if error is the same", {
                    //     err: err.namespace,
                    //     namespace: this.namespace,
                    //     is: err.namespace == this.namespace
                    // });
                    return err.namespace == this.namespace;
                } 
                return false;
            }//,
            // this[id].from = generateStacktrace(2);

        },
        generateSafeError: generateSafeError,
        /**
         * Returns a safe and sharable error by searching the registry for an error with the given id.
         * @param {*} id The id of the error.
         * @param {*} data The extra data to share to the developer.
         * @returns A safe error object.
         */
        safe: function (id, data) {
            return SafeError(`${namespace}.${id}`, data);
        },
        /**
         * Adds an array of errors to the registry.
         * @param {*} errors 
         * @property {*} []id The message id.
         * @property {*} []title The title or message of the error.
         * @property {*} []description A more detailed description of the error.
         */
        addErrors: function (errors) {
            for (var i = 0; i < errors.length; i++) {
                // console.log("Adding error", errors[i]);
                //check if the id is already added to the object
                // if (!(this[errors[i].id])) {
                if (errorz.search(`${namespace}.${errors[i].id}`)) {
                    //it's loaded already? - update it?
                    var e = errorz.search(`${namespace}.${errors[i].id}`);
                    e.title = errors[i].title;
                    e.description = errors[i].description;

                } else {
                    this.add(errors[i].id, errors[i].title, errors[i].description);
                }
                // }
            }
        }
        // SafeError_Error: SafeError_Error
    }
} module.exports.create = Create;

/**
 * Creates an error registry from a define object.
 * @param {*} define The define object.
 * @returns The error registry.
 * @property {*} add Adds an error to the registry.
 * @property {*} addErrors Adds an array of errors to the registry.
 * @property {*} safe Returns a safe error object.
 * @property {*} generateSafeError Generates a safe error object.
 * @property {*} SafeError_Error Generates a safe error object from a throwable error.
 * @property {*} errorName When a new error is added to the object, the error id is added to the object, with the error object as the value.
 * @property {*} errorName.throws Throws an error with the given data.
 * @property {*} errorName.safe Returns a safe error object.
 * @property {*} errorName.is Checks if the error is the same as the error with the given id.
 */
function CreateFromDefine(define) {

    if (!("namespace" in define)) {
        throw new Error("The define object must have a namespace property in the provided definition.");
    }

    var err = Create(define.namespace);
    if ("errors" in define) {
        for (var key in define.errors) {
            var obj = define.errors[key];
            err.add(key, obj.title, obj.description);
            // err.from = generateStacktrace(2);
        }
    }
    return err
} module.exports.createFromDefine = CreateFromDefine;

module.exports.tests = [{
    namespace: `${namespace}.defualt`,
    must: true,
    run: () => {

        var err = Create("test");
        err.add("test", "This is a test thowable.", "Here is the test and it's description.");
        console.error(err.test.message, err.test);

        var s = err.test.safe({
            success: true
        });

        console.error("Safe Error", s);
        return s.data.success;

    }
}, {
    namespace: `${namespace}.template`,
    must: "This is a test thowable. The user John had a fake problem with a fake problem.",
    run: () => {

        var err = Create("test");
        err.add("test", "This is a test thorable!",
            "Here is a descirption without a template.",
            "The user ${name} had a fake problem with ${problem}.");
        console.error(err.test.message, err.test);

        try {
            err.test.throw({
                success: true,
                name: "John",
                problem: "a fake problem"
            });
        } catch (error) {
            console.info("Error", error);
            return error.title;
        }

        // console.error("Safe Error", s);
        return false;

    }
}];