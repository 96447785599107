/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "ua.attr.data.goto",
    title: "Data Goto",
    description: "The ability to add an attribute to fire navigation to a page, such as a product display page.",
    attribute: {
        "data-goto": {
            title: "Data Goto",
            type: "uri",
            description: "The page to navigate to.",
            perform: hasAttribute 
        }
    }
});
// var namespace = module.exports.namespace;

// var namespace = "ua.attr.data.goto";
// exports.namespace = namespace;

// var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");
// var events = require("../../../uam/events.js");

// exports.define = {
//     namespace: namespace,
//     description: "The ability to use data-goto to fire navigation to a page.",
//     fields: {
//         "_attr": {
//             title: "Attributes",
//             type: "object",
//             description: "The attributes to add to the anchor tag."
//         }
//     }
// }

/**
 * Adds a anchor link tag to the page.
 * @param {*} options The json objects and properties to render.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

    throw new Error("This is not a renderable control");

}

/**
 * Navigates to the specified page.
 * It supports relative paths, such as @!/ and /
 * @param {*} options The options to navigate to.
 */
async function hasAttribute(options) {
    var goto = options.value;
    console.log("Navigating to: ", goto);

    //if the first three characters are @!/ - remove the @! and add the domain parts to it
    if (goto.substring(0, 3) == "@!/") {
        goto = goto.substring(2);
        goto = window.location.origin + goto;
        return goto;
    }

    //if the first character is a "/" than add the domain parts to it
    if (goto.charAt(0) == "/") {
        goto = window.location.origin + goto;
        return goto;
    }

    // //support for relative paths
    // if (goto.substring(0, 2) == "./") {

    //     //build the full path
    //     goto = window.location.pathname + goto.substring(1);
    //     return goto;
    // }

    // if (goto.substring(0, 3) == "../") {
    //     //build the full path and keep the ../, ensure that window.location.pathname ends in a"/"
    //     var path = window.location.pathname;
    //     if (path.charAt(path.length - 1) != "/") {
    //         path = path + "/";
    //     }
    //     goto = path + goto;
    //     return goto;
    // }

    //if the first character is a "#" than just navigate to the hash
    if (goto.charAt(0) == "#") {
        window.location.hash = goto;
        return;
    }

    window.location.href = goto;

};

//for any click event of an attribute of data-goto we will navigate to the page UNLESS the element clicked is a button or a tag and that attrivute does not exist in the clicked element.

document.body.addEventListener("click", function (e) {

    if (e.target.hasAttribute("data-goto")) {

        var goto = e.target.getAttribute("data-goto");
        console.log("Navigating to: ", goto);

        if (e.target.tagName.toLowerCase() == "button" || e.target.tagName.toLowerCase() == "a") {
            if (!e.target.hasAttribute("data-goto")) {
                return;
            }
        }

        //if the first character is a "/" than add the domain parts to it
        if (goto.charAt(0) == "/") {
            goto = window.location.origin + goto;
        }

        //if the first character is a "#" than just navigate to the hash
        if (goto.charAt(0) == "#") {
            window.location.hash = goto;
            return;
        }

        window.location.href = goto;
    }

});