/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
/*
<div class="custom-control custom-switch">
  <input type="checkbox" class="custom-control-input" id="customSwitch1">
  <label class="custom-control-label" for="customSwitch1">Toggle this switch element</label>
</div>
*/

var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "ua.switch",
    title: "Switch",
    description: "A switch control.",
    supportServer: false,
    fields: {
        "name": {
            title: "Name",
            type: "string",
            description: "The name of the switch.",
            required: true
        },
        "title": {
            title: "Title",
            type: "string",
            description: "The title of the switch.",
            required: true
        },
        "value": {
            title: "Value",
            type: "boolean",
            description: "The value of the switch."
        },
        "required": {
            title: "Required",
            type: "boolean",
            description: "Is the switch required?"
        }
    }
});
var namespace = module.exports.namespace;

// var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");

// var f = require("../scripts/f.js");
// var uac = require("./templates/ua.control.js");
// var uae = require("../elements/templates/ua.element.js");

// var namespace = "ua.switch";
// exports.namespace = namespace;

exports.render = function (options) {

    var randomName = uai.generateRandomID(12);
   
  var element = {
    "n": "div",
    "name": options.name,
    "c": "form-group pb-3",
    "i": [{
        n: "div",
        c: "custom-control custom-switch",
        i: [{
            n: "input",
            c: "custom-control-input",
            a: {
                "id": randomName,
                "name": options.name,
                "type": "checkbox"
            }
        }, {
            n: "label",
            c: "custom-control-label text-color",
            a: {
                "for": randomName
            },
            t: options.title
        }]
    }]
  };

  if ("value" in options) {
    if (options.value === true) {
        element.i[0].i[0].a.checked = true;
    }
  }

  if ("required" in options) {
    if (options.required === true) {
        element.i[0].i[0].a.required = options.required;
    }
  }

  return element;



//  var options = uac.GetOptions(ele);

//  //already appended
//  if (!(options.uarendered === true)) {
//   var customswitch = document.createElement("div");
//   customswitch.classList.add("custom-control");
//   customswitch.classList.add("custom-switch");

//   var input = document.createElement("input");
//   input.id = options.name;

//   //this tells UA not to add it to the input list
//   // but ask the control
//   input.setAttribute("uaignore", "true");
//   input.setAttribute("name", options.name);
//   input.setAttribute("type", "checkbox");

//   input.classList.add("custom-control-input");

//   if (input.value === true) {
//     input.checked = true;
//   }

//   input.willValidate = true;

//   var label = document.createElement("label");
//   label.classList.add("custom-control-label");
//   label.classList.add("text-color");
//   label.setAttribute("for", options.name);
//   label.innerText = options.title;


//   //pass all attributes to the input field
//   input = PassAtributes(options, input);

//   customswitch.appendChild(input);
//   customswitch.appendChild(label);

//   //ele.setAttribute(s"uarendered", true);
//   //ele.appendChild(customswitch);

//   // ele.setAttribute("uarendered", true);
//   // ele.appendChild(formgroup);
//   customswitch.addEventListener("prepare", Prepare);
//   return uac.Render_FinalStep(customswitch);

//  }

}