/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "pages.page",
    title: "Page",
    description: "The starter element for pages.",
    fields: {
        "title": {
            title: "Title",
          type: "string",
          description: "The title of the page.",
          required: true,
          passthrough: false
        }, "description": {
            title: "Description",
            type: "string",
            description: "The description of the page.",
            required: true,
            passthrough: false
        }, "image": {
            title: "Image",
            type: "uri",
            description: "The image of the page (used in some page types), visible to social media.",
            passthrough: false
            // required: true
        }, "inner":  {
            title: "Inner",
            type: "inner",
            description: "The inner content of the page.",
            passthrough: false
        }, "url": {
            title: "Page Url",
            type: "uri",
            description: "The url of the page (this is autogenerated) - it will be overridden.",
            passthrough: false
        }, "on": {
            title: "Dates",
            type: "object",
            passthrough: false,
            fields: {
                "published": {
                    title: "Published",
                    type: "date",
                    description: "The date the page was published."
                }, "modified": {
                    title: "Modified",
                    type: "date",
                    description: "The date the page was last modified."
                }, "created": {
                    title: "Created",
                    type: "date",
                    description: "The date the page was created."
                }
                // , "expires": {
                //     title: "Expires",
                //     type: "date",
                //     description: "The date the page expires."
                // }
            },
            description: "The dates of the page."
        }, "head": {
            title: "Head",
            type: "inner",
            passthrough: false,
            description: "Specific meta tags and style settings for the page. Currently only autogenerated by the pages.meta object."
        }, "useBackgroundImage": {
            title: "Use Background Image",
            default: true,
            type: "boolean",
            description: "Should the page use the default background image?"
        }, "useNavigation": {
            title: "Use Navigation",
            default: true,
            type: "boolean",
            description: "Should the page use the default navigation?"
        }, "useDocumentEnd": {
            title: "Use Document End",
            default: true,
            type: ["boolean", "string"],
            description: "Should the page use the default footer? It's also possible to pass the name of the part to use in it's place."
        }, "bodyClass": {
            title: "Body Class",
            type: "class",
            description: "Extra classes to add to the body tag."
        },  "useFooter": {
            title: "Use Footer",
                type: "boolean",
                description: "Should the page use the default footer?"
        }, "debug": {
            title: "Debug",
            type: "boolean",
            description: "Should the page output its compiled ua/json?"
        },
        "modal": {
            title: "Modal",
            type: ["object", "boolean"],
            default: false,
            description: "Should the page be rendered as a modal?",
            fields: {
                "footer": {
                    title: "footer",
                    type: "inner",
                    description: "The footer of the modal."
                }
            }
        },
        "expires": {
            title: "Will this page expire?",
            type: "object",
            description: "Information on the experiation of this page.",
            passthrough: false,
            fields: {
                "date": {
                    title: "Date",
                    type: "date",
                    description: "The date the page will expire."
                },
                "action": {
                    title: "Action",
                    description: "The action to take when the page expires.",
                    type: "inner",
                    namespace: "pages.action"
                }
            }
        }
      }
});
// var namespace = module.exports.namespace;
// var namespace = "pages.page";

// exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var f = require("../scripts/f.js");

// var uai = require("../interface/interface.js");
// const { l } = require("../../../uam/sanitize.js");

var describeAccountingDate = require("../../../uam/functions/describeAccountingDate.js").function;

// var required = ["title", "description", "image", "inner", "url", "on.published"];
// exports.required = required;

// exports.define = {
//   namespace: namespace,
//   fields: {
//     "title": {
//         title: "Title",
//       type: "string",
//       description: "The title of the page."
//     }, "description": {
//         title: "Description",
//         type: "string",
//         description: "The description of the page."
//     }, "image": {
//         title: "Image",
//         type: "uri",
//         description: "The image of the page (used in some page types), visible to social media."
//     }, "inner":  {
//         title: "Inner",
//         type: "inner",
//         description: "The inner content of the page."
//     }, "url": {
//         title: "Page Url",
//         type: "uri",
//         description: "The url of the page (this is autogenerated) - it will be overridden."
//     }, "on": {
//         title: "Dates",
//         type: "object",
//         fields: {
//             "published": {
//                 title: "Published",
//                 type: "date",
//                 description: "The date the page was published."
//             }, "modified": {
//                 title: "Modified",
//                 type: "date",
//                 description: "The date the page was last modified."
//             }, "created": {
//                 title: "Created",
//                 type: "date",
//                 description: "The date the page was created."
//             }
//             // , "expires": {
//             //     title: "Expires",
//             //     type: "date",
//             //     description: "The date the page expires."
//             // }
//         },
//         description: "The dates of the page."
//     }, "head": {
//         title: "Head",
//         type: "inner",
//         description: "Specific meta tags and style settings for the page. Currently only autogenerated by the pages.meta object."
//     }, "useBackgroundImage": {
//         title: "Use Background Image",
//         default: true,
//         type: "boolean",
//         description: "Should the page use the default background image?"
//     }, "useNavigation": {
//         title: "Use Navigation",
//         default: true,
//         type: "boolean",
//         description: "Should the page use the default navigation?"
//     }, "useDocumentEnd": {
//         title: "Use Document End",
//         default: true,
//         type: ["boolean", "string"],
//         description: "Should the page use the default footer? It's also possible to pass the name of the part to use in it's place."
//     }, "bodyClass": {
//         title: "Body Class",
//         type: "class",
//         description: "Extra classes to add to the body tag."
//     },  "useFooter": {
//         title: "Use Footer",
//             type: "boolean",
//             description: "Should the page use the default footer?"
//     }, "debug": {
//         title: "Debug",
//         type: "boolean",
//         description: "Should the page output its compiled ua/json?"
//     },
//     "expires": {
//         title: "Will this page expire?",
//         type: "object",
//         description: "Information on the experiation of this page.",
//         fields: {
//             "date": {
//                 title: "Date",
//                 type: "date",
//                 description: "The date the page will expire."
//             },
//             "action": {
//                 title: "Action",
//                 description: "The action to take when the page expires.",
//                 type: "inner",
//                 namespace: "pages.action"
//             }
//         }
//     }
//   },
//   required: required
// };

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

 //convert options.data from 20231010 to October 10, 2023
 options.on.describePublished = describeAccountingDate(options.on.published);

 if (!("url" in options)) {
    console.error("Page url can not be found.", {
        options: options,
        test: ("url" in options),
        stack: f.ParseStackTrace(new Error().stack)
    });
    options.url = window.location.href;
 }

 var meta = {
    "n": "pages.meta",
    "isArticle": true,
    "title": options.title,
    "description": options.description,
    "image": options.image,
    "url": options.url,
    // "author": options.author.name,
    "published": options.on.published,
    // "keywords": options.keywords,
    "social": {
        "author": {},
        "site": {},
        "app": {}
    }
};

if ("author" in options) {
    meta.author = options.author.name;

    if ("social" in options.author) {
        if ("twitter" in options.author.social) {
            meta.social.author.twitter = options.author.social.twitter;
        }

        if ("facebook" in options.author.social) {
            meta.social.author.facebook = options.author.social.facebook;
        }

        if ("instagram" in options.author.social) {
            meta.social.author.instagram = options.author.social.instagram;
        }

        if ("linkedin" in options.author.social) {
            meta.social.author.linkedin = options.author.social.linkedin;
        }

        if ("youtube" in options.author.social) {
            meta.social.author.youtube = options.author.social.youtube;
        }

        if ("tiktok" in options.author.social) {
            meta.social.author.tiktok = options.author.social.tiktok;
        }

        if ("twitch" in options.author.social) {
            meta.social.author.twitch = options.author.social.twitch;
        }

        if ("snapchat" in options.author.social) {
            meta.social.author.snapchat = options.author.social.snapchat;
        }

        if ("reddit" in options.author.social) {
            meta.social.author.reddit = options.author.social.reddit;
        }

        if ("pinterest" in options.author.social) {
            meta.social.author.pinterest = options.author.social.pinterest;
        }

        if ("github" in options.author.social) {
            meta.social.author.github = options.author.social.github;
        }

        if ("tumblr" in options.author.social) {
            meta.social.author.tumblr = options.author.social.tumblr;
        }

        if ("soundcloud" in options.author.social) {
            meta.social.author.soundcloud = options.author.social.soundcloud;
        }

        if ("spotify" in options.author.social) {
            meta.social.author.spotify = options.author.social.spotify;
        }

        if ("mixcloud" in options.author.social) {
            meta.social.author.mixcloud = options.author.social.mixcloud;
        }

        if ("bandcamp" in options.author.social) {
            meta.social.author.bandcamp = options.author.social.bandcamp;
        }

        if ("cashapp" in options.author.social) {
            meta.social.author.cashapp = options.author.social.cashapp;
        }

    }
}


var newPage = [
    meta, {
        n: "page",
        c: "p-0 m-0"
    }];

    newPage[1] = await jsonRender.pass(options, newPage[1], ["description", "image", "on", "title"]);

    //if modal is true add modcal class to body
    if (options.modal) {
        document.body.classList.add("in-modal");

        var modalFooter = "";
        //if modal is a object
        if (typeof options.modal === "object") {
            if ("footer" in options.modal) {
                modalFooter = options.modal.footer;
            }
        }
        
        var modalHeader = "";
        /* <div class="modal-header p-0">
                        <h1 class="modal-title d-flex align-items-center" id="modalReset">
                            <span
                                class="mybrand d-inline-block" style="width:7rem; height:7rem; margin-right:1rem;">
                                 
                            </span>
                            Let's design your website,
                        </h1>
                    </div>*/
        if ("header" in options.modal) {
            modalHeader = {
                n: "div",
                c: "modal-header p-0",
                i: [{
                    n: "h1",
                    c: "modal-title d-flex align-items-center",
                    id: "modalReset",
                    i: [{
                        n: "span",
                        c: "mybrand d-inline-block",
                        style: "width:7rem; height:7rem; margin-right:1rem;"
                    }, options.modal.header]
                }]
            };
        }

        /* Wrap the page in a modal 
          <div class="modal show" id="modelLegal" tabindex="-1" aria-labelledby="modelReset" aria-modal="true" role="dialog"
        style="z-index: 100000; display: block;">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">

                <div class="modal-body">
                </div>
                 <div class="modal-footer p-2">
                </div>
            </div>
        </div>*/

        newPage[1] = {
            n: "div",
            c: "modal show",
            tabindex: "-1",
            "aria-modal": "true",
            role: "dialog",
            s: "z-index: 100000; display: block;",
            i: [{
                n: "div",
                c: "modal-dialog modal-dialog-centered modal-dialog-scrollable",
                i: [{
                    n: "div",
                    c: "modal-content",
                    i: [{
                        n: "div",
                        c: "modal-body",
                        i: [modalHeader, newPage[1]]
                    }, {
                        n: "div",
                        c: "modal-footer p-2",
                        i: modalFooter
                    }]
                }]
            }]
        };

        //if modal header isn't blank add the described by attribute
        if (modalHeader !== "") {
            newPage[1]["aria-describedby"] = "modalReset";
        }

        // newPage[1] = await jsonRender.pass(options, newPage[1], ["description", "image", "on", "title"]);
    }
    // } else {
       
    // }

    // console.info("Page results", newPage);
 
 //Let's build meta tags
  return newPage;
  
};