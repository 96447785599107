var _ = require("./module.js")(module.exports, {
    namespace: "universe.storage",
    title: "Object Storage",
    description: "A safe haven for storing objects and protected data.",
    config: {
        filepath: {
            type: "uri",
            default: "@!/.store",
            title: "Object Storage Path",
            description: "The path to the object storage file."
        }
    },
    errors: {
        "storageCouldNotBeFound": {
            title: "Storage could not be found",
            description: "The storage file could not be found in the registry at the time of the request."
        },
        "notAnObject": {
            title: "Not an Object",
            description: "The object passed to the function was not an object."
        },
        "noID": {
            title: "No ID",
            description: "The stored obejct did not have an ID or a namespoace."
        },
        "objectNotFound": {
            title: "Object Not Found",
            description: "The object could not be found in the storage."
        }
    },
    registry: {
        autosave: true
    }
});

var fm = require("./filemanager.js");

/**
 * Initializes the module, Self called.
 */
async function init() {
    //create the storage file if it doesn't exist
    fm.createDirectoryIfNotExists(_.config.filepath);
} init();

/**
 * Creates a new object storage
 * @param {*} namespace The namespace of the object storage
 * @param {*} title The title of the object storage
 * @param {*} description The description of the object storage
 * @returns The object storage
 */
async function create(namespace, title, description) {
    var obj = {
        namespace: namespace,
        title: title,
        description: description
    };

    //create the folder
    fm.createDirectoryIfNotExists(fm.resolve(_.config.filepath, namespace));

    //add to registry
    _.registry.add(obj);

    //find the item from the registry so we get the updated from
    var item = _.registry.get(namespace);

    if (!(item)) {
        _.errors.storageCouldNotBeFound.throw(obj);
    }

    //save the define objcet
    fm.saveJSON(fm.resolve(_.config.filepath, namespace, "define.json"), item);

    return item;
} _.create = create;

function save(namespace, object) {
    var id = false;

    //is the object an object?
    if (typeof object != "object") {
        _.errors.NotAnObject.throw(object);
    }

    //get the object id or namespace
    if ("namespace" in object) {
        id = object.namespace;
    } else if ("id" in object) {
        id = object.id;
    } else {
        _.errors.noID.throw(object);
    }

    //find the storeage object
    var storage = _.registry.get(namespace);
    if (!(storage)) {
        _.errors.storageCouldNotBeFound.throw(namespace);
    }

    //save the object
    fm.saveJSON(fm.resolve(_.config.filepath, namespace, id + ".json"), object);

} _.save = save;

/**
 * Gets an object from the storage
 * @param {*} store The 
 * @param {*} id 
 * @returns 
 */
function get(namespace, id) {
    var storage = _.registry.get(namespace);
    if (!(storage)) {
        _.errors.storageCouldNotBeFound.throw(namespace);
    }

    var object = fm.loadJSON(fm.resolve(_.config.filepath, namespace, id + ".json"));

    if (!(object)) {
        _.errors.objectNotFound.throw({store: store, id: id});
    }

    return object;

} _.get = get;