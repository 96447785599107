/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "special.body",
    title: "Special: Body Instructions",
    description: "Appends attributes to the body.",
    fields: {
        "class": {
            title: "Class",
            type: "string",
            description: "The class to append to the body tag."
        }
      },
    returnsNothing: true
});
// var namespace = module.exports.namespace;
// var namespace = "pages.page";

// exports.namespace = namespace;

// var jsonRender = require("../interface/jsonRender.js");
// var f = require("../scripts/f.js");

// var uai = require("../interface/interface.js");
// const { l } = require("../../../uam/sanitize.js");

// var describeAccountingDate = require("../../../uam/functions/describeAccountingDate.js").function;

// var required = ["title", "description", "image", "inner", "url", "on.published"];
// exports.required = required;

// exports.define = {
//   namespace: namespace,
//   fields: {
//     "title": {
//         title: "Title",
//       type: "string",
//       description: "The title of the page."
//     }, "description": {
//         title: "Description",
//         type: "string",
//         description: "The description of the page."
//     }, "image": {
//         title: "Image",
//         type: "uri",
//         description: "The image of the page (used in some page types), visible to social media."
//     }, "inner":  {
//         title: "Inner",
//         type: "inner",
//         description: "The inner content of the page."
//     }, "url": {
//         title: "Page Url",
//         type: "uri",
//         description: "The url of the page (this is autogenerated) - it will be overridden."
//     }, "on": {
//         title: "Dates",
//         type: "object",
//         fields: {
//             "published": {
//                 title: "Published",
//                 type: "date",
//                 description: "The date the page was published."
//             }, "modified": {
//                 title: "Modified",
//                 type: "date",
//                 description: "The date the page was last modified."
//             }, "created": {
//                 title: "Created",
//                 type: "date",
//                 description: "The date the page was created."
//             }
//             // , "expires": {
//             //     title: "Expires",
//             //     type: "date",
//             //     description: "The date the page expires."
//             // }
//         },
//         description: "The dates of the page."
//     }, "head": {
//         title: "Head",
//         type: "inner",
//         description: "Specific meta tags and style settings for the page. Currently only autogenerated by the pages.meta object."
//     }, "useBackgroundImage": {
//         title: "Use Background Image",
//         default: true,
//         type: "boolean",
//         description: "Should the page use the default background image?"
//     }, "useNavigation": {
//         title: "Use Navigation",
//         default: true,
//         type: "boolean",
//         description: "Should the page use the default navigation?"
//     }, "useDocumentEnd": {
//         title: "Use Document End",
//         default: true,
//         type: ["boolean", "string"],
//         description: "Should the page use the default footer? It's also possible to pass the name of the part to use in it's place."
//     }, "bodyClass": {
//         title: "Body Class",
//         type: "class",
//         description: "Extra classes to add to the body tag."
//     },  "useFooter": {
//         title: "Use Footer",
//             type: "boolean",
//             description: "Should the page use the default footer?"
//     }, "debug": {
//         title: "Debug",
//         type: "boolean",
//         description: "Should the page output its compiled ua/json?"
//     },
//     "expires": {
//         title: "Will this page expire?",
//         type: "object",
//         description: "Information on the experiation of this page.",
//         fields: {
//             "date": {
//                 title: "Date",
//                 type: "date",
//                 description: "The date the page will expire."
//             },
//             "action": {
//                 title: "Action",
//                 description: "The action to take when the page expires.",
//                 type: "inner",
//                 namespace: "pages.action"
//             }
//         }
//     }
//   },
//   required: required
// };

var classListAppend = "";

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
async function render(options) {

    //if class is passed in add it to the body class list (more than one class as a string could be added example "this andThat")

    if (options.class) {
        classListAppend += " " + options.class;
        // console.log("Class List Append", classListAppend);
    }

    return [""];

}; exports.render = render;

var events = require("../../../uam/events.js");
events.on("interface.afterrender", async function() {

    // console.log("Class List Append After Render", classListAppend);

    //if the body has something to append
    if (classListAppend) {
        document.body.classList.add(classListAppend);
    }

});