/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.breadcrumb",
  title: "Breadcrumb",
  description: "A simple way to showvase navigation items as a breadcrumb.",
  fields: {
    "inner": {
      title: "Items",
      type: "inner",
      namespaces: ["ua.navbar.items"],
      description: "The items to add to the navbar.",
      default: []
    },
    "divider": {
      title: "Divider",
      type: "string",
      description: "The divider to use between items.",
      default: ">"
    }
  },
  supportServer: false,
  passthrough: {
    except: ["divider", "inner"]
  }
  // required: ["inner"],
});
// var namespace = module.exports.namespace;

// var namespace = "ua.breadcrumb";
// exports.namespace = namespace;

// var jsonRender = require("../interface/jsonRender.js");
// var events = require("../../../uam/events.js");
// var uai = require("../interface/interface.js");
// var ua_NavbarItem = require("./ua.navbar.item.js");
// var uae = require("./templates/ua.element.js");

var uaNavBarItem = require("./ua.navbar.item.js");

// var required = ["inner"]; 
// exports.required = required;

// exports.define = {
//   namespace: namespace,
//   title: "Breadcrumbs",
//   description: "A simple way to showvase navigation items as a breadcrumb.",
//   fields: {
//     "inner": {
//       type: "inner",
//       namespaces: ["ua.navbar.items"],
//       description: "The items to add to the navbar.",
//       default: []
//     },
//     "divider": {
//       type: "string",
//       description: "The divider to use between items.",
//       default: ">"
//     }
//   },
//   supportServer: false,
//   required: required,
//   passthrough: {
//     except: ["divider", "inner"]
//   }
// } 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {
  var breadcrumb = {
    n: "nav",
    "aria-label": "breadcrumb",
    c: "",
    s: {
      "--bs-breadcrumb-divider": `'${options.divider}'`
    },
    i: {
      n: "ol",
      c: "breadcrumb p-0 m-0 bg-transparent",
      i: []
    }
  };

  // Add the items to the breadcrumb
  for (var i = 0; i < options.inner.length; i++) {

    var item = options.inner[i];

    var crumb = {
      n: "li",
      c: "breadcrumb-item",
      i: await uaNavBarItem.GetSimple(item)
    };

    if ("active" in item) {
      if (item.active) {
        crumb.c += " active";
        crumb["aria-current"] = "page";
      }
    }

    breadcrumb.i.i.push(crumb);

  };

  console.log("Breadcrumb", breadcrumb);

  return breadcrumb;

}

/** 
 * 
 * <nav id="navMain" class="navbar navbar-expand navbar-dark bg-blur-dark fixed-top" aria-label="Navigation">
        <div class="container">
<ul class="navbar-nav">
    <a class="navbar-brand mybrand d-inline-block" style="width:4rem; height:4rem;" href="/" title="home"></a>
</ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#home">Home</a>
                </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#developers">Developers</a>
              </li>
<li class="nav-item">
    
</li>
            </ul>
<ul class="navbar-nav">
    <li class="nav-item"><button class="btn btn-sm btn-info btn-hover-white text-uppercase ls-1" type="submit">Join the Beta</button></li>
</ul>
            
            
            
            
        </div>
      </nav>
*/
