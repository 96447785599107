/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.fs.module",
  alias: "ua.fs.video",
  fields: {
    "src": {
      title: "Background Video",
      type: "uri",
      filetype: "video",
      description: "The source of the image or video."
    }, "inner": {
      title: "Inner",
      type: "inner",
      description: "The inner items to display on top of the background."
    }, "poster": {
      title: "Poster",
      type: "uri",
      filetype: "image",
      description: "The poster image to display before the video plays."
    },
    "fixed": {
      title: "Fixed",
      type: "boolean",
      description: "Whether the background should be fixed or not."
    }
  },
  passthrough: true
});

// var namespace = "ua.fs.module";
// exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");
// var uai = require("../interface/interface.js");


/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
  * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // var options = uae.GetOptions(json, required);

    var bg = {
      n: "video",
      c: "ua-fs-background ua-fs-background-video",
      playsinline: "",
      muted: "",
      loop: "",
      i: {
        n: "source",
        src: options.src,
        type: "video/mp4"
      }
    }

    var playButton = {
      n: "button",
      c: "ua-fs-background-play"
    };

    var article = {
      n: "article",
      c: "ua-fs-background-video-holster",
      i: [bg]
    };
  
    
    if ("inner" in options) {
      article.i.push(options.inner);
    }
  
    if ("poster" in options) {
      bg.poster = options.poster;
    }
  
    if ("fixed" in options) {
      if (!(options.fixed)) {
        //its false
      } else {
        bg.c += " video-fixed";
        bg.fixed = options.fixed;
        // article.fixed = options.fixed;
      }
    }

  return article
  
};