
/**
 * Handles literal objects gracefully. #{propertyName} syntax, with support for multilevel properties.
 * @param {*} jsonString The string to parse for literals.
 * @param {*} context The context to use for the literals. If not provided, eval will be used (WARNING: This will enable code injection).
 * @returns The adjusted string with property values substituted.
 */
function handleLiterals(str, data = undefined, literalCharacter = "$") {

    //if data is undefined false or null return the string
    if (data === undefined || data === false || data === null) {
        return str;
    }

    var jsonString = str;
    var context = data;

    //if it's a number or boolean skip it
    switch (typeof jsonString) {
        case "number":
        case "boolean":
        case "object":
        case "undefined":
        case "null":
        case "function":
            return jsonString;
    }

    // console.log("json.Render Handle Literals", {
    //     jsonString: jsonString,
    //     context: context 
    // });

    // Helper function to get property value by path
    function getPropertyByPath(path, obj) {
        return path.split('.').reduce((prev, curr) => {
            return prev ? prev[curr] : undefined;
        }, obj);
    }

    // If the string is blank
    if (jsonString == "") {
        return jsonString;
    }

    if (jsonString.indexOf(`${literalCharacter}{`) == -1) {
        // No literals
        return jsonString;
    }

    // If context is undefined, return jsonString as we cannot safely evaluate without enabling code injection
    if (context === undefined) {
        // console.warn(`Warning: handl`, { jsonString });
        // console.warn(`Warning: Using JSON Literals without context can enable code injection.`, { jsonString });
        return jsonString; // Consider safely handling this case as per your application's security requirements.
    } else {
        try {
         const regExpr = new RegExp('\\' + literalCharacter + '\\{([^}]+)\\}', 'g');
            var interpretedString = jsonString.replace(regExpr,(_, propertyPath) => {
                const value = getPropertyByPath(propertyPath.trim(), context);
                return value !== undefined ? value : `#{${propertyPath.trim()}}`;
            });
            return interpretedString;
        } catch (error) {
            console.error("Error processing literals", error);
            return jsonString;
        }
    }
    
} module.exports.handleLiterals = handleLiterals;


module.exports = {
    //name: "objectify",
    namespace: "literals",
    function: handleLiterals,
    tests: [{
        namespace: `literals.default`,
        must: "test d",
        run: async () => {
           
            //test objectify
            var obj = {}
            var result = handleLiterals("test ${a.b.c}", { a: { b: { c: "d" } } });
            return result;

        }
    }, {
        namespace: `literals.longtest`,
        must: "hello world!",
        run: async () => {
           
            var obj = {
                1: "h",
                2: "e",
                3: "l",
                4: "l",
                5: "o",
                6: " ",
                7: "w",
                8: "o",
                9: "r",
                10: "l",
                11: "d",
                aa: "!"
            }

            var result = handleLiterals("${1}${2}${3}${4}${5}${6}${7}${8}${9}${10}${11}${aa}", obj);
            return result;
           
        }
    }]
};