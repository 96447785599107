/*!
 * © 2024. The EGT Universe. All Rights Reserved.
 * License: Requires ACTIVE AppArtisan Subscription.
 * For mor information visit: https://egtuniverse.com/legal
 * 
 * Created for Universe App Tools.
 * 
 * Created by Justin K Kazmierczak.
 */
var _ = require("../../../uam/module.js")(module.exports, {
  namespace: "ua.none",
  title: "A Nonrenderable Object",
  description: "",
  description: "For passing data between UATools where an item must be appeneded to the body and information needs to be passed back to the requesting element",
  editable: false,
  fields: {
      
  }
});

// var f = require("../scripts/f.js");
// var jsonRender = require("../interface/jsonRender.js");

// var prependToId = "_uae__";

// var define = {
//     namespace: "ua.none",
//     title: "A Nonrenderable Object",
//     description: "For passing data between UATools where an item must be appeneded to the body and information needs to be passed back to the requesting element",
//     editable: false,
//     fields: {
      
//     }
//   }; module.exports.define = define;


  /**
   * Must be called directly, render may fail if used as part of a template.
   * @param {*} options The jsonObject to render.
   * @returns The rendered DOM object.
   */
  exports.render = async function (options) {

    return [""];

  }