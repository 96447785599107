/*!***************
    Be cautios when adding other UAM objects to this file.
    This could result in a circular reference.
*/
/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Loads and activates specific persistant globals.
 * 
 * This will allow for easy passing of expected application objects.
 * This object makes it easy for intellisense to operate.
 * 
 * It should be noted that access to this object should be closely guarded.
 * 
 *  * Required Config Vars
 * 
 */
var namespace = "universe.app.scafolding";

var fm = require("./filemanager.js");

// //create the registry directory
// try {
//     fm.createDirectoryIfNotExists(`@!/.log/registry/${namespace}`);    
// } catch (error) {
//    //probably se 
// }

// var events = require("./events.js");

// var registry = require("./registry.js")();
// events.on("uam.registry.create", async function (namespace) {
//     //create the registry directory
//     fm.createDirectoryIfNotExists(`@!/.log/registry/${namespace}`);    
// });

// registry.addEvent("uam.registry.add", async function (registry, item) {

//     //logs the item added to the registry

//     var nItem = {
//         namespace: item.namespace,
//         item: item,
//         from: GenerateStacktrace()
//       };
  
//       // console.warn("Registry Name", this);
  
//       fm.SaveLogJSONbyNamespaceItem(registry.namespace, nItem, "registry");
// });

//register event


function randomName(i) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.";

    for (var x = 0; x < i; x++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

//create a random varable name to store the global object
var globalName = randomName(10);
globalName += randomName(10);
globalName += randomName(10);
globalName += randomName(10);

var $ = global[globalName] || (global[globalName] = {});
module.exports = $;

/**
 * TODO:
 * Log all global objects stored here, they must be degined like config.
 * This will enable extensions to request access to registered global objects.
 * While preventing access to other objects stored here.
 */

// console.log(`Universe Scaffolding registerd at ${globalName}.`);