/*!
 * Copyright (C) 2022 Justin K Kazmierczak - All Rights Reserved.
 * The use of this code is subject to The Terms of Service.
 * To access these terms visit: https://egtuniverse.com/legal/terms
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 */
/*
 * Written for:
 * Stallion.
 * Prompt.
 * 
 * Universe App Scafolding Object
 * Turns a string into an object appended to a specificed object.
 * 
 */

// var $ = require("../modules/scafolding");
var namespace = "flattenObject";

// var $ = require("../scafolding.js");

/**
 * Flattens an object into a single level value key pair.
 * Arrays are not flattened. 
 * @param {*} obj The object to flatten.
 * @param {*} parentKey A top level key for recursion from the object as it's iterated.
 * @returns A value key pair of the object.
 */
function flattenObject(obj, parentKey = '') {
    let result = {};
  
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let newKey = parentKey ? `${parentKey}.${key}` : key;
  
        //if i'm an array
        if (Array.isArray(obj[key])) {
          result[newKey] = obj[key];
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          let flattened = flattenObject(obj[key], newKey);
          result = { ...result, ...flattened };
        } else {
          result[newKey] = obj[key];
        }
      }
    }
  
    return result;
  }

module.exports = {
    //name: "objectify",
    namespace: namespace,
    function: flattenObject,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           
          //test flattenObject
          var obj = {
            "a": {
              "b": {
                "c": {
                  "d": true
                }
              }
            }
          };

          var result = flattenObject(obj);
          if (result["a.b.c.d"] == true) {
              return true;
          } else {
              return false;
          }

        }
    }]
};